import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';

function CustomsClearanceSummaryTable({ data, HSCODEMode }) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [expandedGroups, setExpandedGroups] = useState({});

  // Full detail columns (used in the nested subtable)
  const columns = [
    { key: 'Delivery', label: 'Delivery' },
    { key: 'Item', label: 'Item' },
    { key: 'Material', label: 'Material' },
    { key: 'Item Description', label: 'Description' },
    { key: 'Actual delivery qty', label: 'Qty' },
    { key: 'Sales Unit', label: 'Unit' },
    { key: 'HSCode', label: 'HS Code' },
    { key: 'm2PerUnit', label: 'M2/Unit' },
    { key: 'm2Line', label: 'Total M2' },
    { key: 'Total Weight', label: 'Weight (KG)' },
    { key: 'Net Weight', label: 'Net Weight (KG)' }
  ];

  // For non-grouped mode, sort the data as before.
  const sortedData = data.slice().sort((a, b) => {
    if (!sortColumn) return 0;
    const aVal = a[sortColumn];
    const bVal = b[sortColumn];

    if (aVal == null) return 1;
    if (bVal == null) return -1;

    if (typeof aVal === 'number' && typeof bVal === 'number') {
      return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
    }
    return sortDirection === 'asc'
      ? String(aVal).localeCompare(String(bVal))
      : String(bVal).localeCompare(String(aVal));
  });

  // Group data by HSCode (or 'Unknown' if not provided)
  const groupedData = () => {
    const groups = {};
    data.forEach((row) => {
      const code = row.HSCode || 'Unknown';
      if (!groups[code]) groups[code] = [];
      groups[code].push(row);
    });
    return groups;
  };

  const toggleGroup = (code) => {
    setExpandedGroups((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  // Calculate subtotals for a group
  const getGroupTotals = (rows, field) =>
    rows.reduce((sum, row) => sum + (Number(row[field]) || 0), 0).toFixed(2);

  // Render the summary view with a 4-column header and nested detail subtables
  const renderGroupedView = () => {
    const groups = groupedData();
    const sortedGroupKeys = Object.keys(groups).sort((a, b) => a.localeCompare(b));

    return (
      <Table
        striped
        bordered
        hover
        variant="dark"
        size="sm"
        style={{ fontSize: 'small', width: '100%' }}
      >
        <thead>
          <tr style={{ backgroundColor: '#222', fontWeight: 'bold' }}>
            <th>HS Code</th>
            <th>Total Qty</th>
            <th>Total M2</th>
            <th>Total Weight (KG)</th>
          </tr>
        </thead>
        <tbody>
          {sortedGroupKeys.map((code) => {
            const groupRows = groups[code];
            const isExpanded = expandedGroups[code] || false;
            return (
              <React.Fragment key={code}>
                {/* Summary Row */}
                <tr
                  style={{
                    cursor: 'pointer',
                    backgroundColor: isExpanded ? '#444' : '#222',
                    color: 'white'
                  }}
                  onClick={() => toggleGroup(code)}
                >
                  <td>{code}</td>
                  <td>{getGroupTotals(groupRows, 'Actual delivery qty')}</td>
                  <td>{getGroupTotals(groupRows, 'm2Line')}</td>
                  <td>{getGroupTotals(groupRows, 'Total Weight')}</td>
                </tr>
                {/* Nested subtable: rendered only if group is expanded */}
                {isExpanded && (
                  <tr>
                    <td colSpan={4} style={{ padding: '10px 20px', backgroundColor: '#333' }}>
                      <Table
                        striped
                        bordered
                        hover
                        variant="dark"
                        size="sm"
                        style={{ fontSize: 'small', width: '100%' }}
                      >
                        <thead>
                          <tr>
                            {columns.map((col) => (
                              <th key={col.key}>{col.label}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {groupRows.map((row, idx) => (
                            <tr key={`${code}-${idx}`}>
                              {columns.map((col) => (
                                <td key={col.key}>{row[col.key]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    );
  };

  // When not grouping by HS Code, render the flat (full detail) table.
  const renderFlatView = () => (
    <Table
      striped
      bordered
      hover
      variant="dark"
      size="sm"
      style={{ fontSize: 'small', width: '100%' }}
    >
      <thead>
        <tr>
          {columns.map((col) => (
            <th
              key={col.key}
              onClick={() => {
                setSortColumn(col.key);
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              style={{ cursor: 'pointer' }}
            >
              {col.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, idx) => (
          <tr key={idx}>
            {columns.map((col) => (
              <td key={col.key}>{row[col.key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div className="customs-summary-table">
      {HSCODEMode ? renderGroupedView() : renderFlatView()}
    </div>
  );
}

export default CustomsClearanceSummaryTable;
