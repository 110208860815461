import Dexie from 'dexie';

// Define your database schema
const db = new Dexie('Neolith');
db.version(15).stores({
    IQ09: '++id', // Auto-incrementing primary key
    ME2N: '++id',
    VA05: '++id',
    CUBO: '++id',
    ME2LData: '++id',
    CUBOCompras: '++id',
    CUBOVentas: '++id',
    CUBOStock: '++id',
    CUBOOrders: '++id',
    CUBOStockHQ: '++id',
    CUBOStockSubsid: '++id',
    CUBOAnalyserDRP: '++id',
    CUBOSubsidDRPData: '++id',
    DebtPortalData: '++id',
    VentasAnalyserData: '++id',
    JJDRP: '++id',
    CodexData: '++id',
    JJDRP_Stock: '++id',
    JJDRP_SalesOrders: '++id',
    JJDRP_PurchaseOrders: '++id',
    JJDRP_Transit: '++id',
    JJDRP_Invoiced: '++id',
    LogisticsStatusOrdersData: '++id',
    LogisticsProductionData: '++id',
    LogisticsStockData: '++id',
});

// Connect to the database
export async function connectToDB() {
    try {
        await db.open();
        console.log("Database connected");
        return db;
    } catch (error) {
        console.error("Error connecting to database:", error);
        throw error;
    }
}

// Upgrade the database (if needed)
export async function upgradeDatabase() {
    try {
        await db.open(); // Open the database to trigger any necessary upgrades
        console.log("Database upgrade complete");
    } catch (error) {
        console.error("Error upgrading database:", error);
        throw error;
    }
}

/**
 * Get data from a specified collection in the database.
 * @param {Dexie} db The Dexie database instance.
 * @param {string} collectionName The name of the collection to retrieve data from.
 * @returns {Promise<any[]>} A promise that resolves with an array containing the retrieved data.
 * @throws {Error} If there is an error retrieving the data.
 */
export async function GetData(db, collectionName) {
    try {
        const data = await db[collectionName].toArray();
        console.log("Retrieved data from: " + collectionName);
        return data;
    } catch (error) {
        console.error("Error getting data:", error);
        throw error;
    }
}

/**
 * Store data in a specified object store in the database.
 * @param {Dexie} db The Dexie database instance.
 * @param {string} objectStore The name of the object store to store the data in.
 * @param {any} data The data to store in the object store.
 * @returns {Promise<void>} A promise that resolves when the data is successfully stored.
 * @throws {Error} If there is an error storing the data.
 */
export async function StoreData(db, objectStore, data) {
    try {
        await db[objectStore].add(data);
        console.log("Data stored successfully to: " + objectStore);
    } catch (error) {
        console.error("Error storing data:", error);
        throw error;
    }
}

/**
 * Remove all data from all datasets in the database.
 * @param {Dexie} db The Dexie database instance.
 * @returns {Promise<void>} A promise that resolves when all data is successfully removed.
 * @throws {Error} If there is an error removing the data.
 */
export async function RemoveAllData(db) {
    try {
        await Promise.all(db.tables.map(table => table.clear()));
        console.log("All data removed from the database");
    } catch (error) {
        console.error("Error removing data:", error);
        throw error;
    }
}

export async function RemoveDataFromTable(db, tableName) {
    try {
        await db[tableName].clear();
        console.log("Data removed from: " + tableName);
    } catch (error) {
        console.error("Error removing data:", error);
        throw error;
    }
}