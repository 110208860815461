  export const columnMap = {
    'Center': 'Center',
    'SKU': 'SKU',
    'Descrp. Modelo': 'Descrp. Modelo',
    'Acabado': 'Acabado',
    'Espesor': 'Espesor',
    'Medida': 'Medida',
    'Segmento': 'Segmento',
    'Coverage (Months)': 'Coverage (Months)',
    'Transit Days': 'Transit Days',
    'Invoiced 12M<': 'Invoiced 12M<',
    'Invoiced 3M<': 'Invoiced 3M<',
    'Daily Rate': 'Daily Rate',
    'Transit Buffer': 'Transit Buffer',
    'Target Stock B4 Grwth': 'Target Stock B4 Grwth',
    'Growth Expectation?': 'Growth Expectation?',
    'Target Stock After Growth': 'Target Stock After Growth',
    'Mimimum Stock (RO Trigger)': 'Mimimum Stock (RO Trigger)',
    'MSIB (Min Slabs in 1 Batch)': 'MSIB (Min Slabs in 1 Batch)',
    'Stock Max': 'Stock Max',
    'Current Available Stock (In Plant)': 'Current Available Stock (In Plant)',
    'Current Stock in Transit': 'Current Stock in Transit',
    'Current Purchase Orders': 'Current Purchase Orders',
    'Pending Sales Orders': 'Pending Sales Orders',
    'Current Stock in HQ': 'Current Stock in HQ',
    'Supplemented ATP': 'Supplemented ATP',
    'N2O Raw': 'N2O Raw',
    'Real Need': 'Real Need',
    'Bookmatch SKU': 'Bookmatch SKU',
    'Bookmatch Preference': 'Bookmatch Preference',
    'Sister Code': 'Sister Code',
    'Final Orders to Place': 'Final Orders to Place',
    'OODev': 'OODev', // Map the OODev column
  };

  export const columnDisplayNames = {
    'Center': 'Center',
    'SKU': 'SKU',
    'Descrp. Modelo': 'Decor',
    'Acabado': 'Finish',
    'Espesor': 'Thick',
    'Medida': 'Format',
    'Segmento': 'Segment',
    'Coverage (Months)': 'Coverage (Months)',
    'Transit Days': 'Transit Days',
    'Invoiced 12M<': 'Invoiced 12M<',
    'Invoiced 3M<': 'Invoiced 3M<',
    'Daily Rate': 'Daily Rate',
    'Transit Buffer': 'Tst Buffer',
    'Target Stock B4 Grwth': 'Target Stock B4G',
    'Growth Expectation?': 'Growth?',
    'Target Stock After Growth': 'Tgt Stock',
    'Mimimum Stock (RO Trigger)': 'RO Trigger',
    'MSIB (Min Slabs in 1 Batch)': 'MSIB (Min Slabs per Batch)',
    'Stock Max': 'Stock Max',
    'Current Available Stock (In Plant)': 'In Stock',
    'Current Stock in Transit': 'In Transit',
    'Current Purchase Orders': 'Purchase Orders',
    'Pending Sales Orders': 'Pending SOs',
    'Current Stock in HQ': 'Stock in HQ',
    'Supplemented ATP': 'SATP',
    'N2O Raw': 'N2O Raw',
    'Real Need': 'Real Need',
    'Bookmatch SKU': 'Bookmatch SKU',
    'Bookmatch Preference': 'Bookmatch Prefe',
    'Sister Code': 'Sister Code',
    'Final Orders to Place': 'Final OTP',
    'OODev': 'OverOrder Dev', // Display name for OODev
    
  };

  // Grouping definitions for columns
  export const columnGroups = {
    'Center': 'Characteristics',
    'SKU': 'Characteristics',
    'Descrp. Modelo': 'Characteristics',
    'Acabado': 'Characteristics',
    'Espesor': 'Characteristics',
    'Medida': 'Characteristics',
    'Segmento': 'Characteristics',
    'Coverage (Months)': 'Invoicing',
    'Transit Days': 'Invoicing',
    'Invoiced 12M<': 'Invoicing',
    'Invoiced 3M<': 'Invoicing',
    'Daily Rate': 'Invoicing',
    'Transit Buffer': 'Invoicing',
    'Target Stock B4 Grwth': 'Stock Parameters',
    'Growth Expectation?': 'Stock Parameters',
    'Target Stock After Growth': 'Stock Parameters',
    'Mimimum Stock (RO Trigger)': 'Stock Parameters',
    'MSIB (Min Slabs in 1 Batch)': 'Stock Parameters',
    'Stock Max': 'Stock Parameters',
    'Current Available Stock (In Plant)': 'Current Stock',
    'Current Stock in Transit': 'Current Stock',
    'Current Purchase Orders': 'Current Stock',
    'Pending Sales Orders': 'Current Stock',
    'Current Stock in HQ': 'Current Stock',
    'Supplemented ATP': 'Current Stock',
    'N2O Raw': 'Logic',
    'Real Need': 'Logic',
    'Bookmatch SKU': 'Logic',
    'Bookmatch Preference': 'Logic',
    'Sister Code': 'Logic',
    'Final Orders to Place': 'Logic',
    'OODev': 'Stock Parameters', // Group for
  };

  export const finishMap = {
    "DECOR POLISHED": "DP",
    "SILK": "KK",
    "RIVERWASHED": "RV",
    "ULTRASOFT": "US",
    "SLATE": "LT",
    "STEEL TOUCH": "ST",
    "NANOTECH": "NP",
    "SATIN": "SS",
  };
