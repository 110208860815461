import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import FilterControls from './FilterControls';
import SalesByMaterialChart2 from './SalesByMaterialChart2';
import { GetTopMaterials } from '../../../../Utils/VentasAnalyserOps';

const SalesByMaterialParent = ({ ventasData }) => {
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        currency: 'GBP',
    });
    const [topMaterials, setTopMaterials] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const { startDate, endDate, currency } = filters;
            const topMaterialsData = await GetTopMaterials(ventasData, startDate, endDate, currency);
            setTopMaterials(topMaterialsData);
            console.log(topMaterialsData);
        };

        fetchData();
    }, [filters, ventasData]);

    const handleFilterChange = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleApplyFilters = () => {
        setFilters(filters);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Card className="dark-card-2">
                        <Card.Header>Filter Sales Data</Card.Header>
                        <Card.Body>
                            <FilterControls
                                startDate={filters.startDate}
                                endDate={filters.endDate}
                                selectedCurrency={filters.currency}
                                onFilterChange={handleFilterChange}
                                onApplyFilters={handleApplyFilters}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="dark-card-2 mt-4">
                        <Card.Header>Interactive Sales Chart</Card.Header>
                        <Card.Body style={{width: "100%"}}>
                            <SalesByMaterialChart2 topMaterials={topMaterials} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SalesByMaterialParent;
