import React from 'react';
import { Card, Row, Col, Form, FormGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

function VBC_FiltersBar({
    startDate,
    endDate,
    currency,
    dataType,
    displayMode, // New prop for display mode
    handleStartDateChange,
    handleEndDateChange,
    handleCurrencyChange,
    handleDataTypeChange,
    handleDisplayModeChange // New handler for display mode
}) {
    return (
        <Card className='VBC_FiltersBar-Main-Card'>
            <Card.Body>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control 
                                    type="date" 
                                    value={startDate || ''} 
                                    onChange={handleStartDateChange} 
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control 
                                    type="date" 
                                    value={endDate || ''} 
                                    onChange={handleEndDateChange} 
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Data Type</Form.Label>
                                <ToggleButtonGroup 
                                    type="radio" 
                                    name="dataType" 
                                    value={dataType} 
                                    onChange={handleDataTypeChange}
                                    className="mb-1 toggle-button-small"
                                >
                                    <ToggleButton 
                                        id="Net" 
                                        value="Net" 
                                        variant={dataType === 'Net' ? 'warning' : 'outline-warning'}
                                    >
                                        Net
                                    </ToggleButton>
                                    <ToggleButton 
                                        id="invoiced" 
                                        value="Invoiced" 
                                        variant={dataType === 'Invoiced' ? 'warning' : 'outline-warning'}
                                    >
                                        Invoiced
                                    </ToggleButton>
                                    <ToggleButton 
                                        id="credited" 
                                        value="Credited" 
                                        variant={dataType === 'Credited' ? 'warning' : 'outline-warning'}
                                    >
                                        Credited
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <ToggleButtonGroup 
                                    type="radio" 
                                    name="displayMode" 
                                    value={displayMode} 
                                    onChange={handleDisplayModeChange}
                                    className="mb-1 toggle-button-small"
                                >
                                    <ToggleButton 
                                        id="kpi-grid" 
                                        value="KPIGrid" 
                                        variant={displayMode === 'KPIGrid' ? 'warning' : 'outline-warning'}
                                    >
                                        KPI Grid
                                    </ToggleButton>
                                    <ToggleButton 
                                        id="sales-browser" 
                                        value="SalesBrowser" 
                                        variant={displayMode === 'SalesBrowser' ? 'warning' : 'outline-warning'}
                                    >
                                        Sales Browser
                                    </ToggleButton>
                                    <ToggleButton 
                                        id="top-materials" 
                                        value="TopMaterials" 
                                        variant={displayMode === 'TopMaterials' ? 'warning' : 'outline-warning'}
                                    >
                                        Top Materials
                                    </ToggleButton>
                                    <ToggleButton 
                                        id="credit-analysis" 
                                        value="CreditAnalysis" 
                                        variant={displayMode === 'CreditAnalysis' ? 'warning' : 'outline-warning'}
                                    >
                                        Credit Analysis
                                    </ToggleButton>
                                    {/* <ToggleButton 
                                        id="Array-Documenter" 
                                        value="ArrayDocumentor" 
                                        variant={displayMode === 'ArrayDocumentor' ? 'warning' : 'outline-warning'}
                                    >
                                        Array Documenter
                                    </ToggleButton> */}
                                    <ToggleButton id="yearly-account" value="YearlyAccountAnalysis" variant={displayMode === 'YearlyAccountAnalysis' ? 'warning' : 'outline-warning'}>Yearly Account</ToggleButton>
                                </ToggleButtonGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default VBC_FiltersBar;
