import * as XLSX from 'xlsx';
import formatMatrix from '../Components/Excels/CustomsSummariser/CustomsFormatMatrix.json';
import HSCODEMatrix from '../Components/Excels/CustomsSummariser/CustomsHSCODEMatrix.json';
import jsPDF from 'jspdf';
import 'jspdf-autotable';  

export const ReadExcelForCustoms = async (customsFile) => {
    try {
        let arrayBuffer = await customsFile.arrayBuffer();
        let workbook = XLSX.read(arrayBuffer, { type: 'array' });

        let customsSheet = workbook.Sheets["Sheet1"];
        
        if (!customsSheet) {
            console.error("Sheet 'Customs Summary' not found in the uploaded file.");
            throw new Error("Sheet 'Customs Summary' not found.");
        }

        let customsData = XLSX.utils.sheet_to_json(customsSheet);

        return customsData;

    } catch (error) {
        console.error("Error processing customs file:", error);
        return null;  // Returning null to indicate failure
    }
};

const weightMap = {
    3: 40,
    6: 80,
    12: 160,
    20: 260,
}

const HSCodeMap = {
    3: 1,
    6: 2,
    12: 3,
    20: 4,
}


export function summariseClearanceData(clearanceData) {
    // Build lookup for HSCode by material.
    const hsCodeMap = HSCODEMatrix.reduce((acc, hsItem) => {
      acc[hsItem.Material] = hsItem.HSCODE;
      return acc;
    }, {});
  
    // Build lookup for M2 from the format matrix using the "Size Description" as key.
    const formatMatrixMap = formatMatrix.reduce((acc, fmt) => {
      acc[fmt["Size Description"]] = parseFloat(fmt.M2);
      return acc;
    }, {});
  
    // Safe helper function to determine M2 per unit from the item description.
    function getM2PerUnit(itemDescription) {
      if (!itemDescription || typeof itemDescription !== 'string') return 0;
  
      for (let sizeDescription in formatMatrixMap) {
        if (itemDescription.includes(sizeDescription)) {
          return formatMatrixMap[sizeDescription];
        }
      }
      return 0;
    }
  
    // Create a new array with extra computed properties for each line.
    const enrichedData = clearanceData.map(line => {
      const itemDescription = line["Item Description"] || '';
      const material = line.Material || '';
      const actualQty = Number(line["Actual delivery qty"]) || 0;
  
      // Determine HSCode with fallback logic
      let hsCode = hsCodeMap[material];
      if (!hsCode) {
        if (material.startsWith('TB')) {
          hsCode = 69072100;
        } 
        else if ( itemDescription.toLowerCase().includes('pallet') ) {
           hsCode = 44152020;
        } 
        else {
            hsCode = "Unknown";
        }
    }
      
  
      const m2PerUnit = getM2PerUnit(itemDescription);
      const m2Line = m2PerUnit * actualQty;
  
      return {
        ...line,
        HSCode: hsCode,
        m2PerUnit,
        m2Line
      };
    });
  
    return enrichedData;
  }
  
  
  /**
   * Exports the customs clearance summary data to a two-page PDF.
   * 
   * @param {Array} summarisedData - Array of enriched clearance data.
   */
  export function exportCustomsSummaryPDF(summarisedData) {
    // Create a jsPDF instance with landscape A4
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4'
    });
  
    // -------------------------
    // Page 1: Detailed Summary Table (by lines)
    // -------------------------
    const mainColumns = [
      { header: 'Delivery', dataKey: 'Delivery' },
      { header: 'Item', dataKey: 'Item' },
      { header: 'Material', dataKey: 'Material' },
      { header: 'Description', dataKey: 'Item Description' },
      { header: 'Qty', dataKey: 'Actual delivery qty' },
      { header: 'Unit', dataKey: 'Sales Unit' },
      { header: 'HS Code', dataKey: 'HSCode' },
      { header: 'M2/Unit', dataKey: 'm2PerUnit' },
      { header: 'Total M2', dataKey: 'm2Line' },
      { header: 'Total Weight', dataKey: 'Total Weight' },
      { header: 'Net Weight', dataKey: 'Net Weight' }
    ];
  
    // Add title for page 1
    doc.text(
      'Automatic Customs Clearance Summary',
      doc.internal.pageSize.getWidth() / 2,
      20,
      { align: 'center' }
    );
  
    // Generate main table using autoTable
    doc.autoTable({
      startY: 40,
      head: [mainColumns.map(col => col.header)],
      body: summarisedData.map(row => mainColumns.map(col => row[col.dataKey])),
      styles: { fontSize: 8 },
      headStyles: { fillColor: [22, 160, 133] },
      margin: { top: 40 }
    });
  
    // -------------------------
    // Page 2: Summary by HS Code
    // -------------------------
    // Add a new page for the HS Code summary
    doc.addPage();
  
    // Compute summary data aggregated by HSCode
    const summaryByHSCode = summarisedData.reduce((acc, row) => {
      const hsCode = row.HSCode || "Unknown";
      if (!acc[hsCode]) {
        acc[hsCode] = {
          HSCode: hsCode,
          totalM2: 0,
          totalWeight: 0,
          totalNetWeight: 0
        };
      }
      acc[hsCode].totalM2 += Number(row.m2Line) || 0;
      acc[hsCode].totalWeight += Number(row["Total Weight"]) || 0;
      acc[hsCode].totalNetWeight += Number(row["Net Weight"]) || 0;
      return acc;
    }, {});
    
    const summaryData = Object.values(summaryByHSCode);
  
    // Define the columns for the summary table
    const summaryColumns = [
      { header: 'HS Code', dataKey: 'HSCode' },
      { header: 'Total M2', dataKey: 'totalM2' },
      { header: 'Total Weight', dataKey: 'totalWeight' },
      { header: 'Total Net Weight', dataKey: 'totalNetWeight' }
    ];
  
    // Format the summary data values to append units
    const formattedSummaryBody = summaryData.map(row => [
      row.HSCode,
      `${row.totalM2.toFixed(2)} m2`,
      `${row.totalWeight.toFixed(2)} kg`,
      `${row.totalNetWeight.toFixed(2)} kg`
    ]);
  
    // Add title for the summary table
    doc.text(
      'Summary by HS Code',
      doc.internal.pageSize.getWidth() / 2,
      30,
      { align: 'center' }
    );
  
    // Generate the summary table using autoTable
    doc.autoTable({
      startY: 50,
      head: [summaryColumns.map(col => col.header)],
      body: formattedSummaryBody,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [44, 62, 80] },
      margin: { top: 10 }
    });
  
    // Save the PDF file
    doc.save('customs_clearance_summary.pdf');
  }

// Ensure your AcroForm plugin is imported/registered here
// import 'jspdf-acroform'; // (or the appropriate path/plugin)

 /**
  * Exports the customs clearance summary data to a two-page PDF.
  * Page 1 shows detailed lines; Page 2 shows a summary by HS Code.
  * Rows with an HS Code of "Unknown" will have an editable field.
  * 
  * @param {Array} summarisedData - Array of enriched clearance data.
  */
export function exportCustomsSummaryPDFInteractive(summarisedData) {
  // Create a jsPDF instance with landscape A4
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'a4'
  });

  // -------------------------
  // Page 1: Detailed Summary Table (by lines)
  // -------------------------
  const mainColumns = [
    { header: 'Delivery', dataKey: 'Delivery' },
    { header: 'Item', dataKey: 'Item' },
    { header: 'Material', dataKey: 'Material' },
    { header: 'Description', dataKey: 'Item Description' },
    { header: 'Qty', dataKey: 'Actual delivery qty' },
    { header: 'Unit', dataKey: 'Sales Unit' },
    { header: 'HS Code', dataKey: 'HSCode' },
    { header: 'M2/Unit', dataKey: 'm2PerUnit' },
    { header: 'Total M2', dataKey: 'm2Line' },
    { header: 'Total Weight', dataKey: 'Total Weight' },
    { header: 'Net Weight', dataKey: 'Net Weight' }
  ];

  // Add title for page 1
  doc.text(
    'Automatic Customs Clearance Summary',
    doc.internal.pageSize.getWidth() / 2,
    20,
    { align: 'center' }
  );

  // Generate main table using autoTable
  doc.autoTable({
    startY: 40,
    head: [mainColumns.map(col => col.header)],
    body: summarisedData.map(row => mainColumns.map(col => row[col.dataKey])),
    styles: { fontSize: 8 },
    headStyles: { fillColor: [22, 160, 133] },
    margin: { top: 40 }
  });

  // -------------------------
  // Page 2: Summary by HS Code
  // -------------------------
  // Add a new page for the HS Code summary
  doc.addPage();

  // Compute summary data aggregated by HS Code
  const summaryByHSCode = summarisedData.reduce((acc, row) => {
    const hsCode = row.HSCode || "Unknown";
    if (!acc[hsCode]) {
      acc[hsCode] = {
        HSCode: hsCode,
        totalM2: 0,
        totalWeight: 0,
        totalNetWeight: 0
      };
    }
    acc[hsCode].totalM2 += Number(row.m2Line) || 0;
    acc[hsCode].totalWeight += Number(row["Total Weight"]) || 0;
    acc[hsCode].totalNetWeight += Number(row["Net Weight"]) || 0;
    return acc;
  }, {});
  
  const summaryData = Object.values(summaryByHSCode);

  // Define the columns for the summary table
  const summaryColumns = [
    { header: 'HS Code', dataKey: 'HSCode' },
    { header: 'Total M2', dataKey: 'totalM2' },
    { header: 'Total Weight', dataKey: 'totalWeight' },
    { header: 'Total Net Weight', dataKey: 'totalNetWeight' }
  ];

  // Format the summary data values to append units
  const formattedSummaryBody = summaryData.map(row => [
    row.HSCode,
    `${row.totalM2.toFixed(2)} m2`,
    `${row.totalWeight.toFixed(2)} kg`,
    `${row.totalNetWeight.toFixed(2)} kg`
  ]);

  // Add title for the summary table
  doc.text(
    'Summary by HS Code',
    doc.internal.pageSize.getWidth() / 2,
    30,
    { align: 'center' }
  );

  // Generate the summary table using autoTable on page 2
  doc.autoTable({
    startY: 50,
    head: [summaryColumns.map(col => col.header)],
    body: formattedSummaryBody,
    styles: { fontSize: 8 },
    headStyles: { fillColor: [44, 62, 80] },
    margin: { top: 10 }
  });

  // OPTIONAL: Add editable fields for any "Unknown" HS Code
  // (This requires that the jsPDF AcroForm plugin is enabled)
  if (typeof doc.addField === 'function') {
    // Loop over the rows of the autoTable on page 2.
    // doc.lastAutoTable provides access to the table data and cell positions.
    const table = doc.lastAutoTable;
    table.body.forEach((row, rowIndex) => {
      // The first cell corresponds to the HS Code column.
      const cell = row.cells[0];
      // Check if the text is "Unknown"
      if (cell.text === "Unknown") {
        // The cell object contains the coordinates; adjust if needed.
        const { x, y, width, height } = cell;
        // Add an editable text field at the same position.
        // The API below is hypothetical—adjust according to your AcroForm plugin.
        doc.addField('text', 'hsCodeField_' + rowIndex, {
          x: x,
          y: y,
          width: width,
          height: height,
          value: '',
          fontSize: 8,
          // You can also add additional styling or field properties here.
        });
      }
    });
  }

  // Save the PDF file
  doc.save('customs_clearance_summary.pdf');
}
