import React from 'react';
import { Form } from 'react-bootstrap';

const PaginationRecordsSelectionControl = ({ 
  controlIdName = "rowsPerPage", 
  formLabel = "Rows per page:", 
  value, 
  setRowsPerPage, 
  setCurrentPage 
}) => {
  return (
    <div className="pagination-controls-top">
      <Form.Group controlId={controlIdName} className="d-flex align-items-center">
        <Form.Label style={{position: "relative", top: "5px", marginRight: "13px"}}>{formLabel}</Form.Label>
        <Form.Select
          className="form-select"
          value={value}
          onChange={(e) => {
            setRowsPerPage(parseInt(e.target.value));
            setCurrentPage(1); // Reset to first page
          }}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
          <option value={300}>300</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default PaginationRecordsSelectionControl;
