import React from 'react';
import { Table, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import './PlanningTable.css';

const columnLabelsStatusOrders = {
  EDD: "Est Delivery Date",
  SO: "Sales Order",
  DN: "Delivery Note",
  Client: "Client",
  Pos: "Pos",
  Articulo: "SKU",
  "Descripción material": "Material Description",
  Qty: "Qty",
  LOTE: "Batch",
  FeHORNOS: "Production Date",
  FeMALLADO: "Meshing Date",
  FePULIDO: "Polishing Date",
  FeCORTE: "Cutting Date",
  Delegacion: "Delegation",
  Comentario: "Comment",
  "€ línea": "Line €",
};

const convertExcelDateToUKDate = (excelDate) => {
  if (!excelDate || isNaN(excelDate)) return excelDate;
  const date = new Date((excelDate - 25569) * 86400000);
  return date.toLocaleDateString("en-GB");
};

const headerColors = ['rgb(14, 81, 99)', 'rgb(9, 100, 125)'];

function PlanningTable({ 
  statusOrdersData = [], 
  PaginationProps, 
  visibleColumns, 
  selectedRows = [], 
  setSelectedRows = () => {},
  showWeights,
  rowAdjustments = {},
  setRowAdjustments = () => {},
  showRackSpace,
  divisionIndicators = {}  // e.g. { divisionIndicator3mm, divisionIndicator6mm, ... }
}) {
  const { currentPage, setCurrentPage, sortColumn, setSortColumn, sortDirection, setSortDirection, totalPages, startIndex, endIndex } = PaginationProps;

  if (!statusOrdersData.length) {
    return <div>No data available</div>;
  }

  // Only render valid columns.
  const columnsToRender = visibleColumns.filter(colKey => columnLabelsStatusOrders[colKey]);

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const renderSortIcon = (column) => {
    return sortColumn === column ? (
      sortDirection === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />
    ) : null;
  };

  const sortedData = [...statusOrdersData].sort((a, b) => {
    const aValue = a[sortColumn] || "";
    const bValue = b[sortColumn] || "";
    if (!sortColumn) return 0;
    return sortDirection === 'asc' ? (aValue < bValue ? -1 : 1) : (aValue > bValue ? -1 : 1);
  });

  const paginatedData = sortedData.slice(startIndex, endIndex);

  // Use absolute row index (startIndex + rowIndex) as unique identifier.
  const handleRowSelect = (row, rowIndex) => {
    const uniqueId = startIndex + rowIndex;
    if (selectedRows.includes(uniqueId)) {
      setSelectedRows(selectedRows.filter(id => id !== uniqueId));
    } else {
      setSelectedRows([...selectedRows, uniqueId]);
      if (rowAdjustments[uniqueId] === undefined) {
        setRowAdjustments({ ...rowAdjustments, [uniqueId]: Number(row.Qty) || 0 });
      }
    }
  };

  const handleDecrease = (uniqueId) => {
    const current = rowAdjustments[uniqueId] || 0;
    if (current > 0) {
      setRowAdjustments({ ...rowAdjustments, [uniqueId]: current - 1 });
    }
  };

  const handleIncrease = (uniqueId) => {
    const current = rowAdjustments[uniqueId] || 0;
    setRowAdjustments({ ...rowAdjustments, [uniqueId]: current + 1 });
  };

  return (
    <div className="planning-table-container">
      <div className="table-wrapper">
        <Table striped bordered hover variant="dark" size="sm">
          <thead className="sticky-header">
            <tr>
              {/* Selection column header */}
              <th style={{ width: "40px", textAlign: "center" }}>Select</th>
              {columnsToRender.map((colKey, index) => (
                <th
                  key={colKey}
                  style={{
                    backgroundColor: index % 2 === 0 ? headerColors[0] : headerColors[1],
                    color: 'white',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                  onClick={() => handleSort(colKey)}
                >
                  {columnLabelsStatusOrders[colKey]} {renderSortIcon(colKey)}
                </th>
              ))}
              {showWeights && (
                <th style={{ textAlign: "center" }}>Selected Qty & Weight</th>
              )}
              {showRackSpace && (
                <th style={{ textAlign: "center" }}>Rack Space</th>
              )}
            </tr>
          </thead>
          <tbody className="table-body">
            {paginatedData.map((row, rowIndex) => {
              const uniqueId = startIndex + rowIndex;
              const rowClass = selectedRows.includes(uniqueId) ? "selected-row" : "";
              const rowStyle = selectedRows.includes(uniqueId)
              ? { backgroundColor: 'rgba(0, 123, 255, 0.2)' }
              : {};
              const originalQty = Number(row.Qty) || 0;
              const adjustedQty = rowAdjustments[uniqueId] !== undefined ? rowAdjustments[uniqueId] : originalQty;
              const thicknessStr = (row.Articulo || "").substring(9, 11);
              const thicknessNum = parseInt(thicknessStr, 10);
              const weightMatrix = { 3: 40, 6: 80, 12: 160, 20: 260 };
              const weightPerUnit = weightMatrix[thicknessNum] || 0;
              const selectedWeight = adjustedQty * weightPerUnit;
              let rackSpace = 0;
              if (showRackSpace) {
                let division = 1;
                if (thicknessNum === 3) division = divisionIndicators.divisionIndicator3mm;
                else if (thicknessNum === 6) division = divisionIndicators.divisionIndicator6mm;
                else if (thicknessNum === 12) division = divisionIndicators.divisionIndicator12mm;
                else if (thicknessNum === 20) division = divisionIndicators.divisionIndicator20mm;
                if (division > 0) {
                  rackSpace = adjustedQty / division;
                }
              }
              // Highlight selected row with a subtle pale blue background.
              return (
                <tr key={uniqueId} className={rowClass} style={rowStyle}>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(uniqueId)}
                      onChange={() => handleRowSelect(row, rowIndex)}
                    />
                  </td>
                  {columnsToRender.map((colKey) => (
                    <td key={colKey}>
                      {["EDD", "FeHORNOS", "FeMALLADO", "FePULIDO", "FeCORTE"].includes(colKey)
                        ? convertExcelDateToUKDate(row[colKey])
                        : row[colKey] ?? ""}
                    </td>
                  ))}
                  {showWeights && (
                    <td style={{ 
                      textAlign: "center", 
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "center", 
                      gap: "12px",
                      fontSize: "13px"
                    }}>
                      <div style={{ 
                        display: "flex", 
                        alignItems: "center", 
                        gap: "6px",
                        minWidth: "100px",
                        justifyContent: "center"
                      }}>
                        <button onClick={() => handleDecrease(uniqueId)} style={{ fontSize: "12px" }}>–</button>
                        <span style={{ minWidth: "30px", textAlign: "center" }}>{adjustedQty}</span>
                        <button onClick={() => handleIncrease(uniqueId)} style={{ fontSize: "12px" }}>+</button>
                      </div>
                      <span style={{ fontSize: "16px", fontWeight: "bold", color: "#555" }}>•</span>
                      <div style={{ 
                        minWidth: "60px", 
                        textAlign: "center",
                        fontWeight: "500" 
                      }}>
                        {selectedWeight} kg
                      </div>
                    </td>
                  )}
                  {showRackSpace && (
                    <td style={{ textAlign: "center" }}>
                      {rackSpace.toFixed(2)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="pagination-controls-bottom">
        <Pagination>
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          <Pagination.Item active>{`Page ${currentPage} of ${totalPages}`}</Pagination.Item>
          <Pagination.Next
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default PlanningTable;
