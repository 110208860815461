import './PlanItDashStyles.css';
import React, { useEffect, useState } from 'react';
import { ExportToSpreadsheetWithOmissions, CopyJsonToClipboardWithOmissions } from '../../../../Utils/PlanItFunctions';
import PlanItDashInsightCard from './PlanItDashInsightCard';
import { faBoxesStacked, faCogs, faWeight, faBoxesPacking } from '@fortawesome/free-solid-svg-icons';
import PlanningTable from './PlanningTable';
import Loading from '../../../Reusables/Loading';

function PlanItDash({ planItProps, AppProps, PaginationProps, selectedFiltersLabel, setTotalWeightSelected, 
  setTotalRacksOccupied, setTotalSlabsSelected, totalWeightSelected, totalRacksOccupied, totalSlabsSelected,
  divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm,
  setDivisionIndicator3mm, setDivisionIndicator6mm, setDivisionIndicator12mm, setDivisionIndicator20mm 
}) {
  const { statusOrdersData, visibleColumns, selectedRows, setSelectedRows, showRackSpace, showWeights, rowAdjustments, setRowAdjustments } = planItProps;
  const { token } = AppProps;

  // Insight states
  const [slabsPending, setSlabsPending] = useState(0);




  // Weight matrix: weight per unit for thickness (in mm)
  const weightMatrix = {
    3: 40,
    6: 80,
    12: 160,
    20: 260
  };

  // Recalculate insights for selected rows (when rows are selected)
  useEffect(() => {
    let totalWeight = 0;
    let totalRacks = 0;
    let totalSlabs = 0;
    selectedRows.forEach(index => {
      const row = statusOrdersData[index];
      if (row) {
        const adjustedQty = rowAdjustments[index] !== undefined ? rowAdjustments[index] : Number(row.Qty) || 0;
        totalSlabs += adjustedQty;
        const thicknessStr = (row.Articulo || "").substring(9, 11);
        const thicknessNum = parseInt(thicknessStr, 10);
        const weightPerUnit = weightMatrix[thicknessNum] || 0;
        totalWeight += adjustedQty * weightPerUnit;
        let division = 1;
        if (thicknessNum === 3) division = divisionIndicator3mm;
        else if (thicknessNum === 6) division = divisionIndicator6mm;
        else if (thicknessNum === 12) division = divisionIndicator12mm;
        else if (thicknessNum === 20) division = divisionIndicator20mm;
        if (division > 0) {
          totalRacks += adjustedQty / division;
        }
      }
    });
    setTotalWeightSelected(totalWeight);
    setTotalRacksOccupied(totalRacks.toFixed(2));
    setTotalSlabsSelected(totalSlabs);
  }, [selectedRows, statusOrdersData, rowAdjustments, divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm]);

  // Calculate slabs pending (all rows with Articulo starting with "T")
  useEffect(() => {
    let pending = 0;
    statusOrdersData.forEach(row => {
      if (row.Articulo && row.Articulo.startsWith("T")) {
        pending += Number(row.Qty) || 0;
      }
    });
    setSlabsPending(pending);
  }, [statusOrdersData]);

  // Calculate production count: unique Articulo with FeHORNOS set.
  const [productionCount, setProductionCount] = useState(0);

  useEffect(() => {
    if (!statusOrdersData?.length) {
      setProductionCount(0);
      return;
    }
  
    const uniqueArticuloSet = new Set(
      statusOrdersData
        .filter(row => 
          row.Articulo && 
          row.FeHORNOS && 
          row.Articulo.trim().toUpperCase().startsWith("TB") &&
          row.Articulo.trim().toUpperCase().endsWith("E")
        )
        .map(row => row.Articulo.trim().toUpperCase()) // Normalize for consistency
    );
  
    setProductionCount(uniqueArticuloSet.size);
  }, [statusOrdersData]);
  
  
  

  // (Export/Copy handlers omitted for brevity)

  return (
    <div className='-plan-it-dash-'>
      <span style={{ fontSize: "14px" }}>
        <strong>Selected Filters:</strong> {selectedFiltersLabel || "None"}
      </span>
      {selectedRows.length === 0 ? (
        <div className="plan-it-dash-row">
          <PlanItDashInsightCard 
            parentCssName='plan-it-dash-insight-card' 
            cardHeader='Slabs Pending to load' 
            bodyValue={slabsPending} 
            icon={faBoxesStacked}
          />
          <PlanItDashInsightCard 
            parentCssName='plan-it-dash-insight-card' 
            cardHeader="Production (Unique SKU)" 
            bodyValue={productionCount} 
            icon={faCogs}
          />
        </div>
      ) : (
        <div className="plan-it-dash-row">
          <PlanItDashInsightCard 
            parentCssName='plan-it-dash-insight-card' 
            cardHeader='Slabs selected to load' 
            bodyValue={totalSlabsSelected} 
            icon={faBoxesStacked}
          />
          <PlanItDashInsightCard 
            parentCssName='plan-it-dash-insight-card' 
            cardHeader="Total Weight Selected" 
            bodyValue={totalWeightSelected+" KG"} 
            icon={faWeight}
          />
          <PlanItDashInsightCard 
            parentCssName='plan-it-dash-insight-card' 
            cardHeader='Total Racks Occupied' 
            bodyValue={totalRacksOccupied} 
            icon={faBoxesPacking}
          />
        </div>
      )}
      <hr style={{ width: '90%', margin: '0 auto', borderColor: 'rgba(255,255,255,0.5)', marginTop: "20px", marginBottom: "7px" }} />
      <PlanningTable 
        statusOrdersData={statusOrdersData} 
        PaginationProps={PaginationProps} 
        visibleColumns={visibleColumns} 
        selectedRows={selectedRows} 
        setSelectedRows={setSelectedRows}
        showWeights={showWeights}
        rowAdjustments={rowAdjustments}
        setRowAdjustments={setRowAdjustments}
        showRackSpace={showRackSpace}
        divisionIndicators={{
          divisionIndicator3mm,
          divisionIndicator6mm,
          divisionIndicator12mm,
          divisionIndicator20mm
        }}
      />
    </div>
  );
}

export default PlanItDash;
