import React from 'react'
import { Button } from 'react-bootstrap'

function PlanItStockViewRightNavBar({handleClearFilters, handleClearData}) {
  return (
    <div className="planIt-navbar-right" style={{ display: "flex", alignItems: "center" }}>
        {/* Clear Filters and Clear Data Buttons */}
        <Button 
            onClick={handleClearFilters} 
            variant="warning" 
            style={{ margin: "5px", color: 'white', fontWeight: 'bold' }}
        >
            Clear Filters
        </Button>
        <Button 
            onClick={handleClearData} 
            variant="danger" 
            style={{ margin: "5px" }}
        >
            Clear Data
        </Button>
    </div>
  )
}

export default PlanItStockViewRightNavBar