import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import FileInputContainer from '../../Reusables/FileInputContainer';
import '../../../Styles/Excels/ExcelFileConfig.css';
import { ReadExcelForCustoms } from '../../../Utils/CustomsSummariserFuncs';
import Loading from '../../Reusables/Loading';

const CustomsSummariserConfig = ({ customsFileToSummarise, setCustomsFileToSummarise, setCustomsSummarisedData }) => {
    const [loading, setLoading] = useState(false);

    const handleCustomsProcessing = async () => {
        if (!customsFileToSummarise) {
            alert("Please upload a customs file before proceeding.");
            return;
        }

        try {
            setLoading(true);
            const summarisedData = await ReadExcelForCustoms(customsFileToSummarise);
            setCustomsSummarisedData(summarisedData);
        } catch (error) {
            console.error("Error processing customs data:", error);
            alert("An error occurred while processing the file. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="CustomsSummariserConfig">
            <Card className="data-browser-card">
                <Card.Header>Input Customs Data</Card.Header>
                <Card.Body>
                    <FileInputContainer 
                        labelTitle="Customs Data" 
                        selectedFilePath={customsFileToSummarise} 
                        setSelectedFile={setCustomsFileToSummarise} 
                    />
                    <div className="button-holder-div">
                        <Button 
                            variant="warning" 
                            className="custom-button-gold plan-it-go-button" 
                            onClick={handleCustomsProcessing} 
                            disabled={loading}
                        >
                            {loading ? "Processing..." : "Go"}
                        </Button>
                    </div>
                    {loading && <Loading />}
                </Card.Body>
            </Card>
        </div>
    );
};

export default CustomsSummariserConfig;
