import React from 'react';
import { exportCustomsSummaryPDFInteractive } from '../../../Utils/CustomsSummariserFuncs';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// Copy the Detailed Summary: full data grid (flat view)
// Creates a tab-delimited string where the first row contains headers.
function copyDetailedSummary(data) {
    if (!data.length) return;
    // Assumes all rows have the same keys.
    const headers = Object.keys(data[0]);
    const headerRow = headers.join('\t');
    const rows = data.map(row => headers.map(header => row[header]).join('\t'));
    const text = [headerRow, ...rows].join('\n');
    navigator.clipboard.writeText(text)
      .then(() => alert("Detailed summary copied to clipboard"))
      .catch(err => alert("Error copying detailed summary: " + err));
  }
  
  // Copy the Totalled Summary: aggregated by HS Code
  // Groups data by HSCode and creates a summary table with four columns.
  function copyTotalledSummary(data) {
    const groups = {};
    data.forEach(row => {
      const code = row.HSCode || 'Unknown';
      if (!groups[code]) groups[code] = [];
      groups[code].push(row);
    });
    // Create header row for the summary
    const headerRow = ['HS Code', 'Total Qty', 'Total M2', 'Total Weight (KG)'].join('\t');
    const rows = Object.keys(groups).map(code => {
      const group = groups[code];
      const totalQty = group.reduce((sum, row) => sum + (Number(row['Actual delivery qty']) || 0), 0);
      const totalM2 = group.reduce((sum, row) => sum + (Number(row['m2Line']) || 0), 0);
      const totalWeight = group.reduce((sum, row) => sum + (Number(row['Total Weight']) || 0), 0);
      return [code, totalQty, totalM2, totalWeight].join('\t');
    });
    const text = [headerRow, ...rows].join('\n');
    navigator.clipboard.writeText(text)
      .then(() => alert("Totalled summary copied to clipboard"))
      .catch(err => alert("Error copying totalled summary: " + err));
  }
  

function CustomsSummariserNavBar({ summarisedData, HSCODEMode, setHSCODEMode }) {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
        <div className="container-fluid">
          <span className="navbar-brand">Customs Summariser</span>
        
          {/* Right-side controls */}
          <div className="d-flex align-items-center gap-2">
          {summarisedData.length > 0 && (
              <>
                <button
                  className="btn btn-outline-light btn-sm"
                  onClick={() => exportCustomsSummaryPDFInteractive(summarisedData)}
                >
                  Export to PDF
                </button>
                <button
                  className="btn btn-outline-light btn-sm"
                  onClick={() => copyTotalledSummary(summarisedData)}
                >
                  Copy Totalled Summary 📋
                </button>
                <button
                  className="btn btn-outline-light btn-sm"
                  onClick={() => copyDetailedSummary(summarisedData)}
                >
                  Copy Detailed Summary 📋
                </button>
              </>
            )}
            {summarisedData.length > 0 && (
              <Dropdown align="end" className="d-flex align-items-center">
                <Dropdown.Toggle
                  as="button"
                  className="btn btn-outline-light btn-sm"
                  id="dropdown-custom-toggle"
                  style={{ height: 'auto', lineHeight: 'normal' }}
                >
                  Options
                </Dropdown.Toggle>

                <Dropdown.Menu className="bg-dark text-light">
                  <Dropdown.Item as="div" className="d-flex align-items-center justify-content-between">
                    <Form.Check
                      type="switch"
                      id="hscode-mode-switch"
                      label="Group by HS Code"
                      checked={HSCODEMode}
                      onChange={() => setHSCODEMode(!HSCODEMode)}
                      style={{ cursor: 'pointer', color: 'white' }}
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default CustomsSummariserNavBar;