import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

function VBC_ClientSelectionBar({ ventasData, setSelectedClient, selectedClient }) {
    const [clients, setClients] = useState([]);
    const [filterText, setFilterText] = useState("");
    // Convert selectedClient to an array if it's not already
    const selectedClients = Array.isArray(selectedClient) ? selectedClient : (selectedClient ? [selectedClient] : []);

    useEffect(() => {
        // Unique set of Clients to render buttons
        const uniqueClients = [...new Set(ventasData.map(item => item['Client Description']))];
        uniqueClients.sort(); // Sort clients alphabetically

        // Set clients only if they haven't been set yet to avoid unnecessary re-renders
        if (clients.length === 0) {
            setClients(uniqueClients);
        }
    }, [ventasData, clients]);

    // Filter clients based on the current filterText
    const filteredClients = clients.filter(client => 
      client.toLowerCase().includes(filterText.toLowerCase())
    );

    // Toggle client selection
    const toggleClientSelection = (client) => {
        if (selectedClients.includes(client)) {
            // Remove the client if it's already selected
            setSelectedClient(selectedClients.filter(c => c !== client));
        } else {
            // Add the client to the selection
            setSelectedClient([...selectedClients, client]);
        }
    };

    return (
        <div className='VBC-Client-Selection-Bar'>
            <div className='client-selection-title-box' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: "small" }}>Client Selection Bar</span>
                <Button 
                    variant="danger"
                    className='VBC-Client-Clear-Button'
                    onClick={() => setSelectedClient([])}
                    style={{ fontWeight: 'bold' }}
                    size='sm'
                >
                    Clear All Clients
                </Button>
            </div>

            {/* Input box for filtering */}
            <div className="VBC-Client-Filter-Box" style={{ margin: "5px 0" }}>
                <input 
                    type="text" 
                    placeholder="Filter Clients..."
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    style={{
                        display: 'block',
                        width: '95%',
                        padding: '0.375rem 0.75rem',
                        fontSize: '0.875rem',
                        lineHeight: 1.5,
                        color: '#212529',
                        backgroundColor: 'white',
                        border: '1px solid transparent',
                        borderRadius: '0.25rem',
                        margin: 'auto',
                    }}
                    className="form-control form-control-sm"
                />
            </div>

            <div className='VBC-Client-Selection-Bar-Button-Holder'>
                {filteredClients.map((client, index) => (
                    <Button 
                        variant={selectedClients.includes(client) ? "primary" : "warning"} 
                        key={index} 
                        className={`VBC-Client-Selection-Button ${selectedClients.includes(client) ? 'selected' : ''}`}
                        onClick={() => toggleClientSelection(client)}
                        size='sm'
                    >
                        <span className='client-selection-button-text'>{client}</span>
                    </Button>
                ))}
            </div>
            
            {/* Display selected clients count */}
            {selectedClients.length > 0 && (
                <div style={{ fontSize: "small", margin: "5px 0", textAlign: "center" }}>
                    {selectedClients.length} client{selectedClients.length > 1 ? 's' : ''} selected
                </div>
            )}
        </div>
    );
}

export default VBC_ClientSelectionBar;