import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faCopy, faPalette } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import {columnDisplayNames, columnGroups, columnMap, finishMap} from '../../../Utils/ColumnsConfigJJDRP'; //COLUMN SETTINGS MOVED TO HERE
import RowDetailsModal from './SubComponents/RowDetailsModal';

// Import your CellViewCards
import StockCellViewCard from './JJDRPCellViewCards/StockCellViewCard'
import PurchaseOrderCellViewCard from '../ExcelDRP/ExcelDRPCellViewCards/PurchaseOrderCellViewCard';
import SalesOrderCellViewCard from '../ExcelDRP/ExcelDRPCellViewCards/SalesOrderCellViewCard';

// Default colours for column groups
const defaultGroupColors = {
  'Characteristics': '#2e4053', // Dark Blue-Gray
  'Invoicing': '#4caf50',       // Dark Lime Green
  'Stock Parameters': '#1abc9c', // Green
  'Current Stock': '#c0392b',    // Crimson
  'Logic': '#d4af37',          // Darker Gold
  'DIO': '#2980b9',              // Blue
};

function JJDRPTable({
  JJDRPData,
  setJJDRPData,
  JJDRPDisplayView,
  setJJDRPDisplayView,
  setJJDRPDataValid,
  columns,
  visibleColumns,
  setVisibleColumns,
  recordsToShow,
  customGroupColors = {},
  showOverOrders,
  showColourModal,
  setShowColourModal,
}) {
  // Sorting states
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Column group colour customisation
  const [groupColors, setGroupColors] = useState({ ...defaultGroupColors, ...customGroupColors });


  // Modal states & selected items for cell view cards
  const [stockCellViewModalShow, setStockCellViewModalShow] = useState(false);
  const [purchaseOrderModalShow, setPurchaseOrderModalShow] = useState(false);
  const [salesOrderModalShow, setSalesOrderModalShow] = useState(false);
  
  const [uniqueSelection, setUniqueSelection] = useState(null);

  const [rowDetailsModalShow, setRowDetailsModalShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  // Map your columns to the corresponding data field

    /**
     * Persisting column layout to storage
     */
    
    // Key for localStorage
    const localStorageKey = 'JJDRP_visibleColumns';

    // Load saved column visibility from localStorage on component mount
    useEffect(() => {
      const savedColumns = localStorage.getItem(localStorageKey);
      if (savedColumns) {
        setVisibleColumns(JSON.parse(savedColumns));
      }
    }, [setVisibleColumns]);
  
    // Save column visibility to localStorage whenever it changes
    useEffect(() => {
      localStorage.setItem(localStorageKey, JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    useEffect(() => {
      if (showOverOrders) {
        if (!visibleColumns.includes('OODev')) {
          setVisibleColumns((prev) => [...prev, 'OODev']);
        }
      } else {
        if (visibleColumns.includes('OODev')) {
          setVisibleColumns((prev) => prev.filter((col) => col !== 'OODev'));
        }
      }
    }, [showOverOrders, visibleColumns, setVisibleColumns]);
    
  

  /**
   * Sorting
   */
  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const renderSortIcon = (column) => {
    if (sortColumn === column) {
      return sortDirection === 'asc' ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return null;
  };

  /**
   * Copy entire column data
   */
  const handleCopyColumn = (column) => {
    const columnData = limitedData.map(item => item[columnMap[column]]).join('\n');
    navigator.clipboard.writeText(columnData)
      .then(() => {
        alert(`Copied ${limitedData.length} values from column "${column}" to clipboard.`);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  /**
   * Apply simple numeric formatting
   */
  const formatValue = (value) => {
    if (typeof value === 'number') {
      const decimalPart = value.toString().split('.')[1];
      if (decimalPart && decimalPart.length > 2) {
        return value.toFixed(2);
      }
      return value;
    }
    return value;
  };

  // Map OODev dynamically for each row
  const processedData = JJDRPData.map((item) => ({
    ...item,
    OODev: item['Supplemented ATP'] - item['Stock Max'] // Add OODev calculation
  }));

  /**
   * Sorting logic
   */
  const sortedData = processedData.slice().sort((a, b) => {
    const aValue = sortColumn ? a[columnMap[sortColumn]] : null;
    const bValue = sortColumn ? b[columnMap[sortColumn]] : null;
    if (sortDirection === 'asc') return aValue < bValue ? -1 : 1;
    return aValue > bValue ? -1 : 1;
  });

  /**
   * Enforce row limit
   */
  const limitedData = sortedData.slice(0, recordsToShow);

  /**
   * Handle colour group changes
   */
  const handleColorChange = (group, color) => {
    setGroupColors(prevColors => ({
      ...prevColors,
      [group]: color
    }));
  };
  const handleRowClick = (row) => {
    setSelectedRowData(row);
    setRowDetailsModalShow(true);
  };

  /**
   * Generate rows & integrate CellViewCard triggers
   */
  const generateRows = () => {
    return limitedData.map((item, rowIndex) => (
      <tr 
        key={rowIndex}
        onClick={() => handleRowClick(item)} // Clicking the row calls handleRowClick
      >
        {columns
          .filter((col) => visibleColumns.includes(col)) // Ensure only visible columns are processed
          .map((col, colIndex) => {
            let cellValue;
  
            // Handle OODev column
            if (col === 'OODev') {
              cellValue = item['OODev'];
              const isGreen = cellValue <= 0;
              const isOrange = cellValue > 0 && cellValue < 12;
              const decideColour = isGreen ? 'limegreen' : isOrange ? 'orange' : 'lightcoral';
              return (
                <td
                  key={colIndex}
                  style={{
                    padding: '8px',
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: decideColour, // populate the colour based on the value
                  }}
                  title={cellValue} // Tooltip to show the full value
                >
                  {cellValue}
                </td>
              );
            }

  
            // Default cellValue assignment
            cellValue = formatValue(item[columnMap[col]]);

          // Check for Finish column and apply finishMap
          if (col === 'Acabado') {
            cellValue = finishMap[item['Acabado']] || item['Acabado']; // Map to shortened version if exists, else use the original
          } else {
            // Default cellValue assignment
            cellValue = formatValue(item[columnMap[col]]);
          }
  
            // Special case for "N2O Raw" column
            if (col === 'N2O Raw') {
              const isPositive = cellValue >= 0;
              return (
                <td
                  key={colIndex}
                  style={{
                    padding: '8px',
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: isPositive ? 'limegreen' : 'lightcoral', // Green for positive, red for negative
                  }}
                  title={cellValue} // Tooltip to show the full value
                >
                  {cellValue}
                </td>
              );
            }
  
            // Decide if the cell is clickable for modals:
            if (col === 'Current Available Stock (In Plant)') {
              return (
                <td
                  key={colIndex}
                  style={{
                    padding: '8px',
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setUniqueSelection(item['Unique']);
                    setStockCellViewModalShow(true);
                  }}
                  title={cellValue} // Optional: Add a tooltip for full values
                >
                  {cellValue}
                </td>
              );
            } else if (col === 'Current Purchase Orders') {
              return (
                <td
                  key={colIndex}
                  style={{
                    padding: '8px',
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setUniqueSelection(item['Unique']);
                    setPurchaseOrderModalShow(true);
                  }}
                  title={cellValue}
                >
                  {cellValue}
                </td>
              );
            } else if (col === 'Pending Sales Orders') {
              return (
                <td
                  key={colIndex}
                  style={{
                    padding: '8px',
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setUniqueSelection(item['Unique']);
                    setSalesOrderModalShow(true);
                  }}
                  title={cellValue}
                >
                  {cellValue}
                </td>
              );
            }
  
            // Default cell
            return (
              <td
                key={colIndex}
                style={{
                  padding: '8px',
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={cellValue} // Optional: Tooltip for default cells
              >
                {cellValue}
              </td>
            );
          })}
      </tr>
    ));
  };
  

  return (
    <div style={{ maxHeight: '78vh', overflowY: 'auto', margin: 'auto', maxWidth: '98%' }}>
      <Table striped bordered hover size="sm" variant="dark" style={{ minWidth: '1200px' }}>
        <thead>
          <tr>
            {columns.filter((column) => visibleColumns.includes(column)).map((column, index) => (
              <th
                key={index}
                style={{
                  backgroundColor: groupColors[columnGroups[column]],
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  padding: '0px',
                  textAlign: 'center',
                  fontSize: '14px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 0',
                  }}
                >
                  <span
                    onClick={() => handleSort(column)}
                    style={{
                      cursor: 'pointer',
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    {columnDisplayNames[column] || column}
                    {renderSortIcon(column)}
                  </span>
                </div>
              </th>
            ))}
          </tr>
      </thead>
    
      <tbody>{generateRows()}</tbody>
    </Table>
  
      {/* Modal for changing group colours */}
      <Modal show={showColourModal} onHide={() => setShowColourModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'lightgrey' }}>Customize Group Colors</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: 'lightgrey' }}>
          {Object.keys(defaultGroupColors).map((group, index) => (
            <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <label style={{ minWidth: '150px' }}>{group}</label>
              <input
                type="color"
                value={groupColors[group]}
                onChange={(e) => handleColorChange(group, e.target.value)}
                style={{ marginLeft: '10px' }}
              />
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setGroupColors(defaultGroupColors)}>
            Reset to Default
          </Button>
          <Button variant="primary" onClick={() => setShowColourModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  
      {/* CellViewCard Modals */}
      {uniqueSelection && (
        <StockCellViewCard
          uniqueSelection={uniqueSelection}
          cardHeaderText="Available Stock"
          show={stockCellViewModalShow}
          onHide={() => setStockCellViewModalShow(false)}
        />
      )}
      {uniqueSelection && (
        <PurchaseOrderCellViewCard
          uniqueSelection={uniqueSelection}
          cardHeaderText="Purchase Orders"
          show={purchaseOrderModalShow}
          onHide={() => setPurchaseOrderModalShow(false)}
        />
      )}
      {uniqueSelection && (
        <SalesOrderCellViewCard
          uniqueSelection={uniqueSelection}
          cardHeaderText="Sales Orders"
          show={salesOrderModalShow}
          onHide={() => setSalesOrderModalShow(false)}
        />
      )}
      {/* Row Details Modal */}
      <RowDetailsModal
        show={rowDetailsModalShow}
        onHide={() => setRowDetailsModalShow(false)}
        selectedRowData={selectedRowData}
      />
    </div>
  );
  
}

export default JJDRPTable;
