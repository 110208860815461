import React from 'react';
import { Form } from 'react-bootstrap';

const RackDivisionSliders = ({
  divisionIndicator3mm,
  setDivisionIndicator3mm,
  divisionIndicator6mm,
  setDivisionIndicator6mm,
  divisionIndicator12mm,
  setDivisionIndicator12mm,
  divisionIndicator20mm,
  setDivisionIndicator20mm
}) => {
  const containerStyle = {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "15px",
    margin: "15px auto",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    background: "black",
    width: "95%",
    textAlign: "center"
  };

  const sliderStyle = {
    width: "90%",
    margin: "5px auto"
  };

  const labelStyle = {
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
    display: "block",
    marginBottom: "3px"
  };

  const valueStyle = {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white"
  };

  const groupStyle = {
    padding: "10px 0"
  };

  return (
    <div className='rack-division-sliders' style={containerStyle}>
      <h6 style={{ marginBottom: "10px", fontWeight: "bold" }}>Rack Division Indicators</h6>

      <Form.Group style={groupStyle}>
        <Form.Label style={labelStyle}>3mm</Form.Label>
        <Form.Range 
          min={0} 
          max={116} 
          step={1}
          value={divisionIndicator3mm}
          onChange={(e) => setDivisionIndicator3mm(Number(e.target.value))}
          style={sliderStyle}
        />
        <div style={valueStyle}>{divisionIndicator3mm}</div>
      </Form.Group>

      <hr style={{ margin: "8px 0", borderTop: "1px solid #bbb" }} />

      <Form.Group style={groupStyle}>
        <Form.Label style={labelStyle}>6mm</Form.Label>
        <Form.Range 
          min={0} 
          max={58} 
          step={1}
          value={divisionIndicator6mm}
          onChange={(e) => setDivisionIndicator6mm(Number(e.target.value))}
          style={sliderStyle}
        />
        <div style={valueStyle}>{divisionIndicator6mm}</div>
      </Form.Group>

      <hr style={{ margin: "8px 0", borderTop: "1px solid #bbb" }} />

      <Form.Group style={groupStyle}>
        <Form.Label style={labelStyle}>12mm</Form.Label>
        <Form.Range 
          min={0} 
          max={32} 
          step={1}
          value={divisionIndicator12mm}
          onChange={(e) => setDivisionIndicator12mm(Number(e.target.value))}
          style={sliderStyle}
        />
        <div style={valueStyle}>{divisionIndicator12mm}</div>
      </Form.Group>

      <hr style={{ margin: "8px 0", borderTop: "1px solid #bbb" }} />

      <Form.Group style={groupStyle}>
        <Form.Label style={labelStyle}>20mm</Form.Label>
        <Form.Range 
          min={0} 
          max={32} 
          step={1}
          value={divisionIndicator20mm}
          onChange={(e) => setDivisionIndicator20mm(Number(e.target.value))}
          style={sliderStyle}
        />
        <div style={valueStyle}>{divisionIndicator20mm}</div>
      </Form.Group>
    </div>
  );
};

export default RackDivisionSliders;
