export const ReturnLangaugeSelection = (rawData, searchValue, expectedLang) => {
    const acceptedLangs = ['ENG', 'ESP'];
    //Test Error and stop if selected language isn't valid
    if (!acceptedLangs.includes(expectedLang)) {
        alert('Invalid expectedLang value. Please use "ENG" or "ESP"');
        return;
    }
    const headerRowKeys = Object.keys(rawData[0]);
    const langTest = headerRowKeys.filter((key) => key.includes(searchValue));
    if (langTest) {
        return expectedLang;
    }
    else {
        return console.log('Language is not Found, please try again');
    }
}

function convertExcelDateToJSDate(serial) {
    // Excel's "day 1" is 1 January 1900.
    // 25569 is the number of days between 1/1/1900 and 1/1/1970 (Unix epoch).
    return new Date((serial - 25569) * 86400 * 1000);
  }
  
  export function convertExcelDateToUKDateString(serial) {
    const date = convertExcelDateToJSDate(serial);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }