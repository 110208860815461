import React from 'react'
import PlanItDash from './PlanItDash/PlanItDash'
import PlanItStockMain from './PlanItStock/PlanItStockMain'

function PlanItViewCycler({
  selectedView,
  planItProps,
  AppProps,
  PaginationProps,
  totalWeightSelected,
  totalRacksOccupied,
  totalSlabsSelected,
  setTotalWeightSelected,
  setTotalRacksOccupied,
  setTotalSlabsSelected,
  divisionIndicator3mm,
  divisionIndicator6mm,
  divisionIndicator12mm,
  divisionIndicator20mm,
  setDivisionIndicator3mm,
  setDivisionIndicator6mm,
  setDivisionIndicator12mm,
  setDivisionIndicator20mm,
  selectedFiltersLabel,
  stockViewFilterProps,
  salesOrPurchaseOrderFilter,
  deliveryNoteFilter,
}) {
  return (
    <div className='plan-it-view-cyler'>
        {selectedView === 'PlanIt Dash' && (
            <PlanItDash
                planItProps={planItProps}
                AppProps={AppProps}
                PaginationProps={PaginationProps}
                selectedFiltersLabel={selectedFiltersLabel}
                setTotalWeightSelected={setTotalWeightSelected}
                setTotalRacksOccupied={setTotalRacksOccupied}
                setTotalSlabsSelected={setTotalSlabsSelected}
                totalWeightSelected={totalWeightSelected}
                totalRacksOccupied={totalRacksOccupied}
                totalSlabsSelected={totalSlabsSelected}
                divisionIndicator3mm={divisionIndicator3mm}
                divisionIndicator6mm={divisionIndicator6mm}
                divisionIndicator12mm={divisionIndicator12mm}
                divisionIndicator20mm={divisionIndicator20mm}
                setDivisionIndicator3mm={setDivisionIndicator3mm}
                setDivisionIndicator6mm={setDivisionIndicator6mm}
                setDivisionIndicator12mm={setDivisionIndicator12mm}
                setDivisionIndicator20mm={setDivisionIndicator20mm}
                salesOrPurchaseOrderFilter={salesOrPurchaseOrderFilter}
                deliveryNoteFilter={deliveryNoteFilter}
            />
        )}
        {selectedView === 'Stock' && (
            <PlanItStockMain stockViewFilterProps={stockViewFilterProps} />
        )}
    </div>
  )
}

export default PlanItViewCycler
