import React, { useState, useEffect, useCallback, useMemo } from 'react';
import VBC_ClientSelectionBar from './VBC_ClientSelectionBar';
import '../_VentasStyles/VentasByClient.css';
import VBC_MainDisplayArea from './VBC_MainDisplayArea';
import { CalculateKPIs, getGrossData, getInvoicedData, getCreditedData, getGrossDataInvoicedAmount, getCreditedDataAmount, getInvoicedDataAmount } from '../../../../Utils/AggregateFunctions';
import { excelDateToJSDate } from '../../../../Utils/DateOps';
import centersAndCurrencies from '../../../../Utils/CentersAndCurrencies.json'; // Import the centers and currencies

function VentasByClientParent({ ventasData }) {
    const currentDate = new Date();
    const startOfYear = useMemo(() => {
        const now = new Date();
        return new Date(now.getFullYear(), 0, 1);
      }, []);

    const [selectedClient, setSelectedClient] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [startDate, setStartDate] = useState(startOfYear.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(currentDate.toISOString().split('T')[0]);
    const [dataType, setDataType] = useState('Net');
    const [selectedKPI, setSelectedKPI] = useState('Net Invoiced Amount');
    const [chartData, setChartData] = useState([]);
    const [displayMode, setDisplayMode] = useState('KPIGrid');
    const [localCurrency, setLocalCurrency] = useState('GBP'); // Default to GBP, but will be set dynamically

    useEffect(() => {
        if (ventasData.length > 0) {
            const firstRecord = ventasData[0];
            const society = firstRecord["Society"];
            const centerInfo = centersAndCurrencies.find(
                (center) => center.Society === society
            );

            if (centerInfo) {
                setLocalCurrency(centerInfo["Display Currency"]);
            }
        }
    }, [ventasData]);

        // Update start date when display mode changes
        useEffect(() => {
            if (displayMode === "YearlyAccountAnalysis") {
                setStartDate("2022-01-01");
            }
        }, [displayMode]);

    // Memoize the filterData function
    const filterData = useCallback(() => {
        let data = ventasData;

        if (selectedClient.length > 0) {
            data = data.filter((item) => selectedClient.includes(item['Client Description']));
        }

        if (startDate) {
            const startDateObj = new Date(startDate);
            data = data.filter((item) => {
                const invoiceDate = excelDateToJSDate(item['Invoice Document Date']);
                return invoiceDate >= startDateObj;
            });
        }

        if (endDate) {
            const endDateObj = new Date(endDate);
            data = data.filter((item) => {
                const invoiceDate = excelDateToJSDate(item['Invoice Document Date']);
                return invoiceDate <= endDateObj;
            });
        }

        setFilteredData(data);
    }, [ventasData, selectedClient, startDate, endDate]);

    // Memoize the updateChartData function
    const updateChartData = useCallback(() => {
        let data = filteredData;
        const currency = localCurrency; // Use localCurrency determined dynamically

        switch (selectedKPI) {
            case "Net Invoiced Amount":
                switch (dataType) {
                    case "Net":
                        setChartData(getGrossDataInvoicedAmount(data, currency));
                        break;
                    case "Invoiced":
                        setChartData(getInvoicedDataAmount(data, currency));
                        break;
                    case "Credited":
                        setChartData(getCreditedDataAmount(data, currency));
                        break;
                    default:
                        setChartData(getGrossDataInvoicedAmount(data, currency));
                        break;
                }
                break;
            case "Net Invoiced Amount (Group Currency)":
                switch (dataType) {
                    case "Net":
                        setChartData(getGrossDataInvoicedAmount(data, "Group Currency"));
                        break;
                    case "Invoiced":
                        setChartData(getInvoicedDataAmount(data, "Group Currency"));
                        break;
                    case "Credited":
                        setChartData(getCreditedDataAmount(data, "Group Currency"));
                        break;
                    default:
                        setChartData(getGrossDataInvoicedAmount(data, "Group Currency"));
                        break;
                }
                break;
            case "Billed Quantity":
                switch (dataType) {
                    case "Net":
                        setChartData(getGrossData(data, "Billed Quantity"));
                        break;
                    case "Invoiced":
                        setChartData(getInvoicedData(data, "Billed Quantity"));
                        break;
                    case "Credited":
                        setChartData(getCreditedData(data, "Billed Quantity"));
                        break;
                    default:
                        setChartData(getGrossData(data, "Billed Quantity"));
                        break;
                }
                break;
            case "Transport Cost":
                const t_data = data.filter((item) => item['Material Description'].toLowerCase().includes('transport'));
                switch (dataType) {
                    case "Net":
                        setChartData(getGrossData(t_data, "Net Billed Amount"));
                        break;
                    case "Invoiced":
                        setChartData(getInvoicedData(t_data, "Net Billed Amount"));
                        break;
                    case "Credited":
                        setChartData(getCreditedData(t_data, "Net Billed Amount"));
                        break;
                    default:
                        setChartData(getGrossData(t_data, "Net Billed Amount"));
                        break;
                }
                break;
            case "Área Total Real\nTabla Factura (M2)":
                switch (dataType) {
                    case "Net":
                        setChartData(getGrossData(data, "Área Total Real\nTabla Factura (M2)"));
                        break;
                    case "Invoiced":
                        setChartData(getInvoicedData(data, "Área Total Real\nTabla Factura (M2)"));
                        break;
                    case "Credited":
                        setChartData(getCreditedData(data, "Área Total Real\nTabla Factura (M2)"));
                        break;
                    default:
                        setChartData(getGrossData(data, "Área Total Real\nTabla Factura (M2)"));
                        break;
                }
                break;
            default:
                setChartData(getGrossDataInvoicedAmount(data, currency));
                break;
        }
    }, [filteredData, selectedKPI, dataType, localCurrency]);

    useEffect(() => {
        filterData();
    }, [selectedClient, startDate, endDate, ventasData, dataType, filterData]);

    useEffect(() => {
        updateChartData();
    }, [selectedKPI, dataType, filteredData, selectedClient, startDate, endDate, localCurrency, updateChartData]);

    const handleDataTypeChange = (val) => {
        setDataType(val);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleDisplayModeChange = (val) => {
        setDisplayMode(val);
    };

    const kpis = CalculateKPIs(filteredData, dataType, localCurrency);

    return (
        <div className='-Ventas-By-Client-Parent-'>
            <VBC_ClientSelectionBar 
                ventasData={ventasData} 
                setSelectedClient={setSelectedClient} 
                selectedClient={selectedClient}
                displayMode={displayMode}
                handleDisplayModeChange={handleDisplayModeChange} 
            />
            <VBC_MainDisplayArea 
                startDate={startDate}
                endDate={endDate}
                dataType={dataType}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleDataTypeChange={handleDataTypeChange}
                kpis={kpis}
                setSelectedKPI={setSelectedKPI}
                selectedKPI={selectedKPI}
                chartData={chartData}
                displayMode={displayMode}
                handleDisplayModeChange={handleDisplayModeChange}
                handleCurrencyChange={setLocalCurrency} 
                filteredData={filteredData}
                localCurrency={localCurrency}
            />
        </div>
    );
}

export default VentasByClientParent;
