import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function PlanItViewFunctionDropdown({ handleSelectViewClick, navBarBrandTitle, navDropDownIdAndTitle, textArray }) {
  if (!Array.isArray(textArray) || textArray.length === 0) {
    return <div>Something went wrong. Text options are not recognized correctly.</div>;
  }

  return (
    <div className="d-flex align-items-center">
      {/* Restrict the brand width and prevent extra spacing */}
      <Navbar.Brand style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: "bold", marginLeft: "15px" }}>
        {navBarBrandTitle}
      </Navbar.Brand>

      {/* Ensure NavDropdown doesn't take unnecessary width */}
      <Nav>
        <NavDropdown
          id={navDropDownIdAndTitle}
          title={navDropDownIdAndTitle}
          menuVariant="dark"
          className="text-nowrap"
          style={{ fontSize: "13px", padding: "5px 8px" }}
        >
          {textArray.map((item, index) => (
            <NavDropdown.Item key={index} onClick={() => handleSelectViewClick(item)}>
              {item}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </Nav>
    </div>
  );
}

export default PlanItViewFunctionDropdown;
