import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, {useRef, useEffect, useState} from 'react';
import Home from './Pages/Home';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomeBar from './Components/CoreComponents/HomeBar';
import SideMenu from './Components/CoreComponents/SideMenu';
import FeedbackForm from './Pages/FeedbackForm';
import ClaimForBreakageForm from './Pages/ClaimForBreakageForm';
import RolesChecker from './Auth/RolesChecker';
import StockTools from './Pages/StockTools';
import Insights from './Pages/Insights';
import ProtectedRoute from './Auth/ProtectedRoute';
import DiscountManager from './Pages/DiscountManager';
import Excel from './Pages/Excel';
import ExcelDash from './Components/Excels/ExcelDRP/Dash/ExcelDash';
import PlanItParent from './Pages/PlanItParent';
import CUBOAnalyserParent from './Components/Excels/CUBOAnalyser/CUBOAnalyserParentConfig/CUBOAnalyserParent';
import DebtPortalParent from './Components/Excels/DebtPortal/DebtPortalParent';
import VentasAnalyserConfig from './Components/Excels/VentasAnalyser/VentasAnalyserConfig';
import VentasAnalyserCycler from './Components/Excels/VentasAnalyser/VentasAnalyserCycler';
import JJDRPMain from './Components/Excels/JJDRP/Main';
import ReactGA from 'react-ga4';
import DynamicJSONTable from './Components/Reusables/DynamicJSONTable';
import ABCXYZMain from './Components/Excels/ABCXYZ/ABCXYZMain';
import CustomsSummariserMain from './Components/Excels/CustomsSummariser/CustomsSummariserMain';


function App() {  
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const sideMenuRef = useRef(null);
  const [token, setToken] = useState(null);
  const [loggedInuser, setLoggedInUser] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [canUseInsights, setCanUseInsights] = useState(false);

  //DataProps
  const [DIOData, setDIOData] = useState([]);
  const [SalesData, setSalesData] = useState([]);
  const [StockData, setStockData] = useState([]);
  const [OrderData, setOrderData] = useState([]);
  const [POWithHQData, setPOWithHQData] = useState([]);



  //ExcelProps
  const [excelIQ09Path, setExcelIQ09Path] = useState([]);
  const [excelVA05Path, setExcelVA05Path] = useState([]);
  const [excelME2NPath, setExcelME2NPath] = useState([]);
  const [excelCUBOPath, setExcelCUBOPath] = useState([]);
  const [excelCodexPath, setExcelCodexPath] = useState([]);
  const [excelDIOPath, setExcelDIOPath] = useState([]);
  const [excelDataValid, setExcelDataValid] = useState(false);
  const [excelDRPConcept, setExcelDRPConcept] = useState([]);
  const [dynamicMode, setDynamicMode] = useState("");
  const [budget, setBudget] = useState(0);
  const [lyTurnover, setLyTurnover] = useState(0);
  const [transitDays, setTransitDays] = useState(0);
  const [transitInfoAdded, setTransitInfoAdded] = useState(false);
  const [budgetInfoAdded, setBudgetInfoAdded] = useState(false);
  const [saveDataForFutureUse, setSaveDataForFutureUse] = useState(false);
  const [budgetAnalysisData, setBudgetAnalysisData] = useState({});
  const [overrideCodexData, setOverrideCodexData] = useState(false);

  //Debt Portal Data
  const [debtPortalPath, setDebtPortalPath] = useState([]);
  const [debtPortalData, setDebtPortalData] = useState([]);

  const AppProps = {
    token: token,
    setToken: setToken,
    loggedInuser: loggedInuser,
    setLoggedInUser: setLoggedInUser,
    isAuthed: isAuthed,
    setIsAuthed: setIsAuthed,
    permissions: permissions,
    setPermissions: setPermissions,
    canUseInsights: canUseInsights,
    setCanUseInsights: setCanUseInsights,
  };

  const DRPProps = {
    // filteredData: filteredData,
    // setFilteredData: setFilteredData,
  };

  const DataProps = {
    DIOData: DIOData,
    setDIOData: setDIOData,
    SalesData: SalesData,
    setSalesData: setSalesData,
    StockData: StockData,
    setStockData: setStockData,
    OrderData: OrderData,
    setOrderData: setOrderData,
    POwithHQData: POWithHQData,
    setPOWithHQData: setPOWithHQData,
  };

  const ExcelProps = {
    excelIQ09Path: excelIQ09Path,
    setExcelIQ09Path: setExcelIQ09Path,
    excelVA05Path: excelVA05Path,
    setExcelVA05Path: setExcelVA05Path,
    excelME2NPath: excelME2NPath,
    setExcelME2NPath: setExcelME2NPath,
    excelCUBOPath: excelCUBOPath,
    setExcelCUBOPath: setExcelCUBOPath,
    excelCodexPath: excelCodexPath,
    setExcelCodexPath: setExcelCodexPath,
    excelDIOPath: excelDIOPath,
    setExcelDIOPath: setExcelDIOPath,
    excelDataValid: excelDataValid,
    setExcelDataValid: setExcelDataValid,
    excelDRPConcept: excelDRPConcept,
    setExcelDRPConcept: setExcelDRPConcept,
    dynamicMode: dynamicMode,
    setDynamicMode: setDynamicMode,
    budget: budget,
    setBudget: setBudget,
    lyTurnover: lyTurnover,
    setLyTurnover: setLyTurnover,
    transitDays: transitDays,
    setTransitDays: setTransitDays,
    transitInfoAdded: transitInfoAdded,
    setTransitInfoAdded: setTransitInfoAdded,
    budgetInfoAdded: budgetInfoAdded,
    setBudgetInfoAdded: setBudgetInfoAdded,
    saveDataForFutureUse: saveDataForFutureUse,
    setSaveDataForFutureUse: setSaveDataForFutureUse,
    budgetAnalysisData: budgetAnalysisData,
    setBudgetAnalysisData: setBudgetAnalysisData,
    overrideCodexData: overrideCodexData,
    setOverrideCodexData: setOverrideCodexData,

  };

  const debtPortalProps = {
    debtPortalPath: debtPortalPath,
    setDebtPortalPath: setDebtPortalPath,
    debtPortalData: debtPortalData,
    setDebtPortalData: setDebtPortalData,
  };

  const location = useLocation();

  useEffect(() => {
    // Use the left property to control the slide-in animation
    if (sideMenuVisible) {
      sideMenuRef.current.style.left = '0';
    } else {
      sideMenuRef.current.style.left = '-300px'; // Slide off-screen
    }
  }, [sideMenuVisible]);

  useEffect(() => {
    // Track page views on route change
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    // Replace 'G-XXXXXXXXXX' with your actual Measurement ID
    ReactGA.initialize('G-4T95KJ8RYL');

    // To track the initial page load
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);


  return (
    <div className="App">
      <HomeBar setSideMenuVisible={setSideMenuVisible}/>
      <SideMenu sideMenuVisible={sideMenuVisible} sideMenuRef={sideMenuRef} setSideMenuVisible={setSideMenuVisible} AppProps={AppProps}/>
      <RolesChecker AppProps={AppProps}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Feedback" element={<FeedbackForm />} />
        <Route path="/ClaimBreakage" element={<ClaimForBreakageForm />} />
        <Route path="/PlanIt" element={<PlanItParent AppProps={AppProps} />} />
        <Route path="/ExcelDash" element={<ExcelDash ExcelProps={ExcelProps} AppProps={AppProps} />} />
        <Route path="/DiscountManager" element={<DiscountManager AppProps={AppProps} ExcelProps={ExcelProps}/>} />
        <Route path="/Excel" element={<Excel ExcelProps={ExcelProps} AppProps={AppProps} DataProps={DataProps}/>} />
        <Route path="/VentasAnalyser" element={<VentasAnalyserCycler ExcelProps={ExcelProps} AppProps={AppProps} />} />
        <Route path="/JJDRP" element={<JJDRPMain ExcelProps={ExcelProps} AppProps={AppProps} />} />
        <Route path="/DebtPortal" element={<DebtPortalParent AppProps={AppProps} ExcelProps={ExcelProps} debtPortalProps={debtPortalProps}  />}/>
        <Route path="/TestGround" element={<DynamicJSONTable />}/>
        <Route path="/ABCXYZ" element={<ABCXYZMain />}/>
        <Route path="/CustomsSummariser" element={<CustomsSummariserMain />}/>
        <Route 
          path="/StockTools" 
          element={
            <ProtectedRoute AppProps={AppProps} DRPProps={DRPProps} DataProps={DataProps} element={<StockTools />}></ProtectedRoute>
          } />
        <Route //Protect
          path="/Insights"
          element={
            <ProtectedRoute AppProps={AppProps} DataProps={DataProps} element={<Insights />}></ProtectedRoute>
          }
        />
        {/* <Route //protect
          path="/Excel" 
          element={
            <ProtectedRoute AppProps={AppProps} DataProps={DataProps} ExcelProps={ExcelProps} element={<Excel />}></ProtectedRoute> 
          }
        /> */}
        <Route //protect
          path="/CUBOAnalyser" 
          element={
            <ProtectedRoute AppProps={AppProps} DataProps={DataProps} ExcelProps={ExcelProps} element={<CUBOAnalyserParent />}></ProtectedRoute> 
          }
        />
        {/* <Route //protect
          path="/DebtPortal" 
          element={
            <ProtectedRoute AppProps={AppProps} ExcelProps={ExcelProps} debtPortalProps={debtPortalProps} element={<DebtPortalParent />}></ProtectedRoute> 
          }
        /> */}
      </Routes>
    </div>
  );
}

export default App;
