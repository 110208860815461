import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import decorData from '../../../../Utils/CodexFlushed.json'; // Import the JSON data

// Function to transform the decor data
const transformDecorData = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return [];
    }

    // Extract unique decor names and their associated Half Codes
    const decorMap = data.reduce((acc, item) => {
        const { Decor, 'Decor Code': DecorCode, 'Half Code': HalfCode, 'Material Description': MaterialDescription } = item;
        if (!acc[Decor]) {
            acc[Decor] = {
                'Decor Name': Decor,
                'Decor Code': DecorCode,
                'Half Code': HalfCode,
                'Material Description': MaterialDescription
            };
        }
        return acc;
    }, {});

    return Object.values(decorMap);
};

function VBC_SalesBrowserDecorSelectionBar({ setSelectedHalfCode, selectedHalfCode, setSelectedDecor, selectedDecor }) {
    const [decors, setDecors] = useState([]);

    useEffect(() => {
        // Transform and set decors
        const transformedDecors = transformDecorData(decorData);
        transformedDecors.sort((a, b) => a['Decor Name'].localeCompare(b['Decor Name'])); // Sort alphabetically
        setDecors(transformedDecors);
    }, []);

    return (
        <div className='VBC-SalesBrowser-Decor-Selection-Bar'>
            <div className='decor-selection-title-box'>
                <span style={{ fontSize: "small" }}>Select Decor</span>
                <Button 
                    variant="danger"
                    className='VBC-SalesBrowser-Decor-Clear-Button'
                    onClick={() => setSelectedHalfCode('')}
                    style={{ fontWeight: 'bold', justifyContent: "space-between", fontSize: 'small' }}
                    size='sm'
                >
                    Clear Decor
                </Button>
            </div>
            <div className='VBC-SalesBrowser-Decor-Selection-Bar-Button-Holder'>
                {decors.map((decor, index) => (
                    <Button 
                        variant={selectedHalfCode === decor['Half Code'] ? "success" : "warning"} 
                        key={index} 
                        className={`VBC-SalesBrowser-Decor-Selection-Button ${selectedHalfCode === decor['Half Code'] ? 'selected' : ''}`}
                        onClick={() => setSelectedHalfCode(decor['Half Code'])}
                        style={{ fontWeight: 'bold' }}
                        size='sm'
                    >
                        {decor['Decor Name']}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default VBC_SalesBrowserDecorSelectionBar;
