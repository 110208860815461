export const columnsMap = {
    "Color": "Decor Code",
    "Disp ped": "Slabs ATP",
    "Espesor": "Thickness",
    "Lote": "Lote",
    "Malla": "Mesh",
    "Texto breve de material": "Material Description",
    "Fin": "Finish",
    "Material": "Material Code",
    "Q Prod": "Quality",
    "Size": "Format",
    "Segmento": "Segment",
};