import './PlanIt.css';
import '../../../Styles/Excels/ExcelFileConfig.css';
import React, { useEffect, useState } from 'react';
import FileInputContainer from "../../Reusables/FileInputContainer";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { ReadExceslForPlanIt, SavePlanItDataToDexie, GetStatusOrdersDataFromDexie } from '../../../Utils/PlanItFunctions';
import Loading from '../../Reusables/Loading';

const PlanItConfig = ({ planItProps }) => {
    const { statusOrdersData, setStatusOrdersData, setDataIsValid } = planItProps;
    const [loading, setLoading] = useState(false);

    const fetchFromDexie = async () => {
        try {
            const statusOrdersData = await GetStatusOrdersDataFromDexie();
            if (statusOrdersData?.length) {
                setStatusOrdersData(statusOrdersData);
                setDataIsValid(true);
            } else {
                alert("There was no Data in Dexie, you'll need to upload data to continue. If you did that, there is an unknown issue, speak to Jack");
            }
        } catch (error) {
            console.error("Dexie error:", error);
            setStatusOrdersData([]);
            setDataIsValid(false);
            alert("There was an unknown error preventing you from continuing, please try again or contact Jack.");
        }
    };

    const handlePlanItSetup = async () => {
        try {
            setLoading(true);
            const planItData = await ReadExceslForPlanIt(statusOrdersData);
            console.log("Saving data for future use");
            await SavePlanItDataToDexie(planItData);
            await fetchFromDexie();
            console.log("Data saved to Dexie and ready to go");
            setLoading(false);
        } catch (error) {
            console.error("Error during setup:", error);
            alert("Failed to process data. Please try again.");
        }
    };

    useEffect(() => {
        fetchFromDexie();
    }, [setDataIsValid, setStatusOrdersData]);

    return (
        <div className="-PlanIt-">
            {!loading &&
                <Card className='data-browser-card'>
                    <Card.Header>Input Status Orders Spreadsheet</Card.Header>
                        <Card.Body className='PlanIt-card-body'>
                        <FileInputContainer labelTitle="Status Orders" selectedFilePath={statusOrdersData} setSelectedFile={setStatusOrdersData} />
                        <div className='button-holder-div'>
                            <Button variant='warning' className="custom-button-gold plan-it-go-button" onClick={handlePlanItSetup}>
                                Go
                            </Button>    
                        </div>
                    </Card.Body>
                    
                    {loading && <Loading />}
                </Card>
            }   
        </div>
    );
};

export default PlanItConfig;
