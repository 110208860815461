import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import { connectToDB, GetData } from '../../../../Utils/DBOps';
import { columnsMap } from './PlanItStockColumnsMap';
import codexFlushed from '../../../../Utils/CodexFlushed.json';
import '../PlanItDash/PlanningTable.css'; // Ensure this CSS contains your scrollable table and sticky header styles

function PlanItStockMain({ stockViewFilterProps = {} }) {
  // Destructure filter props with defaults
  const {
    stockViewDecorFilter = '',
    stockViewSelectedDecors = [],
    stockViewFinishFilter = '',
    stockViewSelectedFinishes = [],
    stockViewThicknessFilter = '',
    stockViewSelectedThicknesses = [],
    stockViewFormatFilter = '',
    stockViewSelectedFormats = [],
  } = stockViewFilterProps;

  const [formattedData, setFormattedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(300); // Default to 300 rows per page
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Compute a label that shows the currently selected filters
  const selectedStockFiltersLabel = [
    stockViewDecorFilter && `Decor: ${stockViewDecorFilter}`,
    stockViewFinishFilter && `Finish: ${stockViewFinishFilter}`,
    stockViewThicknessFilter && `Thickness: ${stockViewThicknessFilter}`,
    stockViewFormatFilter && `Format: ${stockViewFormatFilter}`,
  ]
    .filter(label => label)
    .join(" | ");

  useEffect(() => {
    const getStockData = async () => {
      const db = await connectToDB();
      const stockData = await GetData(db, 'LogisticsStockData');

      if (!Array.isArray(stockData) || stockData.length === 0 || !Array.isArray(stockData[0])) {
        console.warn("No stock data available.");
        return;
      }

      // Process all records: rename keys using columnsMap
      const processedData = stockData[0].map(row => {
        let newRow = {};
        Object.keys(row).forEach(key => {
          let newKey = key;
          for (const mapKey in columnsMap) {
            if (key.toUpperCase().trim() === mapKey.toUpperCase().trim()) {
              newKey = columnsMap[mapKey];
              break;
            }
          }
          newRow[newKey] = row[key];
        });
        return newRow;
      });

      setFormattedData(processedData);
    };

    getStockData();
  }, []);

  /** === APPLY FILTERS === */
  const applyFilters = () => {
    if (!formattedData || formattedData.length === 0) return formattedData;
    let filtered = [...formattedData];

    // --- Decor Filter ---
    if (Array.isArray(stockViewSelectedDecors) && stockViewSelectedDecors.length > 0) {
      const decorCodeSet = new Set();
      stockViewSelectedDecors.forEach(decor => {
        const matchedEntry = codexFlushed.find(item => item.Decor.toLowerCase() === decor.toLowerCase());
        if (matchedEntry && matchedEntry["Half Code"] && matchedEntry["Half Code"].length >= 7) {
          // Remove first two characters from "Half Code"
          const decorCode = matchedEntry["Half Code"].substring(2);
          decorCodeSet.add(decorCode);
        }
      });

      filtered = filtered.filter(item => {
        if (!item["Material Code"] || item["Material Code"].length < 7) return false;
        // Example: "TC00208KA12P02E" -> substring(2,7) yields "00208"
        const materialDecor = item["Material Code"].substring(2, 7);
        return decorCodeSet.has(materialDecor);
      });
    }

    // --- Finish Filter ---
    if (Array.isArray(stockViewSelectedFinishes) && stockViewSelectedFinishes.length > 0) {
      const finishCodes = new Set(stockViewSelectedFinishes.map(f => f.toUpperCase()));

      filtered = filtered.filter(item => {
        if (!item["Material Code"] || item["Material Code"].length < 9) return false;
        const materialFinish = item["Material Code"].substring(7, 9);
        return finishCodes.has(materialFinish);
      });
    }

    // --- Thickness Filter ---
    if (Array.isArray(stockViewSelectedThicknesses) && stockViewSelectedThicknesses.length > 0) {
      const thicknessCodes = new Set(stockViewSelectedThicknesses.map(t => t.toString().padStart(2, '0')));

      filtered = filtered.filter(item => {
        if (!item["Material Code"] || item["Material Code"].length < 11) return false;
        const materialThickness = item["Material Code"].substring(9, 11);
        return thicknessCodes.has(materialThickness);
      });
    }

    // --- Format Filter ---
    if (Array.isArray(stockViewSelectedFormats) && stockViewSelectedFormats.length > 0) {
      const formatCodes = new Set(stockViewSelectedFormats.map(f => f.toString().padStart(2, '0')));

      filtered = filtered.filter(item => {
        if (!item["Material Code"] || item["Material Code"].slice(-1) !== 'E' || item["Material Code"].length < 3) return false;
        const materialFormat = item["Material Code"].slice(-3, -1);
        return formatCodes.has(materialFormat);
      });
    }

    return filtered;
  };

  const filteredData = applyFilters();

  /** === Sorting Logic === */
  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key] ?? "";
    const bValue = b[sortConfig.key] ?? "";
    return sortConfig.direction === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
  });

  /** === Pagination Logic === */
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  /** === Handle Sorting on Column Click === */
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="planning-table-container" style={{ maxHeight: '80vh' }}>
      {/* Selected Filters Label */}
      <div style={{ fontSize: '14px', marginTop: '10px', marginLeft: '10px', color: '#ddd' }}>
        {selectedStockFiltersLabel || "No Stock filters selected."}
      </div>
      <div className="table-wrapper" style={{ marginTop: "25px" }}>
        <Table striped bordered hover variant="dark" size="sm">
          <thead className="sticky-header">
            <tr>
              {filteredData.length > 0 && Object.keys(filteredData[0]).map((key, index) => (
                <th
                  key={key}
                  onClick={() => handleSort(key)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: index % 2 === 0 ? 'rgb(14, 81, 99)' : 'rgb(9, 100, 125)',
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  {key} {sortConfig.key === key ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {currentRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(row).map((value, colIndex) => (
                  <td key={colIndex}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="pagination-controls-bottom">
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          />
          <Pagination.Item active>{`Page ${currentPage} of ${totalPages}`}</Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default PlanItStockMain;
