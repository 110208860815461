import React from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';

const VBC_TopMaterialsTable = ({ topMaterials, setSelectedMaterial, localCurrency, groupByDecor }) => {
    // Compute overall total from the aggregated data using absolute values of Total Amount.
    const overallTotal = topMaterials.reduce(
        (acc, item) => acc + Math.abs(item['Total Amount']),
        0
    );

    return (
        <Table striped bordered hover variant="dark" size='sm' className='top-materials-table'>
            <thead>
                <tr>
                    {groupByDecor ? (
                        <>
                            <th>Decor</th>
                        </>
                    ) : (
                        <>
                            <th>Material</th>
                            <th>Material Description</th>
                        </>
                    )}
                    <th>Total Quantity</th>
                    <th>Total Amount</th>
                    <th>As %</th>
                </tr>
            </thead>
            <tbody className='sales-browser-table-body'>
                {topMaterials.length > 0 ? (
                    topMaterials.map((item, index) => {
                        // Compute the percentage for this row.
                        const percentage = overallTotal > 0 
                            ? ((Math.abs(item['Total Amount']) / overallTotal) * 100).toFixed(0)
                            : 0;
                        return (
                            <tr 
                                key={index} 
                                onClick={() => setSelectedMaterial(groupByDecor ? item.Decor : item['Material'])}
                                style={{ cursor: 'pointer' }}
                            >
                                {groupByDecor ? (
                                    <td>{item.Decor}</td>
                                ) : (
                                    <>
                                        <td>{item['Material']}</td>
                                        <td>{item['Material Description']}</td>
                                    </>
                                )}
                                <td>{item['Total Quantity']}</td>
                                <td>{formatCurrency(item['Total Amount'], 'en-GB', localCurrency)}</td>
                                <td>{percentage}%</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={groupByDecor ? "5" : "6"} className="text-center">
                            No Data Available
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default VBC_TopMaterialsTable;
