import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer, Legend } from 'recharts';

const monthNames = {
    "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun",
    "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec"
};

const CustomTooltip = ({ active, payload, currency, compareYear }) => {
    if (active && payload && payload.length) {
        if (compareYear) {
            const getTooltipLabel = (name) => {
                if (name === "CY") return "Current Year";
                if (name === "LY") return "Last Year";
                return name;
            };

            // Extract month from payload and convert to name
            const monthKey = payload[0].payload.month;
            const monthName = monthNames[monthKey] || monthKey;

            return (
                <div className="custom-tooltip" style={{ zIndex: 0 }}>
                    <p className="label">{`Month: ${monthName}`}</p>
                    {payload.map((p, index) => (
                        <div key={index}>
                            <p className="amount">
                                {`${getTooltipLabel(p.name)}: ${new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: currency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(p.value)}`}
                            </p>
                        </div>
                    ))}
                </div>
            );
        } else {
            const { date, amount } = payload[0].payload;
            return (
                <div className="custom-tooltip" style={{ zIndex: 0 }}>
                    <p className="label">{`Date: ${date}`}</p>
                    <p className="amount">
                        {`Amount: ${new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(amount)}`}
                    </p>
                </div>
            );
        }
    }
    return null;
};

const VentasTurnoverDashChart = ({ salesData, localCurrency, dataType, tooltipToggle, compareYear }) => {
    if (!salesData || (Array.isArray(salesData) && salesData.length === 0)) {
        return <p>No data available to display.</p>;
    }

    let strokeColor;
    switch (dataType) {
        case 'Gross': strokeColor = '#00FFFF'; break;
        case 'Invoiced': strokeColor = '#98FB98'; break;
        case 'Credited': strokeColor = '#FF9999'; break;
        default: strokeColor = '#8884d8';
    }
    const lyStrokeColor = '#ADD8E6';

    const formatCurrency = (amount, currencyCode) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    let maxAmount = 0;
    if (compareYear) {
        maxAmount = Math.max(...salesData.map(d => Math.max(d.cy, d.ly)));
    } else {
        maxAmount = Math.max(...salesData.map(d => d.amount));
    }

    return (
        <ResponsiveContainer width="95%" height={400} style={{ margin: "auto" }}>
            <LineChart
                data={compareYear ? salesData : salesData}
                margin={{ top: 30, right: 40, bottom: 20, left: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                {compareYear ? (
                    <>
                        <XAxis
                            dataKey="month"
                            tickFormatter={(tick) => monthNames[tick] || tick}
                        />
                        <YAxis padding={{ top: 50 }} />
                        {tooltipToggle && (
                            <Tooltip content={<CustomTooltip currency={localCurrency} compareYear={compareYear} />} />
                        )}
                        <Legend 
                            verticalAlign="top" 
                            align="center" 
                            iconType="circle"
                            wrapperStyle={{ fontSize: '12px' }}
                        />
                        <Line 
                            type="monotone" 
                            dataKey="cy" 
                            name="CY"
                            stroke={strokeColor}
                        >
                            {!tooltipToggle && (
                                <LabelList
                                    dataKey="cy"
                                    position={({ value }) => (value >= maxAmount * 0.9 ? 'bottom' : 'top')}
                                    formatter={(value) => formatCurrency(value, localCurrency)}
                                    fill="#ffbb00"
                                    fontWeight="bold"
                                />
                            )}
                        </Line>
                        <Line 
                            type="monotone" 
                            dataKey="ly" 
                            name="LY"
                            stroke={lyStrokeColor}
                        >
                            {!tooltipToggle && (
                                <LabelList
                                    dataKey="ly"
                                    position={({ value }) => (value >= maxAmount * 0.9 ? 'bottom' : 'top')}
                                    formatter={(value) => formatCurrency(value, localCurrency)}
                                    fill="#ffbb00"
                                    fontWeight="bold"
                                />
                            )}
                        </Line>
                    </>
                ) : (
                    <>
                        <XAxis
                            dataKey="date"
                            tickFormatter={(tick) => {
                                const [year, month] = tick.split('-');
                                return `${monthNames[month] || month}/${year}`;
                            }}
                        />
                        <YAxis padding={{ top: 50 }} />
                        {tooltipToggle && (
                            <Tooltip content={<CustomTooltip currency={localCurrency} />} />
                        )}
                        <Line type="monotone" dataKey="amount" stroke={strokeColor}>
                            {!tooltipToggle && (
                                <LabelList
                                    dataKey="amount"
                                    position={({ value }) => (value >= maxAmount * 0.9 ? 'bottom' : 'top')}
                                    formatter={(value) => formatCurrency(value, localCurrency)}
                                    fill="#ffbb00"
                                    fontWeight="bold"
                                />
                            )}
                        </Line>
                    </>
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default VentasTurnoverDashChart;
