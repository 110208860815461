import React from 'react';
import '../../Styles/SideMenu.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarksLines, faPeopleGroup, faMoneyBills, faChartSimple, faVial, faBoxesPacking, faMoneyBill1Wave, faEarth } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';

function SideMenu({pageHeight, sideMenuRef, setSideMenuVisible, AppProps}) {
  const {token, isAuthed } = AppProps;
  const navigate = useNavigate();
  
  const SideMenuButtonWithAuth = ({ text, icon, route, requiresAuth }) => {
    const handleClick = () => {
      console.log("Navigating to " + route);
      navigate(route);
      setSideMenuVisible(s => !s);
    };

    const handleContextMenu = (e) => {
      e.preventDefault();
      window.open(route, '_blank');
    };

    if (!requiresAuth) {
      return (
        <button className="sidemenu-button" onClick={handleClick} onContextMenu={handleContextMenu}>
          <div className='button-spacer'>
            <div className='icon-holder'>
              <FontAwesomeIcon icon={icon} size="lg" className='sidemenu-buttonicon' />
            </div>
            {text}
          </div>
        </button>
      )
    } else {
      if (!isAuthed) {
        console.log("User is not authenticated, not rendering button");
      } else {
        try {
          const decoded = jwtDecode(token);
          const hasRole = decoded.permissions.includes("read:staff") || decoded.permissions.includes("write:staff");
  
          if (hasRole) {
            return (
              <button className="sidemenu-button" onClick={handleClick} onContextMenu={handleContextMenu}>
                <div className='button-spacer'>
                  <div className='icon-holder'>
                    <FontAwesomeIcon icon={icon} size="lg" className='sidemenu-buttonicon' />
                  </div>
                  {text}
                </div>
              </button>
            )
          }
        } catch (error) {
          // Handle the error gracefully, for example, by not rendering the button or displaying an error message.
        }
      }
    }
  };

  const menuButtons = [
    { text: "ABCXYZ", icon: faEarth, route: "/ABCXYZ", requiresAuth: false },
    { text: 'Center DRP', icon: faBoxesPacking, route: "/Excel", requiresAuth: false},
    { text: "Customs Summariser", icon: faEarth, route: "/CustomsSummariser", requiresAuth: false },
    { text: 'Debt Portal', icon: faMoneyBill1Wave, route: "/DebtPortal", requiresAuth: false},
    { text: "Global DRP", icon: faEarth, route: "/JJDRP", requiresAuth: false },
    { text: 'Plan It', icon: faVial, route: '/PlanIt', requiresAuth: false },
    { text: "Ventas Analyser", icon: faChartSimple, route: "/VentasAnalyser", requiresAuth: false },

  ];

  return (
    <div className="sidemenu-container">
      <div className="sidemenu" ref={sideMenuRef}>
        <h2 className="sidemenu-title">Function Menu</h2>
        <hr className="hr-sidemenu" />
        {menuButtons.map((button, index) => (
          <SideMenuButtonWithAuth key={index} text={button.text} icon={button.icon} route={button.route} requiresAuth={button.requiresAuth} />
        ))}
      </div>
    </div>
  );
}

export default SideMenu;
