import XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero based
    const year = d.getFullYear();
  
    return `${day}-${month}-${year}`;
}
export const exportCUBODRPToExcel = (data) => {

    const filename = `CUBO_DRP_Data ${formatDate(Date.now())}.xlsx`;

      // Convert numeric values to numbers
    const formattedData = data.map(item => {
        const formattedItem = { ...item };
        // Convert specific numeric fields to numbers
        formattedItem.StockHQ = Number(item.StockHQ);
        formattedItem.PurchaseOrdered = Number(item.PurchaseOrdered);
        formattedItem.SalesOrdered = Number(item.SalesOrdered);
        formattedItem.Invoiced = Number(item.Invoiced);
        formattedItem.DailyRate = Number(item.DailyRate);
        formattedItem.MonthlyRate = Number(item.MonthlyRate);
        formattedItem.SafetyStock = Number(item.SafetyStock);
        formattedItem.NeedToProduce = Number(item.NeedToProduce);
        //remove the % sign from the POToSaleAccuracy field:
        let formattedAccuracy = item.POToSaleAccuracy.replace('%', '');
        formattedItem.POToSaleAccuracy = Number(formattedAccuracy);
        // Add conversions for other numeric fields as needed
        return formattedItem;
    });
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(excelData, filename);
  };
export const exportSubsidiaryToExcel = (sortedFilteredData, selectedCenter) => {

    const DRPSubsidTableMap = [
      { drpPropertyName: 'Material', displayName: 'Material' },
      { drpPropertyName: 'Decor', displayName: 'Decor' },
      { drpPropertyName: 'DecorName', displayName: 'DecorName' },
      { drpPropertyName: 'MaterialDescription', displayName: 'MaterialDescription' },
      { drpPropertyName: 'Thickness', displayName: 'Thickness' },
      { drpPropertyName: 'Format', displayName: 'Format' },
      { drpPropertyName: 'Finish', displayName: 'Finish' },
      { drpPropertyName: 'Mesh', displayName: 'Mesh' },
      { drpPropertyName: 'StockHQ', displayName: 'Stock HQ', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.StockSubsid', displayName: 'Ctr Stock', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.SalesOrderedSubsid', displayName: 'S Orders', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.PurchaseOrderedSubsid', displayName: 'P Orders', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.InvoicedSubsid', displayName: 'Invoiced', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.SafetyStockSubsid', displayName: 'Sf Stock', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.NeedToOrderSubsid', displayName: 'Need To PO', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.MonthlyRateSubsid', displayName: 'Monthly Rate', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.DailyRateSubsid', displayName: 'Daily Rate', numericSort: true },
      { drpPropertyName: 'SubsidiaryDRP.ATPSubsid', displayName: 'ATP', numericSort: true }
    ];

    const renderSubsidiaryValue = (subsidiaryData, column) => {
      if (!subsidiaryData) return 0;
      const fieldMapping = {
        'SubsidiaryDRP.StockSubsid': 'StockSubsid',
        'SubsidiaryDRP.SalesOrderedSubsid': 'SalesOrderedSubsid',
        'SubsidiaryDRP.PurchaseOrderedSubsid': 'PurchaseOrderedSubsid',
        'SubsidiaryDRP.InvoicedSubsid': 'InvoicedSubsid',
        'SubsidiaryDRP.SafetyStockSubsid': 'SafetyStockSubsid',
        'SubsidiaryDRP.NeedToOrderSubsid': 'NeedToOrderSubsid',
        'SubsidiaryDRP.MonthlyRateSubsid': 'MonthlyRateSubsid',
        'SubsidiaryDRP.DailyRateSubsid': 'DailyRateSubsid',
        'SubsidiaryDRP.ATPSubsid': 'ATPSubsid'
      };

      return subsidiaryData[fieldMapping[column]] || 0;
    };

    const wb = XLSX.utils.book_new();
    const wsData = [
      DRPSubsidTableMap.map(col => col.displayName)
    ];

    sortedFilteredData.forEach(item => {
      const row = DRPSubsidTableMap.map(({ drpPropertyName }) => {
        const subsidiaryData = selectedCenter && item.SubsidiaryDRP.find(sub => sub.Center === selectedCenter);
        return ['SubsidiaryDRP.StockSubsid', 'SubsidiaryDRP.SalesOrderedSubsid', 'SubsidiaryDRP.PurchaseOrderedSubsid', 'SubsidiaryDRP.InvoicedSubsid', 'SubsidiaryDRP.SafetyStockSubsid', 'SubsidiaryDRP.NeedToOrderSubsid', 'SubsidiaryDRP.MonthlyRateSubsid', 'SubsidiaryDRP.DailyRateSubsid', 'SubsidiaryDRP.ATPSubsid'].includes(drpPropertyName)
          ? renderSubsidiaryValue(subsidiaryData, drpPropertyName)
          : item[drpPropertyName];
      });
      wsData.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const sheetName = selectedCenter ? `Subsidiary ${selectedCenter}` : 'Demand Data'; 
    const wbNameWithSafeDate = `${selectedCenter} - demand_data_${formatDate(Date.now())}.xlsx`;
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, wbNameWithSafeDate);
  };


export function exportToExcel(data, fileTitle) {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Create a blob from the workbook
  const workbookSaved = XLSX.writeFile(workbook, fileTitle+'.xlsx', { compression: true });
  console.log(workbookSaved);
}


export function exportToExcelWithOmission(data, fileTitle, propertiesToOmit = []) {
  // Filter out properties to omit from each item in data
  console.log(data[0]);
  const filteredData = data.map(item => {
    const filteredItem = {};
    for (const key in item) {
      if (!propertiesToOmit.includes(key)) {
        filteredItem[key] = item[key];
      }
    }
    return filteredItem;
  });

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the filtered data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(filteredData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Create a blob from the workbook
  const workbookSaved = XLSX.writeFile(workbook, fileTitle+'.xlsx', { compression: true });
  console.log(workbookSaved);
}

/**
 * Exports data to an Excel spreadsheet with improved formatting.
 *
 * @param {Array<Object>} dataArray - The data to export.
 * @param {Array<string>} omissions - Columns to omit from export.
 * @param {string} fileName - Name of the exported file.
 */
export function ExportToSpreadsheetWithOmissions(dataArray, omissions = [], fileName = "export.xlsx") {
  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    console.warn("No data available to export.");
    return;
  }

  // Determine the columns (keys) from the first row, excluding omissions.
  const allColumns = Object.keys(dataArray[0]);

  // Remove only explicitly omitted columns
  const columns = allColumns.filter((key) => !omissions.includes(key));

  // Define which columns should be treated as dates
  const dateColumns = ["EDD", "FeHORNOS", "FeMALLADO", "FePULIDO", "FeCORTE"];

  // Columns that should have **double width**
  const wideColumns = ["Client", "Descripción material"];

  // Create a new array of objects containing only the allowed columns.
  const filteredData = dataArray.map((row) => {
    const filteredRow = {};
    columns.forEach((key) => {
      if (dateColumns.includes(key)) {
        const excelDateValue = Number(row[key]);

        // Handle empty or invalid date values
        if (!excelDateValue || excelDateValue <= 0) {
          filteredRow[key] = ""; // Empty string instead of invalid date
        } else {
          filteredRow[key] = new Date((excelDateValue - 25569) * 86400000); // Convert to Date
        }
      } else if (key === "Total Rack Space Selected") {
        // Ensure Rack Space is formatted with two decimal places
        filteredRow[key] = Number(row[key]).toFixed(2);
      } else {
        filteredRow[key] = row[key];
      }
    });
    return filteredRow;
  });

  // Create a worksheet from the filtered data.
  const worksheet = XLSX.utils.json_to_sheet(filteredData);

  // Auto-fit columns & set special widths
  const columnWidths = columns.map((col) => ({
    wch: wideColumns.includes(col) ? 20 : Math.max(10, col.length + 5),
  }));
  worksheet["!cols"] = columnWidths;

  // Define formatting for date columns
  const dateFormat = "dd/mm/yyyy"; // Set desired Excel date format
  dateColumns.forEach((col) => {
    if (columns.includes(col)) {
      const colIndex = columns.indexOf(col);
      const cellRef = XLSX.utils.encode_col(colIndex);
      if (!worksheet[cellRef]) worksheet[cellRef] = {};
      worksheet[cellRef].z = dateFormat;
    }
  });

  // Create a new workbook and append the worksheet.
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate a binary array representation of the workbook.
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a Blob from the binary array.
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  // Use FileSaver to trigger a download.
  FileSaver.saveAs(blob, fileName);
}



