import { connectToDB, StoreData, GetData } from './DBOps';
import {excelDateToJSDate} from './DateOps';
import * as XLSX from "xlsx";

const headerTranslationMap = {
    "Cantidad Facturada": "Billed Quantity",
    "Centro": "Center",
    "Descripción Material": "Material Description",
    "Documento Contable": "Accounting Document",
    "Documento Entrega": "Delivery Document",
    "Documento Factura": "Invoice Document",
    "Documento Oferta": "Quotation Document",
    "Documento Ventas": "Sales Document",
    "Ejercicio/Periodo Factura": "Invoice Fiscal Year/Period",
    "Fecha Doc. Entrega": "Delivery Document Date",
    "Fecha Doc. Factura": "Invoice Document Date",
    "Fecha Doc. Oferta": "Quotation Document Date",
    "Fecha Doc. Ventas": "Sales Document Date",
    "Imp. Neto Facturado": "Net Billed Amount",
    "Imp. Neto Facturado\n(Mon. Sociedad)": "Net Billed Amount (Society Currency)",
    "Imp. Neto Facturado (Mon. Grupo)": "Net Billed Amount (Group Currency)",
    "Material": "Material",
    "Motivo Pedido": "Order Reason",
    "Posición Doc. Entrega": "Delivery Document Position",
    "Posición Doc. Factura": "Invoice Document Position",
    "Posición Doc. Ventas": "Sales Document Position",
    "Responsable de Pago": "Payment Responsible",
    "Área Total Real (M2)": "Total Actual Area (m²)",
    "Lote": "Batch",
    "Numero de Serie": "Serial Number",
    "Referencia Cliente" : "Client Reference",
    "Sociedad Facturadora": "Society",
    // Add any additional header translations if needed
};


const filterDataByCenter = (data, centerNumber) => {
    if (!centerNumber) return data; // If no centerNumber is provided, return data as is
    return data.filter(row => row["Center"] === centerNumber);
};


//MAIN FUNCTIONS
export const VentasAnalyserDataHandler = async (ventasData, setValidSetter, setVentasData, saveToLocal) => {
    try {
        let db;
        if (saveToLocal) {
            const _db = await connectToDB();
            db = _db;
            console.log(db);
        }

        // Get references to the files and read data
        let arrayBuffedVentas = await ventasData.arrayBuffer();
        let VentasBook = XLSX.read(arrayBuffedVentas);
        let VentasSheet = VentasBook.Sheets["Sheet1"];
        let rawData = XLSX.utils.sheet_to_json(VentasSheet, { header: 1, defval: "" }); // Read raw data with headers as rows

        if (rawData.length === 0) {
            throw new Error("No data found in the spreadsheet.");
        }

        // Extract and fix headers
        const rawHeaders = rawData[0];
        const processedHeaders = rawHeaders.map((header, index) => {
            // Handle the special case for empty headers following "Responsable de Pago"
            if (index > 0 && rawHeaders[index - 1] === "Responsable de Pago" && !header) {
                return "Client Description";
            }
            return headerTranslationMap[header] || header;
        });

        // Replace the first row with translated and fixed headers
        rawData[0] = processedHeaders;

        // Convert rows to objects using the updated headers
        const dataObjects = rawData.slice(1).map(row => {
            return processedHeaders.reduce((acc, header, index) => {
                acc[header] = row[index];
                return acc;
            }, {});
        });

        // If there's a need to save to local
        if (saveToLocal && db) {
            await SaveVentasLocalData(dataObjects);
        } else {
            console.log("No database found, cannot save");
            alert("No database found, cannot save");
        }

        console.log('This is the data being saved to Dexie:');
        console.log(dataObjects);

        if (setValidSetter) setValidSetter(true);
        setVentasData(dataObjects);

    } catch (error) {
        console.log(error);
        setValidSetter(false);
        alert(error.message);
    }
};

export const GetVentasLocalData = async () => {
    try {
        const db = await connectToDB();
        const data = await GetData(db, 'VentasAnalyserData');
        return data[0];
    } catch (error) {
        console.error("Error getting Ventas Data from local storage:", error);
        return null;
    }
};

export const SaveVentasLocalData = async (data) => {
    try {
        const db = await connectToDB();
        await StoreData(db, 'VentasAnalyserData', data);
        console.log("Data saved to local storage: VentasAnalyserData");
        return true;
    } catch (error) {
        console.error("Error saving Ventas Data to local storage:", error);
        return false;
    }
};

export const SingleOrMultiSociety = async (data) => {
    let centerSet = new Set();
    data.forEach(row => {
        centerSet.add(row["Center"]);
    });
    // Check if the number of unique values is greater than 1
if (centerSet.size > 1) {
    console.log("There are more than one unique 'Center' values.");
    return true;
} else {
    console.log("There is 1 or fewer unique 'Center' values.");
    // Perform your action here
    return false;
}
};

// Function to calculate the total net billed amount in GBP
export const SumNetBilledAmountGBP = async (data, startDate = null, endDate = null, centerNumber = null) => {
    let total = 0;

    // Convert startDate and endDate to JavaScript Date objects if provided
    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    const end = endDate ? new Date(endDate) : new Date();

    // Filter data by centerNumber if provided
    const filteredData = filterDataByCenter(data, centerNumber);

    // Convert Excel dates and filter data
    filteredData.forEach(row => {
        const excelDate = row["Invoice Document Date"];
        const invoiceDate = excelDateToJSDate(excelDate);

        if (invoiceDate >= start && invoiceDate <= end) {
            const amount = parseFloat(row["Net Billed Amount (Society Currency)"]) || 0;
            total += amount;
        }
    });

    return total;
};

// Function to calculate the total net amount of only positive amounts in GBP
export const SumPositiveNetAmountGBP = async (data, startDate = null, endDate = null, centerNumber = null) => {
    let total = 0;

    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    const end = endDate ? new Date(endDate) : new Date();

    const filteredData = filterDataByCenter(data, centerNumber);

    filteredData.forEach(row => {
        const excelDate = row["Invoice Document Date"];
        const invoiceDate = excelDateToJSDate(excelDate);

        if (invoiceDate >= start && invoiceDate <= end) {
            const amount = parseFloat(row["Net Billed Amount (Society Currency)"]) || 0;
            if (amount > 0) {
                total += amount;
            }
        }
    });

    return total;
};

// Function to calculate the total net amount of only negative amounts in GBP
export const SumNegativeNetAmountGBP = async (data, startDate = null, endDate = null, centerNumber = null) => {
    let total = 0;

    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    const end = endDate ? new Date(endDate) : new Date();

    const filteredData = filterDataByCenter(data, centerNumber);

    filteredData.forEach(row => {
        const excelDate = row["Invoice Document Date"];
        const invoiceDate = excelDateToJSDate(excelDate);

        if (invoiceDate >= start && invoiceDate <= end) {
            const amount = parseFloat(row["Net Billed Amount (Society Currency)"]) || 0;
            if (amount < 0) {
                total += amount;
            }
        }
    });

    return total;
};

// Function to calculate the total net billed amount in EUR
export const SumNetBilledAmountEUR = async (data, startDate = null, endDate = null, centerNumber = null) => {
    let total = 0;

    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    const end = endDate ? new Date(endDate) : new Date();

    const filteredData = filterDataByCenter(data, centerNumber);

    filteredData.forEach(row => {
        const excelDate = row["Invoice Document Date"];
        const invoiceDate = excelDateToJSDate(excelDate);

        if (invoiceDate >= start && invoiceDate <= end) {
            const amount = parseFloat(row["Net Billed Amount (Group Currency)"]) || 0;
            total += amount;
        }
    });

    return total;
};

// Function to calculate the total net amount of only positive amounts in EUR
export const SumPositiveNetAmountEUR = async (data, startDate = null, endDate = null, centerNumber = null) => {
    let total = 0;

    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    const end = endDate ? new Date(endDate) : new Date();

    const filteredData = filterDataByCenter(data, centerNumber);

    filteredData.forEach(row => {
        const excelDate = row["Invoice Document Date"];
        const invoiceDate = excelDateToJSDate(excelDate);

        if (invoiceDate >= start && invoiceDate <= end) {
            const amount = parseFloat(row["Net Billed Amount (Group Currency)"]) || 0;
            if (amount > 0) {
                total += amount;
            }
        }
    });

    return total;
};

// Function to calculate the total net amount of only negative amounts in EUR
export const SumNegativeNetAmountEUR = async (data, startDate = null, endDate = null, centerNumber = null) => {
    let total = 0;

    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    const end = endDate ? new Date(endDate) : new Date();

    const filteredData = filterDataByCenter(data, centerNumber);

    filteredData.forEach(row => {
        const excelDate = row["Invoice Document Date"];
        const invoiceDate = excelDateToJSDate(excelDate);

        if (invoiceDate >= start && invoiceDate <= end) {
            const amount = parseFloat(row["Net Billed Amount (Group Currency)"]) || 0;
            if (amount < 0) {
                total += amount;
            }
        }
    });

    return total;
};


//A function that sums up qty of billed slabs only considering positive values
export const SumBilledSlabsPositiveValuesOnly = async (data) => {
    let total = 0;
    data.forEach(row => {
        const qty = parseFloat(row["Billed Quantity"]);
        if (qty > 0) {
            total += qty;
        }
    });
    return total;
};

//A function that sums up qty of billed slabs only considering negative values
export const SumBilledSlabsNegativeValuesOnly = async (data) => {
    let total = 0;
    data.forEach(row => {
        const qty = parseFloat(row["Billed Quantity"]);
        if (qty < 0) {
            total += qty;
        }
    });
    return total;
};

export const VentasSumAmountFunctions = {
    SumNetBilledAmountGBP,
    SumPositiveNetAmountGBP,
    SumNegativeNetAmountGBP,
    SumNetBilledAmountEUR,
    SumPositiveNetAmountEUR,
    SumNegativeNetAmountEUR,
};

export const VentasSumSlabsFunctions = {
    SumBilledSlabsPositiveValuesOnly,
    SumBilledSlabsNegativeValuesOnly,
};


export const GetTopMaterials = async (data, startDate, endDate, currency) => {
    const materialMap = {};

    // Filter data by date range if provided
    const filteredData = data.filter(row => {
        const invoiceDate = excelDateToJSDate(row["Invoice Document Date"]);
        return (!startDate || invoiceDate >= new Date(startDate)) &&
               (!endDate || invoiceDate <= new Date(endDate));
    });

    // Create a unique set of materials
    filteredData.forEach(row => {
        const materialKey = row.Material;

        if (!materialMap[materialKey]) {
            materialMap[materialKey] = {
                Material: row.Material,
                "Material Description": row["Material Description"],
                "Total Quantity": 0,
                "Total Quantity Invoiced": 0,
                "Total Quantity Credited": 0,
                "Net Billed Amount Local": 0,
                "Invoiced Amount Local": 0,
                "Credited Amount Local": 0,
                "Net Billed Amount EUR": 0,
                "Invoiced Amount EUR": 0,
                "Credited Amount EUR": 0,
            };
        }

        // Accumulate quantities and amounts
        materialMap[materialKey]["Total Quantity"] += row["Billed Quantity"];
        materialMap[materialKey]["Net Billed Amount Local"] += row["Net Billed Amount (Society Currency)"];
        materialMap[materialKey]["Net Billed Amount EUR"] += row["Net Billed Amount (Group Currency)"];

        // Accumulate invoiced and credited amounts
        if (row["Billed Quantity"] > 0) {
            materialMap[materialKey]["Total Quantity Invoiced"] += row["Billed Quantity"];
            materialMap[materialKey]["Invoiced Amount Local"] += row["Net Billed Amount (Society Currency)"];
            materialMap[materialKey]["Invoiced Amount EUR"] += row["Net Billed Amount (Group Currency)"];
        } else {
            materialMap[materialKey]["Total Quantity Credited"] += row["Billed Quantity"];
            materialMap[materialKey]["Credited Amount Local"] += row["Net Billed Amount (Society Currency)"];
            materialMap[materialKey]["Credited Amount EUR"] += row["Net Billed Amount (Group Currency)"];
        }
    });

    // Convert the materialMap to an array
    const materialsArray = Object.values(materialMap);

    // Sort by Total Quantity and get the top 100 materials
    const sortedMaterials = materialsArray
        .sort((a, b) => b["Total Quantity"] - a["Total Quantity"])
        .slice(0, 50);

    return sortedMaterials;
};