import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { columnGroups } from '../../../../Utils/ColumnsConfigJJDRP';

function RowDetailsModal({ 
  show, 
  onHide, 
  selectedRowData 
}) {
  // Group the row data
  const groupedData = selectedRowData ? groupRowData(selectedRowData, columnGroups) : {};

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size="xl" 
      centered 
      dialogClassName="wide-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Row Details</Modal.Title>
      </Modal.Header>
      <Modal.Body 
        style={{ 
          background: '#2c2c2c', 
          color: 'white', 
          padding: '20px',
          maxHeight: '70vh',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {Object.entries(groupedData).map(([groupName, fields], idx) => (
          <div key={idx} style={{ marginBottom: '15px' }}>
            <h5 
              style={{ 
                marginBottom: '10px', 
                color: '#f0ad4e', 
                borderBottom: '2px solid #f0ad4e', 
                paddingBottom: '4px',
                fontSize: '1rem',
              }}
            >
              {groupName}
            </h5>
            <div 
              style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', 
                gap: '15px',
              }}
            >
              {fields.map(({ field, value }, fIdx) => (
                <div 
                  key={fIdx} 
                  style={{ 
                    background: '#333', 
                    padding: '10px', 
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span 
                    style={{ 
                      fontSize: '0.85rem', 
                      fontWeight: 'bold', 
                      color: '#f8f9fa',
                      marginBottom: '5px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {field}
                  </span>
                  <span 
                    style={{ 
                      fontSize: '0.8rem', 
                      color: '#dcdcdc',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {value || 'N/A'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer 
        style={{ 
          background: '#2c2c2c', 
          borderTop: '1px solid #444', 
        }}
      >
        <Button 
          variant="secondary" 
          onClick={onHide}
          style={{ 
            background: '#444', 
            borderColor: '#444', 
            color: '#fff',
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// Helper for grouping row data
function groupRowData(rowData, columnGroups) {
  const grouped = {};

  Object.entries(rowData).forEach(([field, value]) => {
    const group = columnGroups[field] || 'Misc.';
    if (!grouped[group]) {
      grouped[group] = [];
    }
    grouped[group].push({ field, value });
  });

  return grouped;
}

export default RowDetailsModal;
