import React, { useEffect, useState } from 'react';
import { Card, ToggleButtonGroup, ToggleButton, Row, Col, Container, Navbar, Form } from 'react-bootstrap';
import VentasTurnoverDashChart from './VentasTurnoverDashChart';
import centersAndCurrencies from '../../../../Utils/CentersAndCurrencies.json';
import { excelDateToJSDate } from '../../../../Utils/DateOps';
import { 
    SumNetBilledAmountGBP, 
    SumPositiveNetAmountGBP, 
    SumNegativeNetAmountGBP, 
    SumNetBilledAmountEUR, 
    SumPositiveNetAmountEUR, 
    SumNegativeNetAmountEUR 
} from '../../../../Utils/VentasAnalyserOps';

const VentasTurnoverDashMechanics = ({ ventasData }) => {
    const [currency, setCurrency] = useState('Local'); // Default to Local
    const [dataType, setDataType] = useState('Net'); // Default data type is Net
    const [centers, setCenters] = useState([]);
    const [turnovers, setTurnovers] = useState({});
    const [totals, setTotals] = useState({
        billedAmount: 0,
        billedPositive: 0,
        billedNegative: 0
    });
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [NetData, setNetData] = useState([]);
    const [invoicedData, setInvoicedData] = useState([]);
    const [creditedData, setCreditedData] = useState([]);
    // When in compare mode, we merge the data into one array of 12 objects
    const [cyData, setCyData] = useState([]);
    const [localCurrency, setLocalCurrency] = useState('EUR'); // Default to EUR
    const [defaultLocalCurrency, setDefaultLocalCurrency] = useState('EUR'); // Store default local currency
    const [tooltipToggle, setTooltipToggle] = useState(false);
    const [compareYear, setCompareYear] = useState(false);

    useEffect(() => {
        if (ventasData.length > 0) {
            const firstRecord = ventasData[0];
            const society = firstRecord["Society"];
            const centerInfo = centersAndCurrencies.find(
                (center) => center.Society === society
            );
            if (centerInfo) {
                const detectedCurrency = centerInfo["Display Currency"];
                setLocalCurrency(detectedCurrency);
                setDefaultLocalCurrency(detectedCurrency);
                console.log(`Local currency set to ${detectedCurrency}`);
            }
            const currentYear = new Date().getFullYear();
            const defaultStartDate = `${currentYear}-01-01`;
            const defaultEndDate = new Date().toISOString().split('T')[0];
            setStartDate(defaultStartDate);
            setEndDate(defaultEndDate);
        }
    }, [ventasData]);

    const handleCurrencyChange = (val) => {
        setCurrency(val);
        if (val === 'Group') {
            setLocalCurrency('EUR');
        } else {
            setLocalCurrency(defaultLocalCurrency);
        }
    };

    const handleDataTypeChange = (val) => {
        setDataType(val);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    // Extract unique centers (for the summary table)
    useEffect(() => {
        const centersSet = new Set(ventasData.map(row => row["Center"]));
        setCenters(Array.from(centersSet));
    }, [ventasData]);

    useEffect(() => {
        const calculateTurnovers = async () => {
            const turnoverResults = {};
            let totalBilledAmount = 0;
            let totalBilledPositive = 0;
            let totalBilledNegative = 0;

            // Filter out records where Payment Responsible equals "ES02"
            const filteredVentasData = ventasData.filter(item =>
                !(item["Responsable de Pago"] === "ES02" || item["Payment Responsible"] === "ES02")
            );

            const activeCurrency = currency === 'Local' ? localCurrency : 'EUR';

            for (const center of centers) {
                let billedAmount = 0;
                let billedPositive = 0;
                let billedNegative = 0;

                if (currency === 'Local') {
                    billedAmount = await SumNetBilledAmountGBP(filteredVentasData, startDate, endDate, center);
                    billedPositive = await SumPositiveNetAmountGBP(filteredVentasData, startDate, endDate, center);
                    billedNegative = await SumNegativeNetAmountGBP(filteredVentasData, startDate, endDate, center);
                } else if (currency === 'Group') {
                    billedAmount = await SumNetBilledAmountEUR(filteredVentasData, startDate, endDate, center);
                    billedPositive = await SumPositiveNetAmountEUR(filteredVentasData, startDate, endDate, center);
                    billedNegative = await SumNegativeNetAmountEUR(filteredVentasData, startDate, endDate, center);
                }

                turnoverResults[center] = {
                    billedAmount: formatCurrency(billedAmount, activeCurrency),
                    billedPositive: formatCurrency(billedPositive, activeCurrency),
                    billedNegative: formatCurrency(billedNegative, activeCurrency),
                    creditPercentage: billedPositive > 0 
                        ? ((billedNegative / billedPositive) * 100).toFixed(2) + '%' 
                        : 'N/A',
                };

                totalBilledAmount += billedAmount;
                totalBilledPositive += billedPositive;
                totalBilledNegative += billedNegative;
            }

            const totalCreditPercentage = totalBilledPositive > 0
                ? ((totalBilledNegative / totalBilledPositive) * 100).toFixed(2) + '%'
                : 'N/A';

            setTurnovers(turnoverResults);
            setTotals({
                billedAmount: formatCurrency(totalBilledAmount, activeCurrency),
                billedPositive: formatCurrency(totalBilledPositive, activeCurrency),
                billedNegative: formatCurrency(totalBilledNegative, activeCurrency),
                creditPercentage: totalCreditPercentage,
            });
        };

        calculateTurnovers();
    }, [currency, centers, ventasData, startDate, endDate, localCurrency]);

    // Aggregation for chart data (either single series or CY vs LY)
    useEffect(() => {
        const start = startDate ? new Date(startDate) : new Date('1900-01-01');
        const end = endDate ? new Date(endDate) : new Date();
        const filteredSalesData = ventasData.filter(item => {
            const date = item["Invoice Document Date"] ? excelDateToJSDate(item["Invoice Document Date"]) : null;
            // Exclude records where Payment Responsible equals "ES02"
            if (item["Responsable de Pago"] === "ES02" || item["Payment Responsible"] === "ES02") return false;
            return date && date >= start && date <= end;
        });

        if (compareYear) {
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;
            const cyMonthlyData = {};
            const lyMonthlyData = {};

            filteredSalesData.forEach(item => {
                const date = excelDateToJSDate(item["Invoice Document Date"]);
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const rawAmount = currency === 'Local'
                    ? parseFloat(item["Net Billed Amount (Society Currency)"]) || 0
                    : parseFloat(item["Net Billed Amount (Group Currency)"]) || 0;
                // Use the selected dataType to decide what amount to add
                let amount = 0;
                if (dataType === 'Net') {
                    amount = rawAmount;
                } else if (dataType === 'Invoiced') {
                    amount = rawAmount > 0 ? rawAmount : 0;
                } else if (dataType === 'Credited') {
                    amount = rawAmount < 0 ? rawAmount : 0;
                }

                if (year === currentYear) {
                    cyMonthlyData[month] = (cyMonthlyData[month] || 0) + amount;
                } else if (year === previousYear) {
                    lyMonthlyData[month] = (lyMonthlyData[month] || 0) + amount;
                }
            });
            // Merge into a single array with 12 entries (one per month)
            const mergedData = [];
            for (let m = 1; m <= 12; m++) {
                const monthKey = String(m).padStart(2, '0');
                mergedData.push({
                    month: monthKey,
                    cy: cyMonthlyData[monthKey] || 0,
                    ly: lyMonthlyData[monthKey] || 0
                });
            }
            setCyData(mergedData); // Use cyData as the merged compare data
        } else {
            // Existing aggregation (by year-month) for single-series view
            const NetMonthlyData = {};
            const invoicedMonthlyData = {};
            const creditedMonthlyData = {};

            filteredSalesData.forEach(item => {
                const date = excelDateToJSDate(item["Invoice Document Date"]);
                const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

                if (!NetMonthlyData[monthYear]) NetMonthlyData[monthYear] = 0;
                if (!invoicedMonthlyData[monthYear]) invoicedMonthlyData[monthYear] = 0;
                if (!creditedMonthlyData[monthYear]) creditedMonthlyData[monthYear] = 0;

                const rawAmount = currency === 'Local'
                    ? parseFloat(item["Net Billed Amount (Society Currency)"]) || 0
                    : parseFloat(item["Net Billed Amount (Group Currency)"]) || 0;
                const invoicedAmount = rawAmount > 0 ? rawAmount : 0;
                const creditedAmount = rawAmount < 0 ? rawAmount : 0;

                NetMonthlyData[monthYear] += rawAmount;
                invoicedMonthlyData[monthYear] += invoicedAmount;
                creditedMonthlyData[monthYear] += creditedAmount;
            });

            const formatData = (monthlyData) => Object.keys(monthlyData).map(monthYear => ({
                date: monthYear,
                amount: monthlyData[monthYear]
            }));

            if (dataType === 'Net') setNetData(formatData(NetMonthlyData));
            else if (dataType === 'Invoiced') setInvoicedData(formatData(invoicedMonthlyData));
            else if (dataType === 'Credited') setCreditedData(formatData(creditedMonthlyData));
        }
    }, [ventasData, startDate, endDate, currency, compareYear, dataType]);

    // Return appropriate data for the chart based on compareYear toggle
    const getChartData = () => {
        if (compareYear) {
            return cyData;
        }
        switch (dataType) {
            case 'Net':
                return NetData;
            case 'Invoiced':
                return invoicedData;
            case 'Credited':
                return creditedData;
            default:
                return NetData;
        }
    };

    // Toggle handler for "CY vs LY"
    const handleCompareYearToggle = () => {
        // Set date range to start of previous year for calculation
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        const start = `${previousYear}-01-01`;
        setStartDate(start);
        const newVal = !compareYear;
        setCompareYear(newVal);
        // Force tooltip on when compare mode is enabled
        if (newVal) setTooltipToggle(true);
    };

    const formatCurrency = (amount, currencyCode) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    return (
        <>
            <Card className='dark-card-2'>
                <Card.Header className='dark-card-header'>
                    <Navbar bg="dark" variant="dark" className="ventas-date-toggle-navbar">
                        <Form className='ventas-date-toggle-turnover-form'>
                            <Form.Group controlId="startDate" className="custom-row-form-group">
                                <Form.Label className="form-label-flat">Start Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control-custom"
                                />
                            </Form.Group>
                            <Form.Group controlId="endDate" className="custom-row-form-group">
                                <Form.Label className="form-label-flat">End Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control-custom"
                                />
                            </Form.Group>
                        </Form>
                        <ToggleButtonGroup type="radio" name="dataType" value={dataType} className="mb-1 toggle-button-small" onChange={handleDataTypeChange}>
                            <ToggleButton 
                                id="Net" 
                                value="Net" 
                                variant={dataType === 'Net' ? 'warning' : 'outline-warning'}
                            >
                                Net
                            </ToggleButton>
                            <ToggleButton 
                                id="invoiced" 
                                value="Invoiced" 
                                variant={dataType === 'Invoiced' ? 'warning' : 'outline-warning'}
                            >
                                Invoiced
                            </ToggleButton>
                            <ToggleButton 
                                id="credited" 
                                value="Credited" 
                                variant={dataType === 'Credited' ? 'warning' : 'outline-warning'}
                            >
                                Credited
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Navbar>
                </Card.Header>
                <Card.Body className='body-of-toggle-ventas-card'>
                    <div className="title-and-toggle">
                        <Card.Title className="card-title">
                            Revenue by Center:
                        </Card.Title>
                        <ToggleButtonGroup type="radio" name="currency" value={currency} className="mb-1 toggle-button-small" onChange={(val) => handleCurrencyChange(val)}>
                            <ToggleButton 
                                id="local" 
                                value="Local" 
                                variant={currency === 'Local' ? 'warning' : 'outline-warning'}
                            >
                                {currency === 'Local' ? localCurrency : 'Local'}
                            </ToggleButton>
                            <ToggleButton 
                                id="group" 
                                value="Group" 
                                variant={currency === 'Group' ? 'warning' : 'outline-warning'}
                            >
                                EUR
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Container>
                        <Row>
                            <Col className="center-header"><strong>Center</strong></Col>
                            <Col className="center-header"><strong>Net Amount</strong></Col>
                            <Col className="center-header"><strong>Invoiced</strong></Col>
                            <Col className="center-header"><strong>Credited</strong></Col>
                            <Col className="center-header"><strong>Credit %</strong></Col>
                        </Row>
                        {centers.map(center => (
                            <Row key={center} className="mb-2">
                                <Col className="center-label">{`Center ${center}`}</Col>
                                <Col>{turnovers[center]?.billedAmount || 'Loading...'}</Col>
                                <Col>{turnovers[center]?.billedPositive || 'Loading...'}</Col>
                                <Col>{turnovers[center]?.billedNegative || 'Loading...'}</Col>
                                <Col>{turnovers[center]?.creditPercentage || 'Loading...'}</Col>
                            </Row>
                        ))}
                        <hr className="divider-line" />
                        <Row>
                            <Col className="center-header"><strong>Total</strong></Col>
                            <Col>{totals.billedAmount}</Col>
                            <Col>{totals.billedPositive}</Col>
                            <Col>{totals.billedNegative}</Col>
                            <Col>{totals.creditPercentage}</Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
            <Card className='dark-card-2 mt-4'>
                <Card.Header className='dark-card-header-tooltip'>
                    <div style={{width: "300px", display: "flex", justifyContent: "space-between", alignItems: 'center', position: "absolute", right: "0", top: "0"}}>
                        <Form.Check 
                            type="switch" 
                            label="Toggle Tooltip/Label" 
                            style={{ display: "flex", justifyContent: "space-evenly", alignItems: 'center', fontSize: "12px" }}
                            checked={tooltipToggle}
                            onChange={() => setTooltipToggle(!tooltipToggle)}
                            className='toggle-tooltip'
                        />
                        {/* New "CY vs LY" toggle */}
                        <Form.Check 
                            type="switch" 
                            label="CY vs LY" 
                            style={{ display: "flex", justifyContent: "space-evenly", alignItems: 'center', fontSize: "12px" }}
                            checked={compareYear}
                            onChange={handleCompareYearToggle}
                            className='toggle-cy-ly'
                        />
                    </div>
                    <Card.Title style={{marginTop: "7px", margin: "auto" }}>Sales Trend</Card.Title>
                </Card.Header>
                <Card.Body style={{width: "100%"}}>
                    <VentasTurnoverDashChart 
                        salesData={getChartData()} 
                        localCurrency={currency === 'Local' ? localCurrency : 'EUR'} 
                        dataType={dataType} 
                        tooltipToggle={tooltipToggle || compareYear} 
                        compareYear={compareYear}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

export default VentasTurnoverDashMechanics;
