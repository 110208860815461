import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';

const VBC_CreditAnalysisTable = ({
  creditData,
  selectedAggregation,
  selectedGroup,
  setSelectedGroup,
  colorMapping,
  showAll,
}) => {
  const [expandedGroups, setExpandedGroups] = useState([]);

  // This mapping should be identical to what you use in the PieChart.
  const aggregationKeyMapping = {
    'Order Reason': 'Order Reason',
    'Client Description': 'Client Description',
    'Material': 'Material Description',
  };

  const groupKeyName = aggregationKeyMapping[selectedAggregation];

  // Group data using the absolute value for consistency.
  const groupedData = creditData.reduce((acc, item) => {
    let groupKey = item[groupKeyName] || 'Unassigned';
    const amount = Math.abs(item['Net Billed Amount']);
    if (!acc[groupKey]) {
      acc[groupKey] = { total: 0, items: [] };
    }
    acc[groupKey].total += amount;
    acc[groupKey].items.push(item);
    return acc;
  }, {});

  // Convert groupedData to an array.
  let groupTotals = Object.keys(groupedData).map((groupKey) => ({
    groupKey,
    total: groupedData[groupKey].total,
    items: groupedData[groupKey].items,
  }));

  // Sort groups by total descending.
  groupTotals.sort((a, b) => b.total - a.total);

  // Use the same maximum items logic as the PieChart.
  const maxItems = selectedAggregation === 'Material' ? 10 : 7;
  let limitedGroups = groupTotals;
  if (!showAll) {
    limitedGroups = groupTotals.slice(0, maxItems);
  }

  // Calculate overall total based on the limited groups.
  const overallTotal = limitedGroups.reduce((acc, group) => acc + group.total, 0);

  const toggleGroup = (groupKey) => {
    setExpandedGroups((prev) =>
      prev.includes(groupKey)
        ? prev.filter((key) => key !== groupKey)
        : [...prev, groupKey]
    );
  };

  return (
    <div
      className="-Credit-Analysis-Table-"
      style={{ overflowY: 'auto', maxHeight: '500px' }}
    >
      <Table
        striped
        bordered
        hover
        variant="dark"
        size="sm"
        style={{ fontSize: 'medium' }}
      >
        <thead>
          <tr>
            <th>{selectedAggregation}</th>
            <th>Total Net Billed Amount</th>
            <th>As %</th>
          </tr>
        </thead>
        <tbody>
          {limitedGroups.map(({ groupKey, total, items }) => {
            // Compute percentage based on the limited groups total.
            const percentage =
              overallTotal > 0 ? ((total / overallTotal) * 100).toFixed(0) : 0;
            return (
              <React.Fragment key={groupKey}>
                <tr
                  onClick={() => {
                    toggleGroup(groupKey);
                    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedGroup === groupKey ? '#343a40' : 'transparent',
                  }}
                >
                  <td>
                    <span
                      style={{
                        color: colorMapping[groupKey] || '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                    >
                      ■{' '}
                    </span>
                    {groupKey}
                  </td>
                  <td>{formatCurrency(total)}</td>
                  <td>{percentage}%</td>
                </tr>
                {expandedGroups.includes(groupKey) && (
                  <tr>
                    <td colSpan="3" style={{ padding: 0 }}>
                      <Table bordered variant="dark" size="sm" style={{ margin: 0 }}>
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            {selectedAggregation === 'Client Description' ? (
                              <>
                                <th>Order Reason</th>
                                <th>Material</th>
                              </>
                            ) : selectedAggregation === 'Material' ? (
                              <>
                                <th>Reason</th>
                                <th>Client</th>
                              </>
                            ) : selectedAggregation === 'Order Reason' ? (
                              <>
                                <th>Client</th>
                                <th>Material</th>
                              </>
                            ) : null}
                            <th>Net Billed Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item, index) => (
                            <tr key={index}>
                              <td>{item['Invoice Document']}</td>
                              {selectedAggregation === 'Client Description' ? (
                                <>
                                  <td>{item['Order Reason']}</td>
                                  <td>{item['Material Description']}</td>
                                </>
                              ) : selectedAggregation === 'Material' ? (
                                <>
                                  <td>{item['Order Reason']}</td>
                                  <td>{item['Client Description']}</td>
                                </>
                              ) : selectedAggregation === 'Order Reason' ? (
                                <>
                                  <td>{item['Client Description']}</td>
                                  <td>{item['Material Description']}</td>
                                </>
                              ) : null}
                              <td>{formatCurrency(item['Net Billed Amount'])}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default VBC_CreditAnalysisTable;
