import React, { useState } from 'react';
import MainTable from './MainTable';
import CalendarView from './CalendarView';
import SalesOrdersFilterToolbar from './SalesOrdersFilterToolbar';
import { Card } from 'react-bootstrap';

const SalesOrdersComponentCycler = ({ DeliveryScheduleProps }) => {
    const [filterState, setFilterState] = useState({ reference: '', customer: '', material: '' });
    const [selectedView, setSelectedView] = useState('Main Table');
    const { rawData } = DeliveryScheduleProps;

    const handleFilterChange = (field, value) => {
        setFilterState(prev => ({ ...prev, [field]: value }));
    };

    const toggleView = (e) => {
        const view = e.target.innerText;
        console.log('view:', view);
        setSelectedView(view);
    };

    const ComponentMap = {
        "Main Table": {
            component: <MainTable filterState={filterState} rawData={rawData} />,
            className: 'main-table-component'
        },
        "Calendar View": {
            component: <CalendarView filterState={filterState} rawData={rawData}/>,
            className: 'month-view-component'
        },
    };

    // Validate if selectedView exists in ComponentMap, otherwise render a basic card
    const selectedComponent = ComponentMap[selectedView] ? ComponentMap[selectedView].component : (
        <Card style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title>Not Implemented</Card.Title>
                <Card.Text>
                    This component is not implemented yet.
                </Card.Text>
            </Card.Body>
        </Card>
    );

    return (
        <div className="component-cycler-container">
            <SalesOrdersFilterToolbar onFilterChange={handleFilterChange} toggleView={toggleView} />
            <div className={ComponentMap[selectedView]?.className}>
                {selectedComponent}
            </div>
        </div>
    );
}

export default SalesOrdersComponentCycler;
