import React, { useState } from "react";
import { NavDropdown, Form, FormControl, Button } from "react-bootstrap";

const FilterDropdown = ({
    title,
    options = [],
    selectedItems = [],
    setSelectedItems,
    searchValue,
    setSearchValue,
    multiSelect,
    setMultiSelect,
    handleSelectAll,
    setFilter,
    type = "string",
}) => {
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    const handleItemSelect = (value, event) => {
        event.preventDefault();
        event.stopPropagation();
        if (multiSelect) {
            setSelectedItems((prev) =>
                prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            );
        } else {
            setSelectedItems([value]);
            if (setFilter) setFilter(value);
        }
    };

    const handleMultiSelectToggle = () => {
        setMultiSelect(!multiSelect);
        setSelectedItems([]);
    };

    const handleClearFilter = () => {
        setSelectedItems([]);
        if (setFilter) setFilter("");
        setSearchValue("");
        setHighlightedIndex(0);
    };

    const sortOptions = (options) => {
        if (type === "number") {
            return options.sort((a, b) => parseFloat(a) - parseFloat(b));
        }
        return options.sort((a, b) => a.toString().localeCompare(b.toString()));
    };

    const handleKeyDown = (e) => {
        if (options.length === 0) return;

        if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prev) => (prev + 1) % options.length);
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prev) => (prev - 1 + options.length) % options.length);
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (highlightedIndex >= 0 && highlightedIndex < options.length) {
                handleItemSelect(options[highlightedIndex], e);
            }
        } else if (e.key === "Escape") {
            setHighlightedIndex(0);
        }
    };

    return (
        <NavDropdown
            title={title}
            id={`${title.toLowerCase()}-dropdown`}
            style={{ fontSize: "medium" }}
            menuVariant="dark"
        >
            <Form className="px-3">
                <FormControl
                    type="text"
                    placeholder={`Search ${title}...`}
                    value={searchValue || ""}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown} // Fix: Directly handling key events here
                    className="mb-2"
                />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Form.Check
                        type="switch"
                        id={`multi-select-${title.toLowerCase()}-switch`}
                        label="Multi-select"
                        checked={multiSelect}
                        onChange={handleMultiSelectToggle}
                        style={{ fontSize: "small" }}
                    />
                    <Button
                        size="sm"
                        onClick={handleSelectAll}
                        style={{ width: "65px", fontSize: "11px", marginRight: "5px" }}
                    >
                        Select All
                    </Button>
                    <Button
                        size="sm"
                        variant="danger"
                        onClick={handleClearFilter}
                        style={{ width: "65px", fontSize: "11px" }}
                    >
                        Clear
                    </Button>
                </div>
            </Form>
            {sortOptions(options).map((value, idx) => (
                <NavDropdown.Item
                    key={idx}
                    style={{
                        fontSize: "medium",
                        width: "fit-content",
                        backgroundColor: idx === highlightedIndex ? "rgba(255, 255, 0, 0.6)" : "transparent",
                        color: selectedItems.includes(value) ? "gold" : "lightgrey",
                    }}
                    onClick={(e) => handleItemSelect(value, e)}
                >
                    {value}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
};

export default FilterDropdown;
