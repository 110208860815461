import React from 'react';
import { Button, NavDropdown, Form } from 'react-bootstrap';
import PaginationRecordsSelectionControl from './PaginationRecordsSelectionControl';
import RackDivisionSliders from './RackDivisionSliders';

// Assume these are defined somewhere or imported
// DivisionIndicatorProps should be available in your context
// For example:
// const DivisionIndicatorProps = {
//   divisionIndicator3mm,
//   setDivisionIndicator3mm,
//   divisionIndicator6mm,
//   setDivisionIndicator6mm,
//   divisionIndicator12mm,
//   setDivisionIndicator12mm,
//   divisionIndicator20mm,
//   setDivisionIndicator20mm,
// };

const PlanItNavbarRightSection = ({
  rowsPerPage,
  setRowsPerPage,
  setCurrentPage,
  handleClearFilters,
  handleClearData,
  columns,
  visibleColumns,
  setVisibleColumns,
  onlyShowAssigned,
  setOnlyShowAssigned,
  showRackSpace,
  setShowRackSpace,
  showWeights,
  setShowWeights,
  handleExport,
  handleSendEmail,
  defaultClient,
  setDefaultClient, 
  clientsList,
  DivisionIndicatorProps // pass in the division indicators as a prop if needed
}) => {

  const {
    divisionIndicator3mm,
    setDivisionIndicator3mm,
    divisionIndicator6mm,
    setDivisionIndicator6mm,
    divisionIndicator12mm,
    setDivisionIndicator12mm,
    divisionIndicator20mm,
    setDivisionIndicator20mm
  } = DivisionIndicatorProps;   

  // Toggle a column's visibility
  const handleColumnToggle = (columnKey) => {
    if (visibleColumns.includes(columnKey)) {
      setVisibleColumns(visibleColumns.filter(col => col !== columnKey));
    } else {
      setVisibleColumns([...visibleColumns, columnKey]);
    }
  };

  // Clear the default client and remove it from local storage
  const handleClearDefaultClient = () => {
    setDefaultClient("");
    localStorage.removeItem("defaultClient");
  }; 

  // Handle client change and persist the selection to local storage
  const handleClientChange = (e) => {
    const selectedClient = e.target.value;
    setDefaultClient(selectedClient);
    localStorage.setItem("defaultClient", selectedClient);
  };

  const sortedClients = [...clientsList].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return (
    <div className="planIt-navbar-right" style={{ display: "flex", alignItems: "center" }}>
      {/* Settings Dropdown for Column Toggling */}
      <NavDropdown title="Columns" id="columns-dropdown" style={{ fontSize: "medium", marginLeft: "10px", marginRight: "15px" }} menuVariant="dark">
        <Form className="px-3">
          {Object.entries(columns).map(([key, label]) => (
            <Form.Check 
              key={key}
              type="switch"
              id={`column-switch-${key}`}
              label={label}
              checked={visibleColumns.includes(key)}
              onChange={() => handleColumnToggle(key)}
              style={{ fontSize: "13px" }}
            />
          ))}
        </Form>
      </NavDropdown>
      <NavDropdown title="Settings" id="Settings-dropdown" style={{ fontSize: "medium", marginLeft: "10px", marginRight: "15px" }} menuVariant="dark">
        <Form className="px-3">
          <Form.Check 
            key={"OnlyAssigned"}
            type="switch"
            id={"only-assigned-switch"}
            label={"Show Only Assigned"}
            checked={onlyShowAssigned}
            onChange={() => setOnlyShowAssigned(!onlyShowAssigned)}
            style={{ fontSize: "13px" }}
          />
          <Form.Check 
            type="switch"
            id="show-rack-space-switch"
            label="Show Rack Space"
            checked={showRackSpace}
            onChange={() => setShowRackSpace(!showRackSpace)}
            style={{ fontSize: "13px", marginTop: "15px" }}
          />
          <Form.Check 
            type="switch"
            id="show-weights-switch"
            label="Show Weights"
            checked={showWeights}
            onChange={() => setShowWeights(!showWeights)}
            style={{ fontSize: "13px", marginTop: "15px" }}
          />
          {/* Default Client Selection */}
          <Form.Group controlId="default-client-select" style={{ marginTop: "15px" }}>
            <Form.Label style={{ fontSize: "13px" }}>Default Client</Form.Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Select value={defaultClient} onChange={handleClientChange} style={{ flex: 1 }}>
                <option value="">Select a Client...</option>
                {sortedClients.map((client, index) => (
                  <option key={index} value={client}>{client}</option>
                ))}
              </Form.Select>
              {defaultClient && (
                <Button 
                  variant="outline-danger" 
                  size="sm" 
                  style={{ marginLeft: "10px" }} 
                  onClick={handleClearDefaultClient}
                >
                  ✖
                </Button>
              )}
            </div>
          </Form.Group>
          {/* Mini Sliders for Rack Division Indicators */}
          <RackDivisionSliders
            divisionIndicator3mm={divisionIndicator3mm}
            setDivisionIndicator3mm={setDivisionIndicator3mm}
            divisionIndicator6mm={divisionIndicator6mm}
            setDivisionIndicator6mm={setDivisionIndicator6mm}
            divisionIndicator12mm={divisionIndicator12mm}
            setDivisionIndicator12mm={setDivisionIndicator12mm}
            divisionIndicator20mm={divisionIndicator20mm}
            setDivisionIndicator20mm={setDivisionIndicator20mm}
          />
        </Form>
      </NavDropdown>
      {/* Pagination Control */}
      <div>
        <PaginationRecordsSelectionControl 
          value={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      {/* Clear Filters and Clear Data Buttons */}
      <Button 
        onClick={handleClearFilters} 
        variant="warning" 
        style={{ margin: "5px", color: 'white', fontWeight: 'bold' }}
      >
        Clear Filters
      </Button>
      <Button 
        onClick={handleExport} 
        variant="success" 
        style={{ margin: "5px", color: 'white', fontWeight: 'bold' }}
      >
        Export
      </Button>
      {/* <Button 
          onClick={handleSendEmail} 
          variant="success" 
          style={{ margin: "5px", color: 'white', fontWeight: 'bold' }}
      >
          Send Email
      </Button> */}
      <Button 
        onClick={handleClearData} 
        variant="danger" 
        style={{ margin: "5px" }}
      >
        Clear Data
      </Button>
    </div>
  );
};

export default PlanItNavbarRightSection;
