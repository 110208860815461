import React, { useState, useEffect } from 'react';
import CustomsSummariserConfig from './CustomsSummariserConfig';
import { summariseClearanceData } from '../../../Utils/CustomsSummariserFuncs';
import CustomsClearanceSummaryTable from './CustomsClearanceSummaryTable';
import CustomsSummariserNavBar from './CustomsSummariserNavBar';


function CustomsSummariserMain() {
  const [customsFileToSummarise, setCustomsFileToSummarise] = useState(null);
  const [customsSummarisedData, setCustomsSummarisedData] = useState(null);
  const [HSCODEMode, setHSCODEMode] = useState(false);

  const summarisedData = customsSummarisedData ? summariseClearanceData(customsSummarisedData) : [];
    
  useEffect(() => {
    console.log('HSCODEMode', HSCODEMode);
  }, [HSCODEMode]);

  return (
    <div className="CustomsSummariserMain" style={{ padding: '1rem' }}>
      <CustomsSummariserNavBar 
        summarisedData={summarisedData}
        HSCODEMode={HSCODEMode}
        setHSCODEMode={setHSCODEMode} 
      />

      {/* Show the config component only if there is no summarised data */}
      {!summarisedData.length && (
        <CustomsSummariserConfig 
          customsFileToSummarise={customsFileToSummarise} 
          setCustomsFileToSummarise={setCustomsFileToSummarise} 
          customsSummarisedData={customsSummarisedData} 
          setCustomsSummarisedData={setCustomsSummarisedData}
        />
      )}

      

      {/* Show summary table only when we have data */}
      {summarisedData.length > 0 && (
        <div style={{ width: '90%', margin: '0 auto' }}>
          <h5 style={{ marginBottom: '1rem', textAlign: 'center' }}>
            Automatic Customs Clearance Summary
          </h5>
          <CustomsClearanceSummaryTable 
            data={summarisedData} 
            HSCODEMode={HSCODEMode}
          />
        </div>
      )}
    </div>
  );
}

export default CustomsSummariserMain;
