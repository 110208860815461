import React, {useState} from "react"
import PlanItConfig from "../Components/Excels/PlanIt/PlanItConfig"
import PlanItMain from "../Components/Excels/PlanIt/PlanItMain"

const PlanItParent = ({AppProps}) => {
    const [savePlanItDataForFutureUse, setSavePlanItDataForFutureUse] = useState(false);
    const [statusOrdersData, setStatusOrdersData] = useState(); // will switch to Status Orders
    const [dataIsValid, setDataIsValid] = useState(false);

    const [plannedTrucksData, setPlannedTrucksData] = useState(); //not yet utilised


    const planItProps = {
        savePlanItDataForFutureUse: savePlanItDataForFutureUse,
        setSavePlanItDataForFutureUse: setSavePlanItDataForFutureUse,
        statusOrdersData: statusOrdersData,
        setStatusOrdersData: setStatusOrdersData,
        dataIsValid: dataIsValid,
        setDataIsValid: setDataIsValid,

        //Future
        plannedTrucksData: plannedTrucksData,
        setPlannedTrucksData: setPlannedTrucksData,
    };

 return (
    <div className="-Planit-Parent-">
        {!dataIsValid && <PlanItConfig planItProps={planItProps} />}
        {dataIsValid && <PlanItMain planItProps={planItProps} AppProps={AppProps} />}
    </div>
 )
}

export default PlanItParent