import React, { useMemo, useState, useEffect } from 'react';
import { Card, Row, Form } from 'react-bootstrap';
import VBC_TopMaterialsTable from './VBC_TopMaterialsTable';
import VBC_TopMaterialsBarChart from './VBC_TopMaterialsBarChart';
import VBC_MaterialSalesChart from './VBC_TopMaterialsSalesChart';
import { aggregateDataByMonthYear } from '../../../../Utils/AggregateFunctions';
import codexFlushed from '../../../../Utils/CodexFlushed.json';

const VBC_TopMaterials = ({ data, dataType, localCurrency }) => {
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [chartData, setChartData] = useState([]);
    const [slabsOnly, setSlabsOnly] = useState(false); // For "Slabs Only" filtering
    const [groupByDecor, setGroupByDecor] = useState(false); // New flag for "By Decor" mode

    // Function to filter and transform data based on dataType, slabsOnly and groupByDecor flag
    const getTopMaterials = useMemo(() => {
        let filteredData = data;

        // Apply filter based on dataType
        switch (dataType) {
            case "Gross":
                // All sales included
                break;
            case "Invoiced":
                filteredData = filteredData.filter(item => item['Net Billed Amount'] > 0);
                break;
            case "Credited":
                filteredData = filteredData.filter(item => item['Net Billed Amount'] < 0);
                break;
            default:
                break;
        }

        // Remove a specific invalid material
        filteredData = filteredData.filter(item => item['Material'] !== "400000014");

        // Apply slabs-only filter if selected; otherwise, filter out materials starting with a number.
        if (slabsOnly) {
            filteredData = filteredData.filter(item => item['Material'].startsWith("TB"));
        } else {
            filteredData = filteredData.filter(item => !/^\d/.test(item['Material']));
        }

        // Aggregate data – two modes:
        if (groupByDecor) {
            // Group by Decor. For each item, take the first 7 characters of the material code,
            // then look it up in codexFlushed to retrieve the Decor name.
            const decorTotals = {};
            filteredData.forEach(item => {
                const halfCode = item['Material'].substring(0, 7);
                const decorRecord = codexFlushed.find(rec => rec["Half Code"] === halfCode);
                const decorName = decorRecord ? decorRecord["Decor"] : "Unknown Decor";
                if (!decorTotals[decorName]) {
                    decorTotals[decorName] = {
                        Decor: decorName,
                        'Total Quantity': 0,
                        'Total Amount': 0
                    };
                }
                decorTotals[decorName]['Total Quantity'] += item['Billed Quantity'];
                decorTotals[decorName]['Total Amount'] += item['Net Billed Amount'];
            });
            let sortedDecors = Object.values(decorTotals);
            // Sort descending by Total Amount (or Quantity, if desired)
            if (dataType === "Credited") {
                sortedDecors.sort((a, b) => a['Total Amount'] - b['Total Amount']);
            } else {
                sortedDecors.sort((a, b) => b['Total Amount'] - a['Total Amount']);
            }
            return sortedDecors;
        } else {
            // Group by Material (existing logic)
            const materialTotals = filteredData.reduce((acc, item) => {
                const material = item['Material'];
                if (!acc[material]) {
                    acc[material] = {
                        'Material': material,
                        'Material Description': item['Material Description'],
                        'Total Quantity': 0,
                        'Total Amount': 0
                    };
                }
                acc[material]['Total Quantity'] += item['Billed Quantity'];
                acc[material]['Total Amount'] += item['Net Billed Amount'];
                return acc;
            }, {});

            let sortedMaterials = Object.values(materialTotals);
            if (dataType === "Credited") {
                // For credited data, sort by amount ascending
                sortedMaterials.sort((a, b) => a['Total Amount'] - b['Total Amount']);
            } else {
                // For invoiced or gross, sort by quantity descending
                sortedMaterials.sort((a, b) => b['Total Quantity'] - a['Total Quantity']);
            }
            return sortedMaterials;
        }
    }, [data, dataType, slabsOnly, groupByDecor]);

    // Update chart data when a selection is made
    useEffect(() => {
        if (selectedMaterial) {
            let materialData;
            if (groupByDecor) {
                // In By Decor mode, we need to filter items whose decor (looked up via Half Code) matches the selected value.
                materialData = data.filter(item => {
                    const halfCode = item['Material'].substring(0, 7);
                    const decorRecord = codexFlushed.find(rec => rec["Half Code"] === halfCode);
                    const decorName = decorRecord ? decorRecord["Decor"] : "Unknown Decor";
                    return decorName === selectedMaterial;
                });
            } else {
                materialData = data.filter(item => item['Material'] === selectedMaterial);
            }

            // Aggregate the data by month/year and filter by dataType
            const aggregatedData = aggregateDataByMonthYear(
                materialData,
                'Billed Quantity',
                value => {
                    if (dataType === "Invoiced") return value > 0;
                    if (dataType === "Credited") return value < 0;
                    return true;
                }
            );
            setChartData(aggregatedData);
        } else {
            // For top items chart (bar chart), use top 10 from aggregated results.
            const topItemsData = getTopMaterials.slice(0, 10).map((item, index) => {
                return groupByDecor
                    ? {
                        id: index,
                        // Use Decor field in By Decor mode
                        material: item.Decor,
                        totalQuantity: item['Total Quantity'],
                    }
                    : {
                        id: index,
                        material: item['Material Description'],
                        totalQuantity: item['Total Quantity'],
                    };
            });
            setChartData(topItemsData);
        }
    }, [selectedMaterial, data, getTopMaterials, dataType, groupByDecor]);

    return (
        <Card className="dark-card-2 top-materials-card">
            <Card.Body className='top-materials-body'>
                <Row className="options-navbar">
                    <Form.Check
                        type="switch"
                        id="custom-switch-3"
                        label="Slabs Only"
                        className="options-switch"
                        checked={slabsOnly}
                        onChange={() => setSlabsOnly(!slabsOnly)}
                    />
                    <Form.Check
                        type="switch"
                        id="custom-switch-4"
                        label="By Decor"
                        className="options-switch"
                        checked={groupByDecor}
                        onChange={() => setGroupByDecor(!groupByDecor)}
                    />
                </Row>
                <Row className='top-material-table-row'>
                    <VBC_TopMaterialsTable 
                        topMaterials={getTopMaterials} 
                        setSelectedMaterial={setSelectedMaterial} 
                        localCurrency={localCurrency}
                        slabsOnly={slabsOnly}
                        groupByDecor={groupByDecor}  // Pass the new flag to the table
                    />
                </Row>
                <Row className='top-material-chart-row'>
                    {chartData && chartData.length > 0 && (
                        selectedMaterial !== "" ? (
                            <VBC_MaterialSalesChart 
                                materialSalesData={chartData} 
                                localCurrency={localCurrency} 
                            />
                        ) : (
                            <VBC_TopMaterialsBarChart 
                                topMaterials={chartData} 
                                localCurrency={localCurrency}
                            /> 
                        )
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default VBC_TopMaterials;
