import React from "react";

const FilterTextBar = ({ label, filterText, setFilterText, placeholder = "Enter filter text..." }) => {
  return (
    <div className="filter-text-bar" style={{ display: "flex", alignItems: "center" }}>
      <label style={{fontSize: "12px"}}>{label}:</label>
      <input
        type="text"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        placeholder={placeholder}
        style={{ width: "150px", height: "25px", fontSize: "12px", marginLeft: "5px" }}
      />
    </div>
  );
};

export default FilterTextBar;
