import React, { useState, useEffect, useMemo } from 'react';
import { Card, Table, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { excelDateToJSDate } from '../../../../Utils/DateOps';
import centersAndCurrencies from '../../../../Utils/CentersAndCurrencies.json';

const VentasByYearAccount = ({ ventasData, selectedClient }) => {
    const [currency, setCurrency] = useState('Local');
    const [sortBy, setSortBy] = useState('totalSpend');
    const [sortDirection, setSortDirection] = useState('desc');
    const [localCurrency, setLocalCurrency] = useState('EUR');
    const [defaultLocalCurrency, setDefaultLocalCurrency] = useState('EUR');
    const [limitToTop100, setLimitToTop100] = useState(true);
    // New period mode state: "YT" shows full-year data; "YTDYP" applies cutoff (Year-to-Date Year Previous)
    const [periodMode, setPeriodMode] = useState('YT');

    // Set local currency based on first record
    useEffect(() => {
        if (ventasData.length > 0) {
            const firstRecord = ventasData[0];
            const society = firstRecord["Society"];
            const centerInfo = centersAndCurrencies.find(
                (center) => center.Society === society
            );
            if (centerInfo) {
                const detectedCurrency = centerInfo["Display Currency"];
                setLocalCurrency(detectedCurrency);
                setDefaultLocalCurrency(detectedCurrency);
                console.log(`Local currency set to ${detectedCurrency}`);
            }
        }
    }, [ventasData]);

    // Get unique years from the data
    const years = useMemo(() => {
        const yearsSet = new Set();
        ventasData.forEach(item => {
            const date = excelDateToJSDate(item["Invoice Document Date"]);
            const year = date.getFullYear();
            if (year >= 2022) yearsSet.add(year);
        });
        return Array.from(yearsSet).sort();
    }, [ventasData]);

    // Calculate sales by account and year
    const accountData = useMemo(() => {
        const accounts = {};
        const today = new Date(); // current date for cutoff calculation
        
        // Filter data by selected clients if any
        let filteredData = ventasData;
        filteredData = filteredData.filter(item => item["Client Description"] !== "THESIZE SURFACES, S.");
        if (selectedClient && selectedClient.length > 0) {
            filteredData = ventasData.filter(item => 
                Array.isArray(selectedClient) 
                    ? selectedClient.includes(item["Client Description"])
                    : item["Client Description"] === selectedClient
            );
        }
        
        // Initialize accounts and years
        filteredData.forEach(item => {
            const clientDesc = item["Client Description"];
            if (!clientDesc) return;
            
            if (!accounts[clientDesc]) {
                accounts[clientDesc] = {
                    accountName: clientDesc,
                    lastPurchaseDate: null,
                    lastPurchaseDateExcel: 0,
                    totalSpend: 0,
                    years: {}
                };
                
                // Initialize all years with 0
                years.forEach(year => {
                    accounts[clientDesc].years[year] = 0;
                });
            }
            
            const date = excelDateToJSDate(item["Invoice Document Date"]);
            const year = date.getFullYear();
            
            // Only process from 2022 onwards
            if (year < 2022) return;
            
            // When period mode is YTDYP, only consider records up to current month/day for that year
            if (periodMode === 'YTDYP') {
                const cutoff = new Date(year, today.getMonth(), today.getDate());
                if (date > cutoff) return; // skip records after cutoff
            }
            
            // Update last purchase date if this is more recent (and within cutoff if needed)
            const excelDate = item["Invoice Document Date"];
            if (!accounts[clientDesc].lastPurchaseDateExcel || excelDate > accounts[clientDesc].lastPurchaseDateExcel) {
                accounts[clientDesc].lastPurchaseDateExcel = excelDate;
                accounts[clientDesc].lastPurchaseDate = date;
            }
            
            // Add to yearly totals
            const amount = currency === 'Local' 
                ? parseFloat(item["Net Billed Amount (Society Currency)"]) || 0 
                : parseFloat(item["Net Billed Amount (Group Currency)"]) || 0;
            
            accounts[clientDesc].years[year] = (accounts[clientDesc].years[year] || 0) + amount;
            accounts[clientDesc].totalSpend += amount;
        });
        
        // Calculate growth rates
        Object.values(accounts).forEach(account => {
            for (let i = 0; i < years.length - 1; i++) {
                const currentYear = years[i];
                const nextYear = years[i + 1];
                const currentValue = account.years[currentYear];
                const nextValue = account.years[nextYear];
                
                if (currentValue !== 0) {
                    account[`growth_${currentYear}_${nextYear}`] = ((nextValue - currentValue) / Math.abs(currentValue)) * 100;
                } else {
                    account[`growth_${currentYear}_${nextYear}`] = nextValue > 0 ? 100 : 0;
                }
            }
        });
        
        return Object.values(accounts);
    }, [ventasData, years, currency, selectedClient, periodMode]);
    
    // Sort data
    const sortedData = useMemo(() => {
        let sorted = accountData.sort((a, b) => {
            if (sortBy === 'lastPurchaseDate') {
                return sortDirection === 'desc' 
                    ? b.lastPurchaseDateExcel - a.lastPurchaseDateExcel 
                    : a.lastPurchaseDateExcel - b.lastPurchaseDateExcel;
            } else if (sortBy === 'accountName') {
                return sortDirection === 'desc'
                    ? b.accountName.localeCompare(a.accountName)
                    : a.accountName.localeCompare(b.accountName);
            } else if (sortBy.startsWith('growth_')) {
                return sortDirection === 'desc'
                    ? b[sortBy] - a[sortBy]
                    : a[sortBy] - b[sortBy];
            } else if (sortBy.startsWith('year_')) {
                const year = sortBy.split('_')[1];
                return sortDirection === 'desc'
                    ? b.years[year] - a.years[year]
                    : a.years[year] - b.years[year];
            } else {
                return sortDirection === 'desc'
                    ? b[sortBy] - a[sortBy]
                    : a[sortBy] - b[sortBy];
            }
        });
        
        // Apply top 100 limit if enabled
        if (limitToTop100) {
            sorted = sorted.slice(0, 100);
        }
        
        return sorted;
    }, [accountData, sortBy, sortDirection, limitToTop100]);

    const handleCurrencyChange = (val) => {
        setCurrency(val);
        if (val === 'Group') {
            setLocalCurrency('EUR');
        } else {
            setLocalCurrency(defaultLocalCurrency);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortDirection('desc');
        }
    };

    const formatCurrency = (amount, currencyCode) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    const formatDate = (date) => {
        return date ? date.toLocaleDateString('en-GB') : 'N/A';
    };

    const formatGrowth = (value) => {
        if (value === undefined || isNaN(value)) return 'N/A';
        return `${value.toFixed(2)}%`;
    };

    return (
        <Card className='dark-card-2' style={{ maxHeight: '71.5vh' }}>
            <Card.Header className='dark-card-header'>
                <div className="d-flex justify-content-end align-items-center">
                    <ToggleButtonGroup 
                        type="radio" 
                        name="currency" 
                        value={currency} 
                        className="mb-1 toggle-button-small" 
                        onChange={(val) => handleCurrencyChange(val)}
                        style={{ marginRight: '10px' }}
                    >
                        <ToggleButton 
                            id="local" 
                            value="Local" 
                            variant={currency === 'Local' ? 'warning' : 'outline-warning'}
                        >
                            {currency === 'Local' ? localCurrency : 'Local'}
                        </ToggleButton>
                        <ToggleButton 
                            id="group" 
                            value="Group" 
                            variant={currency === 'Group' ? 'warning' : 'outline-warning'}
                        >
                            EUR
                        </ToggleButton>
                    </ToggleButtonGroup>
                    
                    <ToggleButtonGroup 
                        type="radio" 
                        name="pagination" 
                        value={limitToTop100} 
                        className="mb-1 toggle-button-small" 
                        onChange={(val) => setLimitToTop100(val)}
                        style={{ marginRight: '18px' }}
                    >
                        <ToggleButton 
                            id="all" 
                            value={false} 
                            variant={!limitToTop100 ? 'warning' : 'outline-warning'}
                        >
                            All
                        </ToggleButton>
                        <ToggleButton 
                            id="top100" 
                            value={true} 
                            variant={limitToTop100 ? 'warning' : 'outline-warning'}
                        >
                            Top 100
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {/* New Period Mode Toggle Group */}
                    <ToggleButtonGroup 
                        type="radio" 
                        name="periodMode" 
                        value={periodMode} 
                        className="mb-1 toggle-button-small" 
                        onChange={(val) => setPeriodMode(val)}
                        style={{ marginRight: '10px' }}
                    >
                        <ToggleButton 
                            id="yt" 
                            value="YT" 
                            variant={periodMode === 'YT' ? 'warning' : 'outline-warning'}
                        >
                            YT
                        </ToggleButton>
                        <ToggleButton 
                            id="ytdyp" 
                            value="YTDYP" 
                            variant={periodMode === 'YTDYP' ? 'warning' : 'outline-warning'}
                        >
                            YTDYP
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </Card.Header>
            <Card.Body 
                className='body-of-toggle-ventas-card' 
                style={{ 
                    maxHeight: '68.5vh', // Adjusted height to ensure there's room for scrolling
                    padding: 0,
                    overflow: 'hidden'
                }}
            >
                <div 
                    style={{ 
                        maxHeight: '100%',
                        width: '98%',
                        overflowY: 'auto',
                        overflowX: 'auto'
                    }}
                >
                    <Table 
                        striped 
                        bordered 
                        hover 
                        variant="dark" 
                        className="table-sm" 
                        style={{ 
                            position: 'relative',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                        }}
                    >
                        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                            <tr>
                                <th 
                                    onClick={() => handleSortChange('accountName')} 
                                    className="clickable-header"
                                    style={{ 
                                        position: 'sticky', 
                                        top: 0, 
                                        backgroundColor: '#343a40', 
                                        zIndex: 2,
                                        boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)'
                                    }}
                                >
                                    Account Name {sortBy === 'accountName' && (sortDirection === 'asc' ? '↑' : '↓')}
                                </th>
                                {years.map(year => (
                                    <th 
                                        key={year} 
                                        onClick={() => handleSortChange(`year_${year}`)} 
                                        className="clickable-header text-right"
                                        style={{ 
                                            position: 'sticky', 
                                            top: 0, 
                                            backgroundColor: '#343a40', 
                                            zIndex: 2 
                                        }}
                                    >
                                        {year} {sortBy === `year_${year}` && (sortDirection === 'asc' ? '↑' : '↓')}
                                    </th>
                                ))}
                                {years.length > 1 && years.slice(0, -1).map((year, index) => {
                                    const nextYear = years[index + 1];
                                    return (
                                        <th 
                                            key={`growth_${year}_${nextYear}`} 
                                            onClick={() => handleSortChange(`growth_${year}_${nextYear}`)} 
                                            className="clickable-header text-right"
                                            style={{ 
                                                position: 'sticky', 
                                                top: 0, 
                                                backgroundColor: '#343a40', 
                                                zIndex: 2 
                                            }}
                                        >
                                            {year} vs {nextYear} {sortBy === `growth_${year}_${nextYear}` && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                    );
                                })}
                                <th 
                                    onClick={() => handleSortChange('totalSpend')} 
                                    className="clickable-header text-right"
                                    style={{ 
                                        position: 'sticky', 
                                        top: 0, 
                                        backgroundColor: '#343a40', 
                                        zIndex: 2 
                                    }}
                                >
                                    Total {sortBy === 'totalSpend' && (sortDirection === 'asc' ? '↑' : '↓')}
                                </th>
                                <th 
                                    onClick={() => handleSortChange('lastPurchaseDate')} 
                                    className="clickable-header text-right"
                                    style={{ 
                                        position: 'sticky', 
                                        top: 0, 
                                        backgroundColor: '#343a40', 
                                        zIndex: 2 
                                    }}
                                >
                                    Last Purchase {sortBy === 'lastPurchaseDate' && (sortDirection === 'asc' ? '↑' : '↓')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((account, index) => (
                                <tr key={index}>
                                    <td>{account.accountName}</td>
                                    {years.map(year => (
                                        <td key={year} className="text-right">
                                            {formatCurrency(account.years[year], localCurrency)}
                                        </td>
                                    ))}
                                    {years.length > 1 && years.slice(0, -1).map((year, index) => {
                                        const nextYear = years[index + 1];
                                        const growth = account[`growth_${year}_${nextYear}`];
                                        return (
                                            <td 
                                                key={`growth_${year}_${nextYear}`} 
                                                className="text-right"
                                                style={{ 
                                                    color: growth > 0 ? '#98FB98' : growth < 0 ? '#FF9999' : 'inherit'
                                                }}
                                            >
                                                {formatGrowth(growth)}
                                            </td>
                                        );
                                    })}
                                    <td className="text-right">{formatCurrency(account.totalSpend, localCurrency)}</td>
                                    <td className="text-right">{formatDate(account.lastPurchaseDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default VentasByYearAccount;
