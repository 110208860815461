import { ExportToSpreadsheetWithOmissions } from "../../../../Utils/ExcelExportUtils";
import { calcRackSpace, calcWeightPerUnit, getAdjustedQuantity } from "./PlanItCalculationHelpers";

// Example export function – exports a spreadsheet with visible columns.
const handleExport = ({statusOrdersData, omissions}) => {
    // Here, filtered data is statusOrdersData (already filtered)
    // You may call your ExportToSpreadsheetWithOmissions utility:
    ExportToSpreadsheetWithOmissions(statusOrdersData, omissions);
  };
  
// emailUtil.js

/**
 * Builds an HTML email report based on visible columns and data.
 *
 * @param {Object} params
 * @param {string[]} params.visibleColumns - Array of visible column keys.
 * @param {Object} params.columnLabels - Object mapping column keys to friendly labels.
 * @param {Array} params.statusOrdersData - Array of data rows.
 * @param {string} [params.emailSubject="Loading Request - Data Report"] - Email subject.
 * @param {string} [params.senderName="Your Name"] - Sender name for the signature.
 * @returns {Object} An object containing the email subject and HTML body.
 */
export const sendEmailReport = ({
    visibleColumns,
    columnLabels,
    statusOrdersData,
    emailSubject = "Loading Request - Data Report",
    senderName = "Your Name"
  }) => {
    let emailBody = "Dear Team,<br/><br/>Please find below the loading details:<br/><br/>";
    
    // Build the HTML table.
    let tableHTML = "<table border='1' cellspacing='0' cellpadding='5'><tr>";
    
    // Filter columns based on available labels.
    const columnsToExport = visibleColumns.filter(col => columnLabels[col]);
    
    // Add table headers.
    columnsToExport.forEach(col => {
      tableHTML += `<th>${columnLabels[col]}</th>`;
    });
    tableHTML += "</tr>";
    
    // Add rows.
    statusOrdersData.forEach(row => {
      tableHTML += "<tr>";
      columnsToExport.forEach(col => {
        tableHTML += `<td>${row[col] || ""}</td>`;
      });
      tableHTML += "</tr>";
    });
    tableHTML += "</table>";
    
    emailBody += tableHTML;
    emailBody += `<br/><br/>Thank you,<br/>${senderName}`;
    
    // Log the email content.
    console.log("Email Subject:", emailSubject);
    console.log("Email Body (HTML):", emailBody);
    
    // Return the email details. You can later integrate your API call here.
    return {
      subject: emailSubject,
      body: emailBody,
    };
};
  

/**
 * Augments data for export based on selection, handling cases where no selection is made.
 *
 * @param {Array<Object>} originalData - The original (filtered) data array.
 * @param {Object} rowAdjustments - Object mapping row indices (in originalData) to adjusted quantities.
 * @param {Array<number>} selectedIndices - Array of indices (in originalData) for rows that were selected.
 * @param {number} divisionIndicator3mm 
 * @param {number} divisionIndicator6mm 
 * @param {number} divisionIndicator12mm 
 * @param {number} divisionIndicator20mm
 * @param {string[]} visibleColumns - Array of visible column keys. 
 * @returns {Array<Object>} New data array with extra columns and a totals row.
 */
export function augmentDataForExport(
  originalData,
  rowAdjustments,
  selectedIndices,
  divisionIndicator3mm,
  divisionIndicator6mm,
  divisionIndicator12mm,
  divisionIndicator20mm,
  visibleColumns
) {
  const calcWeightPerUnit = (row) => {
    const thicknessStr = (row.Articulo || "").substring(9, 11);
    const thicknessNum = parseInt(thicknessStr, 10);
    const weightMatrix = { 3: 40, 6: 80, 12: 160, 20: 260 };
    return weightMatrix[thicknessNum] || 0;
  };

  const calcRackSpace = (row, qtySelected) => {
    const thicknessStr = (row.Articulo || "").substring(9, 11);
    const thicknessNum = parseInt(thicknessStr, 10);
    let division = 1;
    if (thicknessNum === 3) division = divisionIndicator3mm;
    else if (thicknessNum === 6) division = divisionIndicator6mm;
    else if (thicknessNum === 12) division = divisionIndicator12mm;
    else if (thicknessNum === 20) division = divisionIndicator20mm;
    return division > 0 ? qtySelected / division : 0;
  };

  // Check if selections were made
  const hasSelections = selectedIndices.length > 0;

  // Define columns to omit **if selections are made**
  const columnsToOmitIfSelected = ["FeHORNOS", "FeMALLADO", "FePULIDO", "FeCORTE", "Delegacion", "Comentario", "€ línea"];

  // Determine which dataset to export
  const dataToProcess = hasSelections 
    ? selectedIndices.map(i => originalData[i]) // Only selected rows
    : [...originalData]; // If none selected, export all rows

  // Build the processed data
  let augmentedData = dataToProcess.map((row, index) => {
    const absoluteIndex = hasSelections ? selectedIndices[index] : index;
    const qtySelected = hasSelections 
      ? rowAdjustments[absoluteIndex] !== undefined 
        ? rowAdjustments[absoluteIndex] 
        : 0 
      : Number(row.Qty) || 0;

    const weightPerUnit = calcWeightPerUnit(row);
    const weightSelected = qtySelected * weightPerUnit;
    const rackSpace = calcRackSpace(row, qtySelected);

    return {
      ...row,
      ...(hasSelections && { "Qty Selected": qtySelected }),
      [hasSelections ? "Total Weight Selected" : "Total Weight"]: weightSelected,
      [hasSelections ? "Total Rack Space Selected" : "Total Rack Space"]: rackSpace,
    };
  });

  // Calculate totals
  const totals = augmentedData.reduce((acc, row) => {
    if (hasSelections) acc["Qty Selected"] += Number(row["Qty Selected"]) || 0;
    acc[hasSelections ? "Total Weight Selected" : "Total Weight"] += Number(row[hasSelections ? "Total Weight Selected" : "Total Weight"]) || 0;
    acc[hasSelections ? "Total Rack Space Selected" : "Total Rack Space"] += Number(row[hasSelections ? "Total Rack Space Selected" : "Total Rack Space"]) || 0;
    return acc;
  }, { "Qty Selected": 0, "Total Weight Selected": 0, "Total Rack Space Selected": 0 });

  // Build a summary row
  const summaryRow = {};
  const allKeys = Object.keys(augmentedData[0] || {});

  allKeys.forEach((key, index) => {
    if (key === "Qty Selected" && hasSelections) {
      summaryRow[key] = totals["Qty Selected"];
    } else if (key === (hasSelections ? "Total Weight Selected" : "Total Weight")) {
      summaryRow[key] = totals[key];
    } else if (key === (hasSelections ? "Total Rack Space Selected" : "Total Rack Space")) {
      summaryRow[key] = totals[key];
    } else if (index === 0) {
      summaryRow[key] = "TOTALS";
    } else {
      summaryRow[key] = "";
    }
  });

  // Append empty row and summary row
  augmentedData.push(summaryRow);

  // 🔥 **Ensure Column Order & Conditionals**
  let finalColumns = [...visibleColumns]; 

  // Remove unwanted columns **if selections are made**
  if (hasSelections) {
    finalColumns = finalColumns.filter(col => !columnsToOmitIfSelected.includes(col));
  }

  // Ensure "Total Weight" and "Total Rack Space" are **always** included at the end (with correct label)
  const weightColumn = hasSelections ? "Total Weight Selected" : "Total Weight";
  const rackColumn = hasSelections ? "Total Rack Space Selected" : "Total Rack Space";

  if (!finalColumns.includes(weightColumn)) {
    finalColumns.push(weightColumn);
  }
  if (!finalColumns.includes(rackColumn)) {
    finalColumns.push(rackColumn);
  }

  // "Qty Selected" should **only be included if something was selected**
  if (hasSelections && !finalColumns.includes("Qty Selected")) {
    finalColumns.push("Qty Selected");
  }

  // Map each row to ensure correct column order
  augmentedData = augmentedData.map(row => {
    const orderedRow = {};
    finalColumns.forEach(col => {
      orderedRow[col] = row[col] ?? ""; // Ensure the column is present in order
    });
    return orderedRow;
  });

  return augmentedData;
}




  
  