import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Table, Row } from 'react-bootstrap';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';

const CreditAnalysisPieChart = ({
  creditData,
  selectedAggregation,
  selectedGroup,
  setSelectedGroup,
  colorMapping,
  maxItems = 7,
  showAll,
  setShowAll,
}) => {
  const aggregationKeyMapping = {
    'Order Reason': 'Order Reason',
    'Client Description': 'Client Description',
    'Material': 'Material Description',
  };

  const groupKeyName = aggregationKeyMapping[selectedAggregation];

  const groupedData = creditData.reduce((acc, item) => {
    let groupKey = item[groupKeyName] || 'Unassigned';
    const amount = Math.abs(item['Net Billed Amount']); // Use absolute value for the chart

    if (!acc[groupKey]) {
      acc[groupKey] = { total: 0, data: [] };
    }
    acc[groupKey].total += amount; // Sum absolute amounts
    acc[groupKey].data.push(item);
    return acc;
  }, {});

  let pieData = Object.keys(groupedData).map((key) => ({
    name: key,
    value: groupedData[key].total, // Keep values positive for visualization
  }));

  // Sort data from largest to smallest
  pieData.sort((a, b) => b.value - a.value);

  // Determine the maximum items to show based on aggregation type
  const maxItemsToShow = selectedAggregation === 'Material' ? 10 : maxItems;
  let limitedPieData = pieData;
  if (!showAll) {
    limitedPieData = pieData.slice(0, maxItemsToShow);
  }

  // Calculate the total value based on the displayed (limited) data.
  const totalLimited = limitedPieData.reduce((acc, item) => acc + item.value, 0);

  const handleCellClick = (data) => {
    if (selectedGroup === data.name) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(data.name);
    }
  };

  if (pieData.length === 0) {
    return <div>No data available for the PieChart</div>;
  }

  // Function to dynamically position labels outside the pie chart.
  // Here we use the computed percentage from the slice (which is based on limitedPieData)
  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, name, value, percent } = props;
    const radius = outerRadius + 30; // Position labels slightly outside the pie chart
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Optionally, you can skip very small slices.
    if (percent < 0.05) return null;

    // Re-calculate the percentage using the limited total for consistency.
    const computedPercent = totalLimited > 0 ? (value / totalLimited) * 100 : 0;
    const formattedPercent = `${computedPercent.toFixed(0)}%`;

    return (
      <text
        x={x}
        y={y}
        fill={colorMapping[name]}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize="12px"
        fontWeight="bold"
      >
        {`${name} (${formatCurrency(value)}) - (${formattedPercent})`}
      </text>
    );
  };

  // Custom Legend component that also shows the percentage based on limitedPieData
  const CustomLegend = ({ data }) => {
    // Compute the total from the data that is being displayed.
    const total = data.reduce((acc, item) => acc + item.value, 0);

    return (
      <div style={{ display: 'flex', overflowX: 'auto' }}>
        <Table bordered variant="dark" size="sm" style={{ fontSize: "12px", width: "90%", margin: "auto" }}>
          <thead>
            <tr>
              <th style={{ color: '#FFFFFF' }}>Legend</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {data.map((item, index) => {
                // Calculate the percentage using the same total (limited data)
                const percentage = total > 0 ? ((item.value / total) * 100).toFixed(0) : 0;
                return (
                  <td
                    key={index}
                    style={{ padding: '5px', color: '#FFFFFF', cursor: 'pointer' }}
                    onClick={() => handleCellClick(item)}
                  >
                    <span style={{ color: colorMapping[item.name], fontWeight: 'bold' }}>■ </span>
                    {`${item.name} (${formatCurrency(item.value)}) - (${percentage}%)`}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <ResponsiveContainer width="95%" height={400}>
          <PieChart>
            <Pie
              data={limitedPieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={120}
              innerRadius={60}
              label={renderCustomizedLabel} // Custom label function
              labelLine={true} // Enable label lines for small slices
            >
              {limitedPieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colorMapping[entry.name]}
                  stroke={selectedGroup === entry.name ? '#000000' : null}
                  strokeWidth={selectedGroup === entry.name ? 2 : 1}
                  onClick={() => handleCellClick(entry)}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value) => formatCurrency(value)} />
          </PieChart>
        </ResponsiveContainer>
      </Row>
      <div>
        <CustomLegend data={limitedPieData} />
      </div>
    </div>
  );
};

export default CreditAnalysisPieChart;
