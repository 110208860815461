import './PlanItNavBar.css';
import React, { useState } from 'react';
import { Navbar, Button, Row, Col } from 'react-bootstrap';
import PlanItViewFunctionDropdown from './PlanItViewFunctionDropdown';
import FilterDropdown from '../../../Reusables/FilterDropdown';
import { connectToDB, RemoveDataFromTable } from '../../../../Utils/DBOps';
import PaginationRecordsSelectionControl from './PaginationRecordsSelectionControl';
import { color } from 'd3';
import PlanItNavbarRightSection from './PlanItNavbarRightSection';
import PlanItStockViewRightNavBar from './PlanItStockViewRightNavBar';
import FilterTextBar from '../../../Reusables/FilterTextBar';

function PlanItNavBar({
  // (No need for statusOrdersData or setFilteredData here, since filtering is done in main)
  uniqueProps, 

  // Filters and their setters
  clientFilter, setClientFilter,
  decorFilter, setDecorFilter,
  finishFilter, setFinishFilter,
  thicknessFilter, setThicknessFilter,
  formatFilter, setFormatFilter,

  // Multi-select states and setters
  selectedClients, setSelectedClients,
  multiSelectClient, setMultiSelectClient,
  selectedDecors, setSelectedDecors,
  multiSelectDecor, setMultiSelectDecor,
  selectedFinishes, setSelectedFinishes,
  multiSelectFinish, setMultiSelectFinish,
  selectedThicknesses, setSelectedThicknesses,
  multiSelectThickness, setMultiSelectThickness,
  selectedFormats, setSelectedFormats,
  multiSelectFormat, setMultiSelectFormat,
  // (setSelectedItems is omitted if not used)
  salesOrPurchaseOrderFilter, setSalesOrPurchaseOrderFilter,
  deliveryNoteFilter, setDeliveryNoteFilter,

  // View Selection
  selectedView, setSelectedView, setDataIsValid,
  PaginationProps, columns, visibleColumns, setVisibleColumns,
  onlyShowAssigned, setOnlyShowAssigned,
  showRackSpace, setShowRackSpace, 
  showWeights, setShowWeights,
  handleExport, handleSendEmail,
  defaultClient, setDefaultClient,
  
  stockViewFilterProps,
  DivisionIndicatorProps
}) {
  // Local search states for each dropdown
  const [clientSearch, setClientSearch] = useState('');
  const [decorSearch, setDecorSearch] = useState('');
  const [finishSearch, setFinishSearch] = useState('');
  const [thicknessSearch, setThicknessSearch] = useState('');
  const [formatSearch, setFormatSearch] = useState('');

  const { uniqueDecors, uniqueFinishes, uniqueThicknesses, uniqueFormats, uniqueClients } = uniqueProps;
  const {rowsPerPage, setRowsPerPage, setCurrentPage } = PaginationProps;
  const {
    stockViewDecorFilter, setStockViewDecorFilter,
    stockViewFinishFilter, setStockViewFinishFilter,
    stockViewThicknessFilter, setStockViewThicknessFilter,
    stockViewFormatFilter, setStockViewFormatFilter,
    stockViewSelectedDecors, setStockViewSelectedDecors,
    stockViewSelectedFinishes, setStockViewSelectedFinishes,
    stockViewSelectedThicknesses, setStockViewSelectedThicknesses,
    stockViewSelectedFormats, setStockViewSelectedFormats,
    stockViewMultiSelectDecor, setStockViewMultiSelectDecor,
    stockViewMultiSelectFinish, setStockViewMultiSelectFinish,
    stockViewMultiSelectThickness, setStockViewMultiSelectThickness,
    stockViewMultiSelectFormat, setStockViewMultiSelectFormat,
  } = stockViewFilterProps;
  


  // Clear Filters simply resets all filter state values.
  const handleClearFilters = () => {
    setClientFilter('');
    setDecorFilter('');
    setFinishFilter('');
    setThicknessFilter('');
    setFormatFilter('');

    setSelectedClients([]);
    setSelectedDecors([]);
    setSelectedFinishes([]);
    setSelectedThicknesses([]);
    setSelectedFormats([]);

    setClientSearch('');
    setDecorSearch('');
    setFinishSearch('');
    setThicknessSearch('');
    setFormatSearch('');
  };

  const handleClearStockFilters = () => {
    setStockViewDecorFilter('');
    setStockViewFinishFilter('');
    setStockViewThicknessFilter('');
    setStockViewFormatFilter('');

    setStockViewSelectedDecors([]);
    setStockViewSelectedFinishes([]);
    setStockViewSelectedThicknesses([]);
    setStockViewSelectedFormats([]);

    setDecorSearch('');
    setFinishSearch('');
    setThicknessSearch('');
    setFormatSearch('');
  };

  // Clear Data remains as before.
  const handleClearData = async () => {
    try {
      const db = await connectToDB();
      await RemoveDataFromTable(db, 'LogisticsStatusOrdersData');
      await RemoveDataFromTable(db, 'LogisticsProductionData');
      await RemoveDataFromTable(db, 'LogisticsStockData');
      setDataIsValid(false);
      alert('Data cleared successfully');
    } catch (error) {
      console.log('Error clearing local Dexie:', error.message);
      alert('Error clearing local Dexie:', error.message);
    }
  };

  return (
    <Navbar variant="dark" bg="dark" expand="lg" className="planIt-navbar">
      <div className="nav-bar-holder">
        {/* View Selector Dropdown */}
        <PlanItViewFunctionDropdown 
          navBarBrandTitle={"Plan It"} 
          navDropDownIdAndTitle={"Views"} 
          textArray={["PlanIt Dash", "Stock"]} 
          handleSelectViewClick={setSelectedView}
        />

        {/* Filters Row */}
        {selectedView === 'PlanIt Dash' && (
          <>
            {/* Filters Row */}
            <Row className="filters-row" style={{ display: "flex", alignItems: "center", marginBottom: "0", marginLeft: "8px", borderLeft: "1px solid" }}>
              <Col style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                <Navbar.Brand>Filters:</Navbar.Brand>
              </Col>
              <Col>
                <FilterDropdown 
                  title="Client"
                  options={uniqueClients.filter(value => value?.toString().toLowerCase().includes(clientSearch.toLowerCase()))}
                  selectedItems={selectedClients}
                  setSelectedItems={setSelectedClients}
                  searchValue={clientSearch}
                  setSearchValue={setClientSearch}
                  multiSelect={multiSelectClient}
                  setMultiSelect={setMultiSelectClient}
                  handleSelectAll={() => setSelectedClients(uniqueClients)}
                  setFilter={setClientFilter}
                />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Decor"
                  options={uniqueDecors.filter(value => value?.toString().toLowerCase().includes(decorSearch.toLowerCase()))}
                  selectedItems={selectedDecors}
                  setSelectedItems={setSelectedDecors}
                  searchValue={decorSearch}
                  setSearchValue={setDecorSearch}
                  multiSelect={multiSelectDecor}
                  setMultiSelect={setMultiSelectDecor}
                  handleSelectAll={() => setSelectedDecors(uniqueDecors)}
                  setFilter={setDecorFilter}
                />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Finish"
                  options={uniqueFinishes.filter(value => value?.toString().toLowerCase().includes(finishSearch.toLowerCase()))}
                  selectedItems={selectedFinishes}
                  setSelectedItems={setSelectedFinishes}
                  searchValue={finishSearch}
                  setSearchValue={setFinishSearch}
                  multiSelect={multiSelectFinish}
                  setMultiSelect={setMultiSelectFinish}
                  handleSelectAll={() => setSelectedFinishes(uniqueFinishes)}
                  setFilter={setFinishFilter}
                />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Thickness"
                  options={uniqueThicknesses.filter(value => value?.toString().toLowerCase().includes(thicknessSearch.toLowerCase()))}
                  selectedItems={selectedThicknesses}
                  setSelectedItems={setSelectedThicknesses}
                  searchValue={thicknessSearch}
                  setSearchValue={setThicknessSearch}
                  multiSelect={multiSelectThickness}
                  setMultiSelect={setMultiSelectThickness}
                  handleSelectAll={() => setSelectedThicknesses(uniqueThicknesses)}
                  setFilter={setThicknessFilter}
                />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Format"
                  options={uniqueFormats.filter(value => value?.toString().toLowerCase().includes(formatSearch.toLowerCase()))}
                  selectedItems={selectedFormats}
                  setSelectedItems={setSelectedFormats}
                  searchValue={formatSearch}
                  setSearchValue={setFormatSearch}
                  multiSelect={multiSelectFormat}
                  setMultiSelect={setMultiSelectFormat}
                  handleSelectAll={() => setSelectedFormats(uniqueFormats)}
                  setFilter={setFormatFilter}
                />
              </Col>

            </Row>

          {/* Panel for SO and DN Filter */}
          <div className="filters-container" style={{ display: "flex", alignItems: "center", marginLeft: "8px", borderLeft: "1px solid", width: "25%", justifyContent: "center" }}>
            <div style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
              <FilterTextBar label={"SO/PO"} filterText={salesOrPurchaseOrderFilter} setFilterText={setSalesOrPurchaseOrderFilter} placeholder={"Enter Sales/Purchase Order..."} />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
              <FilterTextBar label={"Delivery Note"} filterText={deliveryNoteFilter} setFilterText={setDeliveryNoteFilter} placeholder={"Enter Delivery Note..."} />
            </div>
          </div>

                

            {/* Navbar Right Section */}
            <PlanItNavbarRightSection 
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
              handleClearFilters={handleClearFilters}
              handleClearData={handleClearData}
              columns={columns}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              onlyShowAssigned={onlyShowAssigned}
              setOnlyShowAssigned={setOnlyShowAssigned}
              showRackSpace={showRackSpace}
              setShowRackSpace={setShowRackSpace}
              showWeights={showWeights}
              setShowWeights={setShowWeights}
              handleExport={handleExport}
              handleSendEmail={handleSendEmail}
              defaultClient={defaultClient}
              setDefaultClient={setDefaultClient}
              clientsList={uniqueClients}
              DivisionIndicatorProps={DivisionIndicatorProps}
            />
          </>
        )}
        {/* Stock View Filters Row */}
        {selectedView === 'Stock' && (
          <>
            <Row className="filters-row" style={{ display: "flex", alignItems: "center", marginBottom: "0", marginLeft: "8px", borderLeft: "1px solid" }}>
            <Col style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
              <Navbar.Brand>Stock Filters:</Navbar.Brand>
              </Col>
              <Col>
              <FilterDropdown 
                title="Decor" 
                options={uniqueDecors.filter(value => value?.toString().toLowerCase().includes(decorSearch.toLowerCase()))} 
                selectedItems={stockViewSelectedDecors} 
                setSelectedItems={setStockViewSelectedDecors} 
                searchValue={decorSearch}
                setSearchValue={setDecorSearch}
                multiSelect={stockViewMultiSelectDecor} 
                setMultiSelect={setStockViewMultiSelectDecor}
                handleSelectAll={() => setStockViewSelectedDecors(uniqueDecors)}
                setFilter={setStockViewDecorFilter} 
              />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Finish" 
                  options={uniqueFinishes} 
                  selectedItems={stockViewSelectedFinishes} 
                  setSelectedItems={setStockViewSelectedFinishes} 
                  searchValue={finishSearch}
                  setSearchValue={setFinishSearch}
                  multiSelect={stockViewMultiSelectFinish} 
                  setMultiSelect={setStockViewMultiSelectFinish}
                  handleSelectAll={() => setStockViewSelectedFinishes(uniqueFinishes)}
                  setFilter={setStockViewFinishFilter} 
                />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Thickness" 
                  options={uniqueThicknesses} 
                  selectedItems={stockViewSelectedThicknesses} 
                  setSelectedItems={setStockViewSelectedThicknesses} 
                  searchValue={thicknessSearch}
                  setSearchValue={setThicknessSearch}
                  multiSelect={stockViewMultiSelectThickness} 
                  setMultiSelect={setStockViewMultiSelectThickness}
                  handleSelectAll={() => setStockViewSelectedThicknesses(uniqueThicknesses)}
                  setFilter={setStockViewThicknessFilter} 
                />
              </Col>
              <Col>
                <FilterDropdown 
                  title="Format" 
                  options={uniqueFormats} 
                  selectedItems={stockViewSelectedFormats} 
                  setSelectedItems={setStockViewSelectedFormats} 
                  searchValue={formatSearch}
                  setSearchValue={setFormatSearch}
                  multiSelect={stockViewMultiSelectFormat} 
                  setMultiSelect={setStockViewMultiSelectFormat}
                  handleSelectAll={() => setStockViewSelectedFormats(uniqueFormats)}
                  setFilter={setStockViewFormatFilter} 
                />
              </Col>
            </Row>

            <PlanItStockViewRightNavBar
                handleClearFilters={handleClearStockFilters}
                handleClearData={handleClearData}
            /> 
          </>
        )}
      </div>
    </Navbar>
  );
}

export default PlanItNavBar;
