import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import columnsConfig from '../../../../Utils/columnsConfigSalesOrders.json'; // Adjust the path as needed
import { convertExcelDateToUKDateString } from '../../../../Utils/SalesOrderFunctions';
import './CalendarView.css';

const CalendarView = ({ filterState, rawData }) => {
  // Current month state
  const [currentDate, setCurrentDate] = useState(new Date());
  // Calendar day cells (always 42 cells)
  const [calendarDays, setCalendarDays] = useState([]);
  // Filtered orders from rawData based on filterState using columnsConfig keys
  const [filteredData, setFilteredData] = useState([]);
  // State for the language-based column set (default ENG)
  const [columnSet, setColumnSet] = useState('ENG');

  // Modal state for a clicked day
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDayOrders, setSelectedDayOrders] = useState([]);

  // Detect language (and thus which column set to use) like in MainTable
  useEffect(() => {
    if (rawData && rawData.length > 0) {
      const topRow = rawData[0];
      const headers = Object.keys(topRow);
      const isSpanish = headers.some((key) => key.includes('Centro'));
      setColumnSet(isSpanish ? 'ESP' : 'ENG');
    }
  }, [rawData]);

  // Update filtered data when rawData or filterState change using columnsConfig keys
  useEffect(() => {
    if (rawData && rawData.length > 0) {
      const data = rawData.filter(item => (
        (filterState.customer === '' ||
          (item[columnsConfig[columnSet].headers['Customer Name']] &&
            item[columnsConfig[columnSet].headers['Customer Name']].toLowerCase().includes(filterState.customer.toLowerCase()))) &&
        (filterState.material === '' ||
          (item[columnsConfig[columnSet].headers['Material Description']] &&
            item[columnsConfig[columnSet].headers['Material Description']].toLowerCase().includes(filterState.material.toLowerCase()))) &&
        (filterState.reference === '' ||
          (item[columnsConfig[columnSet].headers['Customer Reference']] &&
            item[columnsConfig[columnSet].headers['Customer Reference']].toLowerCase().includes(filterState.reference.toLowerCase())))
      ));
      setFilteredData(data);
    }
  }, [filterState, rawData, columnSet]);

  // Generate a 42-cell grid for the current month (including days from adjacent months)
  useEffect(() => {
    generateCalendar();
  }, [currentDate, filteredData]);

  const generateCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const startDay = firstDayOfMonth.getDay(); // 0 (Sun) to 6 (Sat)
    let days = [];
    // Start at the Sunday before (or on) the 1st of the month
    let cellDate = new Date(year, month, 1 - startDay);
    for (let i = 0; i < 42; i++) {
      days.push(new Date(cellDate));
      cellDate.setDate(cellDate.getDate() + 1);
    }
    setCalendarDays(days);
  };

  // Return orders for a given day (by comparing dates)
  const ordersForDay = (day) => {
    return filteredData.filter(order => {
      const excelDate = order['Delivery Date'] || order['Fecha de entrega'];
      if (!excelDate) return false;

      const dateString = convertExcelDateToUKDateString(excelDate); // expecting "dd/mm/yyyy"
      const [dayStr, monthStr, yearStr] = dateString.split('/');
      // Build a date using numeric values (months are zero-based)
      const orderDate = new Date(
        parseInt(yearStr, 10),
        parseInt(monthStr, 10) - 1,
        parseInt(dayStr, 10)
      );

      return (
        orderDate.getFullYear() === day.getFullYear() &&
        orderDate.getMonth() === day.getMonth() &&
        orderDate.getDate() === day.getDate()
      );
    });
  };

  // Navigation handlers
  const goToPreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  // When a current-month day is clicked, show the modal with that day's orders
  const handleDayClick = (day) => {
    // Only allow modal for days in the current month
    if (day.getMonth() !== currentDate.getMonth()) return;
    const orders = ordersForDay(day);
    setSelectedDate(day);
    setSelectedDayOrders(orders);
    setShowModal(true);
  };

  // Render an individual day cell
  const renderDay = (day, index) => {
    const isCurrentMonth = day.getMonth() === currentDate.getMonth();

    // For days not in the current month, render a greyed-out empty cell (no border or number)
    if (!isCurrentMonth) {
      return (
        <div
          key={index}
          className="calendar-day inactive"
          style={{
            background: '#262626',
            minHeight: '100px',
            padding: '5px'
          }}
        />
      );
    }

    // For current month days, extract unique customer names and render a clickable cell.
    // (We display the raw "Sold-To Party Name" here.)
    const orders = ordersForDay(day);
    const uniqueCustomerNames = Array.from(
      new Set(orders.map(order => order['Sold-To Party Name']).filter(Boolean))
    );

    return (
      <div
        key={index}
        className="calendar-day"
        onClick={() => handleDayClick(day)}
        style={{
          border: '1px solid #ddd',
          minHeight: '100px',
          padding: '5px',
          overflowY: 'auto',
          cursor: 'pointer'
        }}
      >
        <div
          className="calendar-day-header"
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            textAlign: 'left',
            marginLeft: '5px'
          }}
        >
          {day.getDate()}
        </div>
        <div className="calendar-day-orders">
          {uniqueCustomerNames.map((name, idx) => (
            <div
              key={idx}
              className="calendar-order"
              style={{ fontSize: '0.8rem', color: '#FFD700' }}
            >
              {name.length > 25 ? `${name.slice(0, 27)}...` : name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Group orders by customer name for the modal
  const groupOrdersByCustomer = () => {
    return selectedDayOrders.reduce((acc, order) => {
      const customer = order['Sold-To Party Name'] || 'Unknown Customer';
      if (!acc[customer]) {
        acc[customer] = [];
      }
      acc[customer].push(order);
      return acc;
    }, {});
  };

  const ordersByCustomer = groupOrdersByCustomer();

  return (
    <div className="month-view-container" style={{ width: '96%', margin: 'auto' }}>
      {/* Header with navigation */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Button variant="warning" onClick={goToPreviousMonth} size="sm" className="custom-button-gold">
          Previous
        </Button>
        <h5 style={{ color: '#FFD700' }}>
          {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </h5>
        <Button variant="warning" onClick={goToNextMonth} size="sm" className="custom-button-gold">
          Next
        </Button>
      </div>

      {/* Weekday headers */}
      <div
        className="calendar-weekdays"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((weekday, idx) => (
          <div
            key={idx}
            className="calendar-weekday"
            style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '13px', color: '#FFD700' }}
          >
            {weekday}
          </div>
        ))}
      </div>

      {/* Calendar grid of day cells */}
      <div
        className="calendar-grid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: '10px',
          margin: 'auto',
          height: '70vh'
        }}
      >
        {calendarDays.map((day, idx) => renderDay(day, idx))}
      </div>

      {/* Modal showing details for a clicked day */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
        style={{ color: '#FFD700' }}
      >
        <Modal.Header closeButton style={{ backgroundColor: '#333', color: '#FFD700' }}>
          <Modal.Title style={{ color: '#FFD700' }}>
            Deliveries for {selectedDate ? selectedDate.toLocaleDateString() : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#333', color: '#FFD700' }}>
          {selectedDayOrders.length > 0 ? (
            Object.keys(ordersByCustomer).map((customer, idx) => (
              <div key={idx} style={{ marginBottom: '20px' }}>
                <h6 style={{ color: '#FFD700', marginBottom: '10px' }}>{customer}</h6>
                <Table variant="dark" size="sm" striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ color: '#FFD700' }}>Material</th>
                      <th style={{ color: '#FFD700' }}>Qty</th>
                      <th style={{ color: '#FFD700' }}>Reference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersByCustomer[customer].map((order, orderIdx) => (
                      <tr key={orderIdx}>
                        <td>{order['Material Description']}</td>
                        <td>{order['Open Dlv Quantity']}</td>
                        <td>{order['Customer Reference (Header)']}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))
          ) : (
            <div>No deliveries for this day.</div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#333' }}>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalendarView;
