import React, { useState, useEffect } from 'react';
import PlanItNavBar from './PlanItNavBar/PlanItNavBar';
import codexFlushed from '../../../Utils/CodexFlushed.json';
import PlanItViewCycler from './PlanItViewCycler';


// ---UTILITES--- //
import { ExportToSpreadsheetWithOmissions } from '../../../Utils/ExcelExportUtils';
import { augmentDataForExport } from './PlanItUtils/PlanItExportFunctions';

// --- COLUMN SETTINGS ---
// Define the full set of columns as an object (key: friendly label)
const columns = {
  EDD: "Est Delivery Date",
  SO: "Sales Order",
  DN: "Delivery Note",
  Client: "Client",
  Pos: "Pos",
  Articulo: "SKU",
  "Descripción material": "Material Description",
  Qty: "Qty",
  LOTE: "Batch",
  FeHORNOS: "Production Date",
  FeMALLADO: "Meshing Date",
  FePULIDO: "Polishing Date",
  FeCORTE: "Cutting Date",
  Delegacion: "Delegation",
  Comentario: "Comment",
  "€ línea": "Line €",
};


const PlanItMain = ({ planItProps, AppProps }) => {
  const {setDataIsValid, statusOrdersData } = planItProps;
  const [selectedView, setSelectedView] = useState("PlanIt Dash");

// --- PAGINATION & SORT STATES ---
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Default visible columns: Exclude last 6 columns
  const defaultVisibleColumns = Object.keys(columns).filter(col => 
    !["FeMALLADO", "FePULIDO", "FeCORTE", "Comentario", "€ línea"].includes(col)
  );

  const totalPages = Math.ceil(statusOrdersData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const PaginationProps = {
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    totalPages,
    startIndex,
    endIndex,
  }

  // --- DEFAULT VALUES --- //
  const [defaultClient, setDefaultClient] = useState('');

  // Load saved visibility settings or use default
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const saved = localStorage.getItem('planItVisibleColumns');
    return saved ? JSON.parse(saved) : defaultVisibleColumns;
  });

  useEffect(() => {
    const savedClient = localStorage.getItem("defaultClient");
    if (savedClient) {
      setDefaultClient(savedClient);
    }
  }, []);

  // Save visible columns to localStorage whenever they change.
  useEffect(() => {
    localStorage.setItem('planItVisibleColumns', JSON.stringify(visibleColumns));
  }, [visibleColumns]);


  // --- ROW SELECTION STATES ---
  const [selectedRows, setSelectedRows] = useState([]);

  // ---OTHER SETTINGS--- //
  const [onlyShowAssigned, setOnlyShowAssigned] = useState(false);
  const [showRackSpace, setShowRackSpace] = useState(false);
  const [showWeights, setShowWeights] = useState(false);
  // State for per-row adjustments (object: { [rowIndex]: adjustedQty })
  const [rowAdjustments, setRowAdjustments] = useState({});

  const [totalWeightSelected, setTotalWeightSelected] = useState(0);
  const [totalRacksOccupied, setTotalRacksOccupied] = useState(0);
  const [totalSlabsSelected, setTotalSlabsSelected] = useState(0);

  // Division indicator states
  const [divisionIndicator3mm, setDivisionIndicator3mm] = useState(96);
  const [divisionIndicator6mm, setDivisionIndicator6mm] = useState(48);
  const [divisionIndicator12mm, setDivisionIndicator12mm] = useState(24);
  const [divisionIndicator20mm, setDivisionIndicator20mm] = useState(14);

  const DivisionIndicatorProps = {
    divisionIndicator3mm: divisionIndicator3mm,
    setDivisionIndicator3mm: setDivisionIndicator3mm,
    divisionIndicator6mm: divisionIndicator6mm,
    setDivisionIndicator6mm: setDivisionIndicator6mm,
    divisionIndicator12mm: divisionIndicator12mm,
    setDivisionIndicator12mm: setDivisionIndicator12mm,
    divisionIndicator20mm: divisionIndicator20mm,
    setDivisionIndicator20mm: setDivisionIndicator20mm,
  };

  // --- SINGLE-SELECT FILTER STATES ---
  const [clientFilter, setClientFilter] = useState('');
  const [decorFilter, setDecorFilter] = useState('');
  const [finishFilter, setFinishFilter] = useState('');
  const [thicknessFilter, setThicknessFilter] = useState('');
  const [formatFilter, setFormatFilter] = useState('');

  // --- INPUT States for SO and DN
  const [salesOrPurchaseOrderFilter, setSalesOrPurchaseOrderFilter] = useState('');
  const [deliveryNoteFilter, setDeliveryNoteFilter] = useState('');
  
  // --- MULTI-SELECT STATES ---
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDecors, setSelectedDecors] = useState([]);
  const [selectedFinishes, setSelectedFinishes] = useState([]);
  const [selectedThicknesses, setSelectedThicknesses] = useState([]);
  const [selectedFormats, setSelectedFormats] = useState([]);

  // --- MULTI-SELECT TOGGLE STATES (if needed) ---
  const [multiSelectClient, setMultiSelectClient] = useState(false);
  const [multiSelectDecor, setMultiSelectDecor] = useState(false);
  const [multiSelectFinish, setMultiSelectFinish] = useState(false);
  const [multiSelectThickness, setMultiSelectThickness] = useState(false);
  const [multiSelectFormat, setMultiSelectFormat] = useState(false);

// --- STATES FOR PLANIT STOCK VIEW ---
// --- SINGLE-SELECT FILTER STATES ---
const [stockViewClientFilter, setStockViewClientFilter] = useState('');
const [stockViewDecorFilter, setStockViewDecorFilter] = useState('');
const [stockViewFinishFilter, setStockViewFinishFilter] = useState('');
const [stockViewThicknessFilter, setStockViewThicknessFilter] = useState('');
const [stockViewFormatFilter, setStockViewFormatFilter] = useState('');

// --- MULTI-SELECT STATES ---
const [stockViewSelectedClients, setStockViewSelectedClients] = useState([]);
const [stockViewSelectedDecors, setStockViewSelectedDecors] = useState([]);
const [stockViewSelectedFinishes, setStockViewSelectedFinishes] = useState([]);
const [stockViewSelectedThicknesses, setStockViewSelectedThicknesses] = useState([]);
const [stockViewSelectedFormats, setStockViewSelectedFormats] = useState([]);

// --- MULTI-SELECT TOGGLE STATES (if needed) ---
const [stockViewMultiSelectClient, setStockViewMultiSelectClient] = useState(false);
const [stockViewMultiSelectDecor, setStockViewMultiSelectDecor] = useState(false);
const [stockViewMultiSelectFinish, setStockViewMultiSelectFinish] = useState(false);
const [stockViewMultiSelectThickness, setStockViewMultiSelectThickness] = useState(false);
const [stockViewMultiSelectFormat, setStockViewMultiSelectFormat] = useState(false);

const stockViewFilterProps = {
  stockViewClientFilter: stockViewClientFilter,
  setStockViewClientFilter: setStockViewClientFilter,
  stockViewDecorFilter: stockViewDecorFilter,
  setStockViewDecorFilter: setStockViewDecorFilter,
  stockViewFinishFilter: stockViewFinishFilter,
  setStockViewFinishFilter: setStockViewFinishFilter,
  stockViewThicknessFilter: stockViewThicknessFilter,
  setStockViewThicknessFilter: setStockViewThicknessFilter,
  stockViewFormatFilter: stockViewFormatFilter,
  setStockViewFormatFilter: setStockViewFormatFilter,
  stockViewSelectedClients: stockViewSelectedClients,
  setStockViewSelectedClients: setStockViewSelectedClients,
  stockViewSelectedDecors: stockViewSelectedDecors,
  setStockViewSelectedDecors: setStockViewSelectedDecors,
  stockViewSelectedFinishes: stockViewSelectedFinishes,
  setStockViewSelectedFinishes: setStockViewSelectedFinishes,
  stockViewSelectedThicknesses: stockViewSelectedThicknesses,
  setStockViewSelectedThicknesses: setStockViewSelectedThicknesses,
  stockViewSelectedFormats: stockViewSelectedFormats,
  setStockViewSelectedFormats: setStockViewSelectedFormats,
  stockViewMultiSelectClient: stockViewMultiSelectClient,
  setStockViewMultiSelectClient: setStockViewMultiSelectClient,
  stockViewMultiSelectDecor: stockViewMultiSelectDecor,
  setStockViewMultiSelectDecor: setStockViewMultiSelectDecor,
  stockViewMultiSelectFinish: stockViewMultiSelectFinish,
  setStockViewMultiSelectFinish: setStockViewMultiSelectFinish,
  stockViewMultiSelectThickness: stockViewMultiSelectThickness,
  setStockViewMultiSelectThickness: setStockViewMultiSelectThickness,
  stockViewMultiSelectFormat: stockViewMultiSelectFormat,
  setStockViewMultiSelectFormat: setStockViewMultiSelectFormat,
};



  // --- UNIQUE ARRAYS FOR THE DROPDOWNS ---
  const uniqueDecors = [...new Set(codexFlushed.map(item => item.Decor))];
  const uniqueFinishes = [...new Set(codexFlushed.map(item => item.Finish))];
  const uniqueThicknesses = [...new Set(codexFlushed.map(item => Number(item.Thickness)))]
    .filter(val => !isNaN(val))
    .sort((a, b) => a - b);
  const uniqueFormats = [...new Set(codexFlushed.map(item => Number(item["Size Code"])))]
    .filter(val => !isNaN(val))
    .sort((a, b) => a - b);
  const uniqueClients = [...new Set(statusOrdersData.map(item => item.Client))];
  const UniqueDocTypes = ["PC", "PV", "Deliveries"];

  const uniqueProps = {
    uniqueDecors,
    uniqueFinishes,
    uniqueThicknesses,
    uniqueFormats,
    uniqueClients,
    UniqueDocTypes,
  };

  // --- UTILITY: Generic passesFilter (for non-SKU filters) ---
  const passesFilter = (itemValue, isMulti, selectedValues, singleValue) => {
    if (isMulti) {
      // If nothing is chosen in multi-select, don’t filter anything out.
      if (!selectedValues || selectedValues.length === 0) return true;
      return selectedValues.includes(itemValue);
    } else {
      // Single-select: if no filter is set, pass everything.
      if (!singleValue) return true;
      return String(itemValue).toLowerCase() === String(singleValue).toLowerCase();
    }
  };

  // --- APPLY FILTERS ---
  // This function applies all the filters to statusOrdersData.
  const applyFilters = () => {
    if (!statusOrdersData || statusOrdersData.length === 0) return statusOrdersData;
  
    let filtered = [...statusOrdersData];
  
    // --- Client Filter ---
    if (defaultClient) {
      filtered = filtered.filter(item =>
        String(item.Client).toLowerCase() === String(defaultClient).toLowerCase()
      );
    } else {
      filtered = filtered.filter(item =>
        passesFilter(item.Client, multiSelectClient, selectedClients, clientFilter)
      );
    }
  
    // --- Decor Filter (Optimized for Performance) ---
    if (selectedDecors && selectedDecors.length > 0) {
      const decorCodeSet = new Set();
      selectedDecors.forEach(decor => {
        const rec = codexFlushed.find(r => r.Decor.toLowerCase() === decor.toLowerCase());
        if (rec && rec["Half Code"] && rec["Half Code"].length >= 7) {
          const decorCode = rec["Half Code"].substring(2);
          decorCodeSet.add(decorCode);
        }
      });
      filtered = filtered.filter(item => {
        const sku = item.Articulo;
        if (!sku || sku.length < 7) {
          return false;
        }
        const skuDecor = sku.substring(2, 7);
        return decorCodeSet.has(skuDecor);
      });
    }
  
    // --- Finish Filter ---
    if (selectedFinishes && selectedFinishes.length > 0) {
      const finishCodes = selectedFinishes.map(f => f.toUpperCase());
      filtered = filtered.filter(item => {
        const sku = item.Articulo;
        if (!sku || sku.length < 9) return false;
        const skuFinish = sku.substring(7, 9);
        return finishCodes.includes(skuFinish);
      });
    }
  
    // --- Thickness Filter ---
    if (selectedThicknesses && selectedThicknesses.length > 0) {
      const thicknessCodes = selectedThicknesses.map(t => t.toString().padStart(2, '0'));
      filtered = filtered.filter(item => {
        const sku = item.Articulo;
        if (!sku || sku.length < 11) return false;
        const skuThickness = sku.substring(9, 11);
        return thicknessCodes.includes(skuThickness);
      });
    }
  
    // --- Format Filter ---
    if (selectedFormats && selectedFormats.length > 0) {
      const formatCodes = selectedFormats.map(f => f.toString().padStart(2, '0'));
      filtered = filtered.filter(item => {
        const sku = item.Articulo;
        if (!sku || sku.slice(-1) !== 'E' || sku.length < 3) return false;
        const skuFormat = sku.slice(-3, -1);
        return formatCodes.includes(skuFormat);
      });
    }
  
    // --- Sales Order Filter ---
    if (salesOrPurchaseOrderFilter) {
      filtered = filtered.filter(item =>
        String(item.SO)
          .toLowerCase()
          .includes(String(salesOrPurchaseOrderFilter).toLowerCase())
      );
    }
  
    // --- Delivery Note Filter ---
    if (deliveryNoteFilter) {
      filtered = filtered.filter(item =>
        String(item.DN)
          .toLowerCase()
          .includes(String(deliveryNoteFilter).toLowerCase())
      );
    }
  
    if (onlyShowAssigned) {
      filtered = filtered.filter(item => (item.LOTE || "").toString().trim() !== "");
    }
  
    return filtered.map(row => ({
      ...row,
      FeHORNOS: row.FeHORNOS ?? "",
      FeMALLADO: row.FeMALLADO ?? "",
      FePULIDO: row.FePULIDO ?? "",
      FeCORTE: row.FeCORTE ?? ""
    }));
  };
  


  const handleExport = () => {
  // Assume filteredData is the current data used in the table.
  // selectedRows should be an array of indices corresponding to filteredData.
  const augmentedData = augmentDataForExport(
    filteredData,           // the data you want to export
    rowAdjustments,         // your adjustments keyed by index
    selectedRows,           // only export rows that were selected
    divisionIndicator3mm,
    divisionIndicator6mm,
    divisionIndicator12mm,
    divisionIndicator20mm,
    visibleColumns
  );

  const dynamicDateFileSafe = new Date().toISOString().split("T")[0];
  const fileName = `PlanItExport_${dynamicDateFileSafe}`;

  ExportToSpreadsheetWithOmissions(augmentedData, [], fileName);
};
  

const handleSendEmail = () => {
  console.log("Sending email...");
};

// For example, you might compute filteredData (or pass applyFilters down)
const filteredData = applyFilters();

// --- SELECTED FILTERS LABEL --- 
// Build a string that displays the currently applied filters
const selectedFiltersLabel = [
  clientFilter && `Client: ${clientFilter}`,
  decorFilter && `Decor: ${decorFilter}`,
  finishFilter && `Finish: ${finishFilter}`,
  thicknessFilter && `Thickness: ${thicknessFilter}`,
  formatFilter && `Format: ${formatFilter}`,
]
  .filter(label => label)
  .join(" | ");

  useEffect(() => {
    if (statusOrdersData.length > 0) {
    }
  }, [statusOrdersData]);
  


  return (
    <div>
      {/* Pass down all filter states and setters to your NavBar */}
      <PlanItNavBar
        selectedView={selectedView} 
        setSelectedView={setSelectedView} 
        setDataIsValid={setDataIsValid}
        uniqueProps={uniqueProps}
        // Single-select states and setters:
        clientFilter={clientFilter} setClientFilter={setClientFilter}
        decorFilter={decorFilter} setDecorFilter={setDecorFilter}
        finishFilter={finishFilter} setFinishFilter={setFinishFilter}
        thicknessFilter={thicknessFilter} setThicknessFilter={setThicknessFilter}
        formatFilter={formatFilter} setFormatFilter={setFormatFilter}
        salesOrPurchaseOrderFilter={salesOrPurchaseOrderFilter} setSalesOrPurchaseOrderFilter={setSalesOrPurchaseOrderFilter}
        deliveryNoteFilter={deliveryNoteFilter} setDeliveryNoteFilter={setDeliveryNoteFilter}
        // Multi-select states and setters:
        selectedClients={selectedClients} setSelectedClients={setSelectedClients}
        multiSelectClient={multiSelectClient} setMultiSelectClient={setMultiSelectClient}
        selectedDecors={selectedDecors} setSelectedDecors={setSelectedDecors}
        multiSelectDecor={multiSelectDecor} setMultiSelectDecor={setMultiSelectDecor}
        selectedFinishes={selectedFinishes} setSelectedFinishes={setSelectedFinishes}
        multiSelectFinish={multiSelectFinish} setMultiSelectFinish={setMultiSelectFinish}
        selectedThicknesses={selectedThicknesses} setSelectedThicknesses={setSelectedThicknesses}
        multiSelectThickness={multiSelectThickness} setMultiSelectThickness={setMultiSelectThickness}
        selectedFormats={selectedFormats} setSelectedFormats={setSelectedFormats}
        multiSelectFormat={multiSelectFormat} setMultiSelectFormat={setMultiSelectFormat}
        PaginationProps={PaginationProps}
        columns={columns}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        onlyShowAssigned={onlyShowAssigned}
        setOnlyShowAssigned={setOnlyShowAssigned}
        showRackSpace={showRackSpace}
        setShowRackSpace={setShowRackSpace}
        showWeights={showWeights}
        setShowWeights={setShowWeights}
        handleExport={handleExport}
        handleSendEmail={handleSendEmail}
        defaultClient={defaultClient}
        setDefaultClient={setDefaultClient}
        stockViewFilterProps={stockViewFilterProps}
        DivisionIndicatorProps={DivisionIndicatorProps}
      />


      {/* Pass filteredData to your view cycler (or use it in your table view) */}
      <PlanItViewCycler
        selectedView={selectedView}
        planItProps={{ 
          ...planItProps, 
          statusOrdersData: filteredData, 
          visibleColumns, 
          columns, 
          onlyShowAssigned,
          selectedRows, 
          setSelectedRows,
          // Pass new states for insights:
          showRackSpace, 
          showWeights,
          rowAdjustments, 
          setRowAdjustments,
        }} 
        AppProps={AppProps}
        PaginationProps={PaginationProps}
        totalWeightSelected={totalWeightSelected}
        totalRacksOccupied={totalRacksOccupied}
        totalSlabsSelected={totalSlabsSelected}
        setTotalWeightSelected={setTotalWeightSelected}
        setTotalRacksOccupied={setTotalRacksOccupied}
        setTotalSlabsSelected={setTotalSlabsSelected}
        divisionIndicator3mm={divisionIndicator3mm}
        divisionIndicator6mm={divisionIndicator6mm}
        divisionIndicator12mm={divisionIndicator12mm}
        divisionIndicator20mm={divisionIndicator20mm}
        setDivisionIndicator3mm={setDivisionIndicator3mm}
        setDivisionIndicator6mm={setDivisionIndicator6mm}
        setDivisionIndicator12mm={setDivisionIndicator12mm}
        setDivisionIndicator20mm={setDivisionIndicator20mm}
        selectedFiltersLabel={selectedFiltersLabel}
        stockViewFilterProps={stockViewFilterProps}
        salesOrPurchaseOrderFilter={salesOrPurchaseOrderFilter}
        deliveryNoteFilter={deliveryNoteFilter}
      />
    </div>
  );
}

export default PlanItMain;
