// FeedbackForm.js
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import '../Styles/FeedbackForm.css'; // Import your CSS file for styling

const FeedbackForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Name:', name);
    console.log('Email:', email);
    console.log('Comments:', comments);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('comments', comments);

    if (name && email && comments) {
      try {
        const hostedUrl = 'https://api.neolution.uk/mailer/send-feedback';
        const localUrl = 'https://localhost:4000/mailer/send-feedback';
        const response = await fetch(localUrl, {
          method: 'POST',
          mode: 'cors',
          body: formData,
        });
    
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          alert("Email Sent");
        } else {
          console.error('Error:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
    else {
      alert("Please fill in all fields");
    }
  };

  return (
    <div className="feedback-form-container">
      <Form onSubmit={handleSubmit} className='the-form'>
        <div className="form-row">
          <p style={{margin: "auto",  fontWeight: "700"}}>Feedback Form</p>
        </div>
        <div className="form-row">
          <p style={{margin: "auto",  fontWeight: "600", fontSize: "medium"}}>Tell us what you think. Help us to help you.</p>
        </div>
        <div className="form-row">
          <Form.Group controlId="formName" className="form-group">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className='form-row'> 
        <Form.Group controlId="formEmail" className="form-group">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className="form-row">
          <Form.Group controlId="formComments" className="form-group">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={7}
              placeholder="Enter your comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Group>
        </div>

        <Button variant="primary" type="submit" className="button">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default FeedbackForm;
